import React from 'react';
import ReactTable from "react-table-6";
import ActionHistorique from "../../../Components/Historique/ActionHistorique/ActionHistorique";
import {CategoryState} from "../../../Store/Reducers/Collections/types";
import {Input, Select, Table as AntdTable} from 'antd';

//@ts-ignore
import matchSorter from 'match-sorter';
import {DacState} from "../../../Store/Reducers/Dac/types";

const {Option} = Select;

interface Props {
    data: Array<any>
}

interface State {
    data: Array<CategoryState>,
    filters: {
        families: Array<string>,
        categories: Array<string>

    }
}

class Table extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            data: [],
            filters: {
                families: [],
                categories: []

            }
        }
    }

    componentDidMount() {
        if (this.props.data) {
            this.setState(() => ({data: this.props.data}));
        }

    }


    filterData = () => {
        let newData = [];

        // for(const family of this.state.filters.families) {
        //     newData.push(...this.props.data.filter(data => data.family === family))
        //
        // }

        const families = this.uniq(this.props.data.map(d => d.family))
        const categories = this.uniq(this.props.data.map(d => d.name))
        const familiesFilter = this.state.filters.families.length > 0 ? this.state.filters.families : families;
        const categoriesFilter = this.state.filters.categories.length > 0 ? this.state.filters.categories : categories;
        for (const data of this.props.data) {
            if (familiesFilter.includes(data.family) && categoriesFilter.includes(data.name)) {
                newData.push(data);
            }
        }

        return newData;
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if (prevProps.data !== this.props.data) {
            this.setState(() => ({data: this.props.data}));
        }
        if (this.state.filters !== prevState.filters) {

            this.setState(() => ({data: this.filterData()}));
            // this.setState((state) => ({data: matchSorter(state.data, e.target.value, {keys: [e.target.name]})}))
        }
    }


    compare(a: any, b: any) {
        //@ts-ignore
        if (a.family < b.family) {
            return -1;
        }
        //@ts-ignore
        if (a.family > b.family) {
            return 1;
        }
        return 0;
    }

    change = (name: string, value: Array<string>) => {
        // this.setState((state) => ({data: matchSorter(state.data, e.target.value, {keys: [e.target.name]})}))
        this.setState((state) => ({
            filters: {
                ...state.filters,
                [name]: value
            }
        }))
    }

    uniq(a: Array<any>) {
        return a.sort().filter(function (item, pos, ary) {
            return !pos || item != ary[pos - 1];
        });
    }

    render() {
        const data = this.state.data.sort(this.compare);
        const families = this.uniq(this.props.data.map(d => d.family))
        const categories = this.uniq(this.props.data.map(d => d.name))
        const columns = [
            {
                title: (
                    <div style={{textAlign: 'center'}}>
                        <div>Famille</div>
                        <hr/>
                        {/*<Input placeholder='Search' value={this.state.filters.family} name={'family'} onChange={this.change}/>*/}
                        <Select
                            mode="multiple"
                            allowClear
                            style={{width: '100%'}}
                            placeholder="Please select"
                            onChange={(value: Array<string>) => this.change('families', value)}
                            maxTagCount={'responsive'}
                        >
                            {families.map(family => (
                                <Option value={family}>{family}</Option>
                            ))}
                        </Select>
                    </div>
                ),
                dataIndex: 'family',
                render: (text: string, row: any, index: any) => {
                    const obj = {
                        children: <a>{text}</a>,
                        props: {
                            rowSpan: 1,
                            colSpan: 1
                        }


                    };
                    const arrFamiliesName = data.map(d => d.family)
                    const pos = arrFamiliesName.indexOf(text);
                    let length = arrFamiliesName.filter(r => r === text).length

                    if (index === pos) {
                        obj.props.rowSpan = length
                    }
                    if (index > pos) {
                        obj.props.rowSpan = 0
                    }

                    return obj
                },
            },
            {
                title: (
                    <div style={{textAlign: 'center'}}>
                        <div>ABO HT</div>
                        <hr/>
                        <div className={'rpl__ipt'}></div>
                    </div>
                ),
                dataIndex: 'family',
                render: (text: string, row: any, index: any) => {
                    let sum_abo_ht: number | undefined = 0;
                    for (const {id_family, abo_ht} of data) {
                        if (id_family !== row.id_family) continue;
                        sum_abo_ht += abo_ht ? abo_ht : 0;
                    }
                    const obj = {
                        children: <a>{sum_abo_ht} €</a>,
                        props: {
                            rowSpan: 1,
                            colSpan: 1
                        }


                    };
                    const arrFamiliesName = data.map(d => d.family)
                    const pos = arrFamiliesName.indexOf(text);
                    let length = arrFamiliesName.filter(r => r === text).length

                    if (index === pos) {
                        obj.props.rowSpan = length
                    }
                    if (index > pos) {
                        obj.props.rowSpan = 0
                    }

                    return obj
                },
            },
            {
                title: (
                    <div style={{textAlign: 'center'}}>
                        <div>FAS HT</div>
                        <hr/>
                        <div className={'rpl__ipt'}></div>
                    </div>
                ),
                dataIndex: 'family',
                render: (text: string, row: any, index: any) => {
                    let sum_fas_ht: number | undefined = 0;

                    for (const {id_family, fas_ht} of data) {
                        if (id_family !== row.id_family) continue;
                        sum_fas_ht += fas_ht ? fas_ht : 0;
                    }
                    const obj = {
                        children: <a>{sum_fas_ht} €</a>,
                        props: {
                            rowSpan: 1,
                            colSpan: 1
                        }


                    };
                    const arrFamiliesName = data.map(d => d.family)
                    const pos = arrFamiliesName.indexOf(text);
                    let length = arrFamiliesName.filter(r => r === text).length

                    if (index === pos) {
                        obj.props.rowSpan = length
                    }
                    if (index > pos) {
                        obj.props.rowSpan = 0
                    }

                    return obj
                },
            },
            {
                title: (
                    <div style={{textAlign: 'center'}}>
                        <div>Categorie</div>
                        <hr/>
                        <Select
                            mode="multiple"
                            allowClear
                            style={{width: '100%'}}
                            placeholder="Please select"
                            onChange={(value: Array<string>) => this.change('categories', value)}

                            maxTagCount={'responsive'}
                        >
                            {categories.map(category => (
                                <Option value={category}>{category}</Option>
                            ))}
                        </Select>
                    </div>
                ),
                dataIndex: 'name',
                onFilter: (value: any, record: any) => record.name.includes(value),
                filterSearch: true,
                render: (text: string, row: any) => {

                    const obj = {
                        children: text,
                        props: {}
                    };
                    return obj;
                },
            },
            {
                title: (
                    <div style={{textAlign: 'center'}}>
                        <div>ABO HT</div>
                        <hr/>
                        <div className={'rpl__ipt'}></div>
                    </div>
                ),
                dataIndex: 'abo_ht',
                render: (text: string, row: any) => {
                    const obj = {
                        children: <div>{text} €</div>,
                        props: {}
                    };
                    return obj;
                },
            },
            {
                title: (
                    <div style={{textAlign: 'center'}}>
                        <div>FAS HT</div>
                        <hr/>
                        <div className={'rpl__ipt'}></div>
                    </div>
                ),
                dataIndex: 'fas_ht',
                render: (text: string, row: any) => {
                    const obj = {
                        children: <div>{text} €</div>,
                        props: {}
                    };
                    return obj;
                },
            },
            // {
            //     title: (
            //         <div style={{textAlign: 'center'}}>
            //             <div>ID DEAL</div>
            //             <hr/>
            //             <Input placeholder='Search' value={this.state.filters.id_deal} name={'id_deal'} onChange={this.change}/>
            //         </div>
            //     ),
            //     dataIndex: 'ids_orders',
            //     render: (text: string, row: any) => {
            //         const obj = {
            //             children: row.ids_orders.map((v: string) => <div>{v}</div>),
            //             props: {}
            //         };
            //         return obj;
            //     },
            // },
        ]
        return (
            <AntdTable columns={columns}
                       dataSource={data}
                       bordered
                       pagination={false}
                       summary={pageData => {

                           let abo_ht = 0;
                           let fas_ht = 0;

                           data.forEach(d => {
                               // @ts-ignore
                               abo_ht += d.abo_ht;
                               // @ts-ignore
                               fas_ht += d.fas_ht;
                           })
                           return (
                               <AntdTable.Summary fixed={'bottom'}>
                                   <AntdTable.Summary.Row>
                                       <AntdTable.Summary.Cell index={0}>
                                           Total
                                       </AntdTable.Summary.Cell>
                                       <AntdTable.Summary.Cell index={1}/>
                                       <AntdTable.Summary.Cell index={2}/>
                                       <AntdTable.Summary.Cell index={3}/>
                                       <AntdTable.Summary.Cell index={4}>
                                           {abo_ht} €
                                       </AntdTable.Summary.Cell>
                                       <AntdTable.Summary.Cell index={5} >
                                           {fas_ht} €
                                       </AntdTable.Summary.Cell>
                                   </AntdTable.Summary.Row>
                               </AntdTable.Summary>
                           )

                       }}
            />
        )
    }
}

export default Table;