 import * as React from 'react';
import './Layout.scss';
import {withRouter} from 'react-router-dom';
import {RouteComponentProps} from "react-router";
import NavbarLeft from "./NavbarLeft/NavbarLeft";
type PathParams = {
    pathname: string
}

interface LayoutProps extends RouteComponentProps<PathParams> {
    children: JSX.Element[] | JSX.Element,
}

interface State {
    collapse: boolean
}

class Layout extends React.Component <LayoutProps, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            collapse: true
        }
    }

    changeSizeNavbar = () => {
        this.setState({collapse: !this.state.collapse});
    }

    render() {
        const {collapse} = this.state;
        return (
            <div id={'Layout'} className={!collapse ? "small__navbar-left" : ""}>
                <NavbarLeft collapse={collapse} changeSizeNavbar={this.changeSizeNavbar}/>
                <main>
                    {this.props.children}
                </main>
            </div>
        );
    }
}

export default withRouter(Layout);
