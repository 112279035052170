import React from 'react';


class IconTrello extends React.Component {

    render() {
        return (
            <svg version="1.1" id="Capa_1" x="0px" y="0px"
                 viewBox="0 0 512 512" fill="black" >
<g>
	<g>
		<path d="M448,0H64C28.704,0,0,28.704,0,64v384c0,35.296,28.704,64,64,64h384c35.296,0,64-28.704,64-64V64
			C512,28.704,483.296,0,448,0z M480,448c0,17.664-14.336,32-32,32H64c-17.632,0-32-14.336-32-32V64c0-17.632,14.368-32,32-32h384
			c17.664,0,32,14.368,32,32V448z"/>
	</g>
</g>
                <g>
	<g>
		<path d="M192,64H96c-17.632,0-32,14.368-32,32v288c0,17.664,14.368,32,32,32h96c17.632,0,32-14.336,32-32V96
			C224,78.368,209.632,64,192,64z M192,384H96V96h96V384z"/>
	</g>
</g>
                <g>
	<g>
		<path d="M416,64h-96c-17.664,0-32,14.368-32,32v160c0,17.664,14.336,32,32,32h96c17.664,0,32-14.336,32-32V96
			C448,78.368,433.664,64,416,64z M416,256h-96V96h96V256z"/>
	</g>
</g>
                <g>
</g>
                <g>
</g>
                <g>
</g>
                <g>
</g>
                <g>
</g>
                <g>
</g>
                <g>
</g>
                <g>
</g>
                <g>
</g>
                <g>
</g>
                <g>
</g>
                <g>
</g>
                <g>
</g>
                <g>
</g>
                <g>
</g>
</svg>

        )
    }
}

export default IconTrello;