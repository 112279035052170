import * as React from 'react';
import {connect, ConnectedProps} from "react-redux";
import {CategoryState, CollectionsState} from '../../Store/Reducers/Collections/types';
import {DacState, ServicesState} from "../../Store/Reducers/Dac/types";
import './Statistiques.scss'
import {faUsers} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {DatePicker} from "antd";
import moment from "moment";
import Table from "./Table/Table";
import SimplePieChart from "../../Components/Statistiques/SimplePieChart/SimplePieChart";

const categories = ["SPECIAL", "SDWAN", "ACCES INTERNET", "UCAAS", "RFIBER", "AUTRES PRESTATIONS", "MOBILITE", "FIREWALL"];

interface RootState {
    collectionsReducer: CollectionsState
}

const mapState = (state: RootState) => ({
    ordersReducer: state.collectionsReducer.Order,
    categoryReducer: state.collectionsReducer.Category,
    familyReducer: state.collectionsReducer.Family
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {

}

interface State {
    dateStart: string,
    dateEnd: string,
    filterCategory: string,
    filterFamily: string,
}

//
// const idFieldSolution = 4073;
// const keySolution = "cb506445698841078c66a1a297ca69cda6eb0434";


class Statistiques extends React.Component<Props, State> {


    constructor(props: any) {
        super(props);
        this.state = {
            dateStart: '',
            dateEnd: '',
            filterCategory: 'abo_ht',
            filterFamily: 'abo_ht',
        }
    }

    componentDidMount() {
        const dateEnd = new Date().toLocaleDateString('fr-FR');
        const dateStart = new Date(new Date().setMonth(new Date().getMonth() - 1)).toLocaleDateString('fr-FR');
        this.setState(prevState => ({...prevState, dateStart, dateEnd}))
    }

    flatRecursive = (a: Array<any>, searchKey: string, t: Array<any>) => {
        if (Array.isArray(a)) {
            for (let i = 0; i < a.length; i++) {
                if (typeof a[i] === "object" && !Array.isArray(a[i])) {
                    for (let [key, value] of Object.entries(a[i])) {
                        if (key === searchKey) {
                            //@ts-ignore
                            if (value.length > 0) {
                                //@ts-ignore
                                value = Array.from(value, (x) => ({...x}))
                            }
                            //@ts-ignore
                            t.push(...value);
                        }
                        if (typeof value === "object" && value) {
                            //@ts-ignore
                            this.flatRecursive(value, searchKey, t);
                        }
                    }
                }
            }
        }
        return t;
    };

    calc(servicesByCategory: Array<ServicesState>, key: string) {
        //@ts-ignore
        const intArray = Array.from(servicesByCategory, s => s[key]).filter(s => s);
        if (intArray.length > 0) {
            const result = intArray.reduce((a, c) => a + c);
            if (result && !isNaN(result)) {
                return result;
            } else {
                return 0;
            }
        } else {
            return 0;
        }
    }

    calcByCategory(services: Array<ServicesState>) {
        let stats: any = [];
        categories.forEach(category => {
            const servicesByCategory = services.filter(service => service.family === category);
            const totalAbo = this.calc(servicesByCategory, 'abo_ht');
            const mgeAbo = this.calc(servicesByCategory, 'mge_abo');
            //@ts-ignore
            let percentAbo = parseInt(mgeAbo / totalAbo * 100);
            if (!percentAbo && isNaN(percentAbo)) {
                percentAbo = 0;
            }
            const totalFas = this.calc(servicesByCategory, 'fas_ht');
            const mgeFas = this.calc(servicesByCategory, 'mge_fas');
            //@ts-ignore
            let percentFas = parseInt(mgeFas / totalFas * 100);
            if (!percentFas && isNaN(percentFas)) {
                percentFas = 0;
            }
            stats = [...stats, {category, totalAbo, percentAbo, totalFas, percentFas}]
        })
        return stats;
    }

    changeDate = (date: any, dateString: string, name: string) => {
        const ndateString = new Date(dateString).toLocaleDateString('fr-FR');
        this.setState(prevState => ({...prevState, [`${name}`]: ndateString}));
    }

    conditionsDate = (arr: any, arrDateStart: any, arrDateEnd: any) => (
        new Date(parseInt(arr[2]), parseInt(arr[1]), parseInt(arr[0])) > new Date(parseInt(arrDateStart[2]), parseInt(arrDateStart[1]), parseInt(arrDateStart[0]))
        && new Date(parseInt(arr[2]), parseInt(arr[1]), parseInt(arr[0])) < new Date(parseInt(arrDateEnd[2]), parseInt(arrDateEnd[1]), parseInt(arrDateEnd[0]))
    )

    calcSum(orders: Array<DacState>, categoryId: string, keyValue: string) {
        let sum = 0;
        orders.forEach(order => {
            order.sites.forEach(site => {
                site.services.forEach(service => {
                    if (categoryId === service.id_categorie) {
                        //@ts-ignore
                        sum += service[keyValue];
                    }
                })
            })
        })
        return sum;
    }

    refactorData = (orders: Array<DacState>, services: Array<ServicesState>) => {
        const families = this.props.familyReducer;
        const categories = this.props.categoryReducer;
        categories.map((category) => {
            const family = families.find(family => family._id === category.id_family)
            if (family) {
                category.family = family.name;
            }
            if (orders.length > 0) {
                orders.forEach(order => {
                    if (!category.orders) {
                        category.orders = []
                    } else {
                        const services = order.sites.flatMap(site => site.services)
                        const idsCategoryInOrder = services.map(service => service.id_categorie);
                        const idsOrdersInCategory = category.orders.map(order => order._id)
                        if (idsCategoryInOrder.includes(category._id) && !idsOrdersInCategory.includes(order._id)) {
                            category.orders.push(order)
                        }
                        const calcAboHt = this.calcSum(orders, category._id, 'abo_ht');
                        const calcFasHt = this.calcSum(orders, category._id, 'fas_ht');
                        category.abo_ht = calcAboHt ? calcAboHt : 0;
                        category.fas_ht = calcFasHt ? calcFasHt : 0;
                        category.ids_orders = category.orders.map((order: DacState) => order.society.id_pipedrive)
                    }

                    return category
                })
            } else {
                category.abo_ht = 0;
                category.fas_ht = 0;
            }
        })

        return categories;
    }

    getFamilyValue = (data: Array<CategoryState>, type: string) => {

        return this.props.familyReducer.map(family => {
            const f = data.filter(d => d.id_family === family._id);
            let sum: number = 0;
            f.forEach(t => {
                // @ts-ignore
                if (t && t[type]) {
                    // @ts-ignore
                    sum += t[type]
                }
            })
            return {name: family.name, value: sum}
        })

    }

    render() {
        const {dateStart, dateEnd} = this.state;
        const orderFilter = this.props.ordersReducer.filter(o => o.tag === "won").filter(order => {
            let arr = order.society.order_date.split('/');
            let arrDateStart = dateStart.split('/');
            let arrDateEnd = dateEnd.split('/');
            if (this.conditionsDate(arr, arrDateStart, arrDateEnd)) {
                return order;
            }
        });
        const services = this.flatRecursive(orderFilter, "services", []);
        const data = this.refactorData(orderFilter, services);
        // @ts-ignore
        const categoriesDataChart = data.map(d => ({name: d.name, value: d[this.state.filterCategory]}));

        return (
            <div id={'Statistiques'}>
                <div className="title_page">
                    <FontAwesomeIcon icon={faUsers}/>
                    <h2>Statistiques</h2>
                </div>
                <div className={'ctn-inputs-date'}>
                    {dateStart &&
                    <div className={'ctn-input-date'}>
                        <label> Date de debut</label>
                        <DatePicker defaultValue={moment(dateStart, "DD-MM-YYYY")}
                                    onChange={(date: any, dateString: string) => this.changeDate(date, dateString, 'dateStart')}/>
                    </div>
                    }
                    {dateEnd &&
                    <div className={'ctn-input-date'}>
                        <label> Date de fin</label>
                        <DatePicker defaultValue={moment(dateEnd, "DD-MM-YYYY")}
                                    onChange={(date: any, dateString: string) => this.changeDate(date, dateString, 'dateEnd')}/>
                    </div>
                    }
                </div>
                <Table data={data}/>
                <div className={'ctn_chart'}>
                    <div>
                        <h2>Famille</h2>
                        <div>
                            <button onClick={() => this.setState(() => ({filterCategory: 'abo_ht'}))}>Abo</button>
                            <button onClick={() => this.setState(() => ({filterCategory: 'fas_ht'}))}>Fas</button>
                        </div>
                        <SimplePieChart data={this.getFamilyValue(data, this.state.filterFamily)} name={`family`}/>
                    </div>
                    <div>
                        <h2>Categorie</h2>
                        <div>
                            <button onClick={() => this.setState(() => ({filterFamily: 'abo_ht'}))}>Abo</button>
                            <button onClick={() => this.setState(() => ({filterFamily: 'fas_ht'}))}>Fas</button>
                        </div>
                        <SimplePieChart data={categoriesDataChart} name={`categorie`}/>
                    </div>
                </div>
            </div>
        )
    }
}


export default connector(Statistiques);
