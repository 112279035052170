import * as React from 'react';
import './InfoPrice.scss';
import {DacState} from "../../../../../Store/Reducers/Dac/types";
import {connect, ConnectedProps} from "react-redux";
import {TotalState} from "../../../../../Store/Reducers/Dac/Total/types";

interface RootState {
    dacReducer: DacState,
    totalReducer: TotalState,
}

const mapState = (state: RootState, ownProps: any) => {

    return {
        serviceReducer: state.dacReducer.sites[ownProps.nbSite].services[ownProps.nbService],
        totalReducer: state.totalReducer,
        countServices: state.dacReducer.countServices
    }
};

const mapDispatch = (dispatch: Function, ownProps: { nbSite: number, nbService: number }) => ({
});

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
    nbSite: number,
    nbService: number,
    unit: string,
    ctnName?: string
}

class InfoPrice extends React.PureComponent<Props, any> {

    renderUnitMarginCalc = () => {
        const {serviceReducer, unit} = this.props;
        let res;
        // // @ts-ignore
        // const prixVente = (serviceReducer[`${unit}_ht`] * (1 - ((this.props.totalReducer[`discount_${unit}`] / this.props.countServices) /100)));
        //
        // // @ts-ignore
        // let calc = prixVente - serviceReducer[`pa_${unit}`];
        // @ts-ignore
        if (serviceReducer && serviceReducer[`${unit}_ht`]) {
            // @ts-ignore
            // let calc = serviceReducer[`tx_${unit}`] - (this.props.totalReducer[`discount_${unit}`] / this.props.countServices)
            // res = parseFloat(`${calc}`).toFixed(2);

            // @ts-ignore
            const prixVente = (serviceReducer[`${unit}_ht`] * (1 - ((this.props.totalReducer[`discount_${unit}`] / this.props.countServices) /100)));

            // @ts-ignore
            let mge = prixVente - serviceReducer[`pa_${unit}`];
            // @ts-ignore
            res = parseFloat(`${(mge / serviceReducer[`pa_${unit}`] * 100)}`).toFixed(2);
        } else {
            res = 0;
        }
        return res;
    };

    renderPrice = () => {
        const {serviceReducer, unit} = this.props;
        let res;
        //@ts-ignore
        if (serviceReducer && serviceReducer[`${unit}_ht`]) {
            //@ts-ignore
            let calc = serviceReducer[`${unit}_ht`] * ((100 - this.props.totalReducer[`discount_${unit}`])) / 100 * serviceReducer.quantity;

            res = parseFloat(`${calc}`).toFixed(2);
        } else {
            res = 0;
        }
        return res;
    };

    renderMarginEuros = () => {
        const {serviceReducer, unit} = this.props;
        let res;
        // @ts-ignore
        if (serviceReducer && serviceReducer[`${unit}_ht`]) {
            // @ts-ignore
            const prixVente = (serviceReducer[`${unit}_ht`] * (1 - ((this.props.totalReducer[`discount_${unit}`] / this.props.countServices) /100)));

            // @ts-ignore
            let calc = prixVente - serviceReducer[`pa_${unit}`];
            //@ts-ignore
            res = parseFloat(`${calc}`).toFixed(2);
        } else {
            res = 0;
        }
        return res;
    };

    renderMarginTotalEuros = () => {
        const {serviceReducer, unit} = this.props;
        let res;
        // @ts-ignore
        if (serviceReducer && serviceReducer[`${unit}_ht`]) {
            // @ts-ignore
            const prixVente = (serviceReducer[`${unit}_ht`] * (1 - ((this.props.totalReducer[`discount_${unit}`] / this.props.countServices) /100)));
            // @ts-ignore
            let calc = (prixVente - serviceReducer[`pa_${unit}`]) * serviceReducer.quantity;
            res = parseFloat(`${calc}`).toFixed(2);
        } else {
            res = 0;
        }
        return res;
    };

    renderBuyingPrice = () => {
        const {serviceReducer, unit} = this.props;
        let res;
        // @ts-ignore
        if (serviceReducer && serviceReducer[`pa_${unit}`]) {
            // @ts-ignore
            res = parseFloat(serviceReducer[`pa_${unit}`]).toFixed(2);
        } else {
            res = 0;
        }
        return res;
    };

    render() {
        const {unit, ctnName} = this.props;
        return (
            <div className={`infoPrice ${ctnName}`}>
                    <p >
                        {unit.toUpperCase()} Total: {this.renderPrice()}€{unit === 'abo' && "/mois"}
                    </p>
                    <p >
                        Prix d'achat: {this.renderBuyingPrice()} €
                    </p>
                    <p >
                        Marge en %: ({this.renderUnitMarginCalc()}%)
                    </p>
                    <p >
                        Marge en €: ({this.renderMarginEuros()}€)
                    </p>
                    <p >
                        Marge total:
                        ({this.renderMarginTotalEuros()}€)
                    </p>
            </div>
        )
    }
}

export default connector(InfoPrice);
