import {combineReducers} from "redux";
import dacReducer from './Dac/dacReducer';
import collectionsReducer from "./Collections/collectionsReducer";
import totalReducer from './Dac/Total/totalReducer';
import actionsDacReducer from './Dac/ActionsDac/actionsDacReducer';
const rootReducers = combineReducers({
    dacReducer,
    collectionsReducer,
    totalReducer,
    actionsDacReducer
})

export default rootReducers;