import axios from "axios";
import {Contact, Deal, Organizations} from "../Types/Pipedrive";
import Socket from "../Socket/Socket";

const apiPipe = process.env.REACT_APP_API_PIPEDRIVE;
const tokenPipe = process.env.REACT_APP_PIPEDRIVE_TOKEN;


class Pipedrive {

    static updateDeal(id_pipedrive: string): Promise<Deal> {
        return new Promise((resolve, reject) => {
            if (id_pipedrive) {
                axios.put(`https://api.pipedrive.com/v1/deals/${id_pipedrive}?api_token=${tokenPipe}`,
                    {
                        status: 'won'
                    })
                    .then(res => {

                        if (res) {
                            resolve(res.data.data)
                        }
                    })
                    .catch(error => {
                        console.error(error)
                    })
            }
        })
    }

    static getDeal(id_pipedrive: string): Promise<Deal> {
        return new Promise((resolve, reject) => {
            if (id_pipedrive) {
                axios.get(`${apiPipe}/${id_pipedrive}?api_token=${tokenPipe}&limit=500`)
                    .then(res => {
                        if (res) {
                            resolve(res.data.data)
                        }
                    })
                    .catch(error => {
                        console.error(error)
                    })
            }
        })
    }


    static getAllDeals(start: number): Promise<Array<Deal>> {
        return new Promise((resolve, reject) => {
            axios.get(`${apiPipe}?status=all_not_deleted&start=${start}&api_token=${tokenPipe}`)
                .then(res => {
                    if (res) {
                        resolve(res.data.data)
                    }
                })
                .catch(error => {
                    reject(error)
                })
        })
    }

    static getOrgsWithPaginations(start: number, limit: number): Promise<Array<Organizations>> {
        return new Promise((resolve, reject) => {
            axios.get(`https://api.pipedrive.com/v1/organizations?start=${start}&limit=${limit}&api_token=d168467b213a71acdc4ba5cb5b2cfe96529ac061`)
                .then(res => {
                    resolve(res.data.data)
                })
                .catch(error => {
                    console.error(error)
                    reject([])
                })
        })
    }

    static getContacts(org_id: number): Promise<Array<Contact>> {
        return new Promise((resolve, reject) => {
            if (org_id) {
                axios.get(`https://sayse.pipedrive.com/v1/organizations/${org_id}/persons?api_token=d168467b213a71acdc4ba5cb5b2cfe96529ac061`)
                    .then(res => {
                        resolve(res.data.data);
                    })
                    .catch(error => {
                        console.error(error);
                        reject([])
                    })
            } else {
                reject([])
            }
        })
    }

    static getOrganisation(start: number): Promise<Array<Organizations>> {
        return new Promise((resolve, reject) => {
            axios.get(`https://api.pipedrive.com/v1/organizations?start=${start}&api_token=d168467b213a71acdc4ba5cb5b2cfe96529ac061`)
                .then(res => {
                    resolve(res.data.data)
                })
                .catch(error => {
                    console.error(error)
                    reject(error)
                })
        })
    }

    static getNbrDeals(organisationId: number, organisationName: string): Promise<Organizations> {
        return new Promise((resolve, reject) => {
            axios.get(`https://api.pipedrive.com/v1/persons/search?term=${organisationName}&organization_id=${organisationId}&start=0&api_token=d168467b213a71acdc4ba5cb5b2cfe96529ac061`)
                .then(res => {
                    resolve(res.data.data)
                })
                .catch(error => {
                    console.error(error)
                    reject([])
                })
        })
    }

    static dealFields() {
        return new Promise((resolve, reject) => {
            axios.get('https://api.pipedrive.com/v1/dealFields?start=0&api_token=d168467b213a71acdc4ba5cb5b2cfe96529ac061')
                .then(res => {
                    resolve(res);
                }).catch(err => {
                    reject(err);
                })
        })
    }

    static updateOrgField(obj: any) {
        return new Promise((resolve, reject) => {
            axios.put('https://api.pipedrive.com/v1/deals/1?api_token=d168467b213a71acdc4ba5cb5b2cfe96529ac061',
                obj,
                {
                    "headers": {
                        "Content-Type": "application/json"
                    }
                })
                .then(res => {
                    resolve(res);
                }).catch(err => reject(err))
        })
    }

    static countOrgs(): Promise<number> {
        return new Promise((resolve, reject) => {
            axios.get(`https://api.pipedrive.com/v1/organizations?get_summary=1&api_token=d168467b213a71acdc4ba5cb5b2cfe96529ac061`)
            .then(res => {
                resolve(res.data.additional_data.summary.total_count);
            })
            .catch(error => {
                console.error(error)
            })
        })
    }
}

export default Pipedrive;
