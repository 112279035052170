import React, {Component} from 'react';

class IconHome extends Component {
    render() {
        return (
            <svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                 viewBox="0 0 357.4 415.2" space="preserve">
<path d="M329.4,415.2h-78.8c-15.4,0-27.9-12.5-27.9-27.9V285.1c0-7.1-5.8-12.9-12.9-12.9h-62.3c-7.1,0-12.9,5.8-12.9,12.9v102.2
	c0,15.4-12.5,27.9-27.9,27.9H27.9C12.5,415.2,0,402.7,0,387.3V178.8c0-7.5,2.9-14.5,8.2-19.8l151-151c10.7-10.7,28.2-10.7,38.9,0
	l151.1,151c5.3,5.3,8.2,12.3,8.2,19.7v208.5C357.4,402.7,344.8,415.2,329.4,415.2z"/>
</svg>

        );
    }
}

export default IconHome;