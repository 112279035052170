import * as React from 'react';
import './ExcelInput.scss';

const backUrl = process.env.REACT_APP_API_DAC;

interface State {
}

interface Props {

}

class ExcelInput extends React.Component<Props, State> {

    uploadFile = async () => {
        const link = document.createElement('a');
        link.href = `https://byos-dev.sayse.fr:4243/api/excel/download`;
        link.setAttribute('download', 'catalogue.xlsx');
        document.body.appendChild(link);
        link.click();
        link.remove();
    };

    render() {
        return (
            <button onClick={this.uploadFile}>Telecharger Excel</button>
        )
    }
}

export default ExcelInput;
