import * as React from 'react';
import './ActionsDac.scss';
import {DacState, ServicesState, SitesState} from "../../../Store/Reducers/Dac/types";
import {connect, ConnectedProps} from "react-redux";
import {
    addService,
    addSite,
    duplicateSites,
    editDac,
    editError,
    editSites,
    editSociety,
    pasteServices,
    removeServices,
    removeSites,
    setDAC
} from "../../../Store/Reducers/Dac/actions";
import Socket from "../../../utils/Socket/Socket";
import {notification, Tooltip} from "antd";
import User from "../../../utils/User/User";
import {faSave} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ActionsDacState} from "../../../Store/Reducers/Dac/ActionsDac/types";
import {editActionsDac} from "../../../Store/Reducers/Dac/ActionsDac/actions";
import {TotalState} from "../../../Store/Reducers/Dac/Total/types";
import {editTotal, receiveTotal} from "../../../Store/Reducers/Dac/Total/actions";
import PDFButton from "../PDF/PDFButton";
import axios from "axios";
import TotalFunctions from "./Total/Functions/TotalFunctions";
import SelectSearch from "../../Input/SelectSearch/SelectSearch";
import IconDelete from "../../IconSVG/IconDelete";
import IconDuplicate from "../../IconSVG/IconDuplicate";
import IconAdd from "../../IconSVG/IconAdd";

//
// const bdcKey = '9c6420bf0e752e05c2b2c3e9165bfa36db65cfe0';

interface RootState {
    dacReducer: DacState,
    actionsDacReducer: ActionsDacState,
    totalReducer: TotalState,
}

const mapState = (state: RootState, ownProps: any) => ({
    dacReducer: state.dacReducer,
    dac_society_reducer: state.dacReducer.society,
    sitesReducer: state.dacReducer.sites,
    selectedSites: state.actionsDacReducer.copySites,
    selectedServices: state.actionsDacReducer.copyServices,
    totalReducer: state.totalReducer,
    discount_abo: state.totalReducer.discount_abo,
    discount_fas: state.totalReducer.discount_fas,

});

const mapDispatch = {
    addSite: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => addSite(),
    addService: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, ids: Array<string>) => addService(ids),
    editError: (section: string, key: string, value: string, nbSite: number, nbService: number) => editError(section, key, value, nbSite, nbService),
    removeSites: (ids: Array<string>) => removeSites(ids),
    removeServices: (ids: Array<{ idSite: string, idService: string }>) => removeServices(ids),
    clearSelected: (name: string, selected: Array<string>) => editActionsDac(name, selected),
    duplicateSites: (sites: Array<SitesState>) => duplicateSites(sites),
    duplicateServices: (services: Array<ServicesState>, sites: Array<string>) => pasteServices(services, sites),
    editSociety: (name: string, value: number | string) => editSociety(name, value),
    editTotal: (name: string, value: number) => editTotal(name, value),
    receiveTotal: (value: TotalState) => receiveTotal(value),
    editSites: (name: string, value: string | number, nbSite: number) => editSites(name, value, nbSite),
    editDac: (nb: number) => editDac('countServices', nb),
    pushCopyServices: (name: string, value: Array<{ idSite: string, idService: string }>) => editActionsDac(name, value),
    setDAC: (value: DacState) => setDAC(value)
}

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {

    durations: Array<{ label: string, id: string }>
}

interface State {
    disabledPdf: boolean,
    subscribe_duration: string,
    change: boolean,
    crashTest: Array<any>,
    pa_fas_total: number,
    pa_abo_total: number,
}

class ActionsDac extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            disabledPdf: true,
            subscribe_duration: "",
            change: false,
            crashTest: ['re', 're'],
            pa_fas_total: 0,
            pa_abo_total: 0,
        }
    }

    async getIdDurationPipe(duration: string) {
        try {
            const res = await axios.get('https://api.pipedrive.com/v1/dealFields/12465?api_token=d168467b213a71acdc4ba5cb5b2cfe96529ac061');
            //@ts-ignore
            return res.data.data.options.find(item => parseInt(item.label) === parseInt(duration)).id;
        } catch (e) {
            console.error(e)
            return;
        }
    }


    checkError = () => {
        let error = false;
        const requiredInputs = document.querySelectorAll('[data-required="true"]');
        requiredInputs.forEach(element => {
            const value = element.getAttribute('data-value') ? element.getAttribute('data-value') : element.value;
            if (!value) {
                element.classList.add('error__Field')
            }
        })
        return error;
    }

    saveQuote = () => {
        const {dacReducer} = this.props;
        if (!this.checkError()) {
            Socket.read('Order', {['society.id_order']: dacReducer.society.id_order})
                .then(res => {
                    if (res.data.length > 0) {
                        Socket.update('Order', res.data[0]._id, null, dacReducer)
                            .then(res => {
                                if (res.status === 200) {
                                    notification.success({message: "Votre devis a bien été mis a jour. Retrouvez dans la dans l'historique."});
                                    axios.put(`https://api.pipedrive.com/v1/deals/${dacReducer.society.id_pipedrive}?api_token=d168467b213a71acdc4ba5cb5b2cfe96529ac061`,
                                        {
                                            '9c6420bf0e752e05c2b2c3e9165bfa36db65cfe0': dacReducer.society.id_order,
                                            "d3a396fe2f9e8d79b7b478645e0c598a05798c9f": parseInt(dacReducer.society.subscribe_duration),
                                            "07202d8e78c6626b26b986eaa4c61d7a53188a73": dacReducer.society.fas_ht,
                                            "5218646ecb703b444ec78d1f7d6b79d17a8bc72c": dacReducer.society.mge_fas,
                                            "value": dacReducer.society.abo_ht,
                                            "6505fba293d443baf594b1bcb941aa9a8fba5dc7": dacReducer.society.mge_abo,
                                            "currency": "EUR",
                                            "07202d8e78c6626b26b986eaa4c61d7a53188a73_currency": "EUR",
                                            "6505fba293d443baf594b1bcb941aa9a8fba5dc7_currency": "EUR",
                                            "5218646ecb703b444ec78d1f7d6b79d17a8bc72c_currency": "EUR",
                                        },
                                        {
                                            "headers": {
                                                "Content-Type": "application/json"
                                            }
                                        })
                                        .then(res => {
                                            localStorage.removeItem('dac')
                                            // this.props.setDAC(dacState);
                                        }).catch(err => console.error(err))
                                } else {
                                    console.error(res);
                                    notification.error({message: "Une erreur est survenu lors de la mise a jour."})
                                }
                            })
                    } else {
                        Socket.insert('Order', dacReducer)
                            .then(res => {
                                notification.success({message: "Votre devis a bien été sauvegardé. Retrouvez dans la dans l'historique."});

                                User.userInfo()
                                    .then(user => {
                                        const quoteType = dacReducer.billing.contract ? "contrat" : "devis";
                                        const title = `Nouveau ${quoteType}`;
                                        const message = `ID: ${dacReducer.society.id_pipedrive} | Deal: ${dacReducer.society.title}`;
                                        const details = `${user.firstname} ${user.lastname} à créer un ${quoteType} pour le deal ${dacReducer.society.title}`;
                                        Socket.insert("ToolitLogs", {title, message, details})
                                            .catch(err => console.error(err))
                                        Socket.sendNotif({title, message, details});
                                        axios.put(`https://api.pipedrive.com/v1/deals/${dacReducer.society.id_pipedrive}?api_token=d168467b213a71acdc4ba5cb5b2cfe96529ac061`,
                                            {
                                                '9c6420bf0e752e05c2b2c3e9165bfa36db65cfe0': dacReducer.society.id_order,
                                                "d3a396fe2f9e8d79b7b478645e0c598a05798c9f": parseInt(dacReducer.society.subscribe_duration),
                                                "07202d8e78c6626b26b986eaa4c61d7a53188a73": dacReducer.society.fas_ht,
                                                "5218646ecb703b444ec78d1f7d6b79d17a8bc72c": dacReducer.society.mge_fas,
                                                "value": dacReducer.society.abo_ht,
                                                "6505fba293d443baf594b1bcb941aa9a8fba5dc7": dacReducer.society.mge_abo,
                                                "currency": "EUR",
                                                "07202d8e78c6626b26b986eaa4c61d7a53188a73_currency": "EUR",
                                                "6505fba293d443baf594b1bcb941aa9a8fba5dc7_currency": "EUR",
                                                "5218646ecb703b444ec78d1f7d6b79d17a8bc72c_currency": "EUR",
                                            },
                                            {
                                                "headers": {
                                                    "Content-Type": "application/json"
                                                }
                                            })
                                            .then(res => {
                                                // this.props.setDAC(dacState);
                                                localStorage.removeItem('dac')
                                            }).catch(err => console.error(err))
                                    });
                                this.setState(() => ({disabledPdf: false}))
                            })
                            .catch(err => {
                                console.error(err)
                                notification.error({message: "Une erreur est survenu lors de l'enregistrement."})
                            })
                    }
                })
                .catch(err => console.error(err))

        } else {
            notification.error({message: "Une erreur est survenu lors de l'enregistrement."});
        }
    };

    removeSites = () => {
        const {selectedSites} = this.props;
        this.props.removeSites(selectedSites);
        this.props.clearSelected('copySites', []);
    }

    removeServices = () => {
        const {selectedServices} = this.props;
        if (selectedServices.length > 0) {
            this.props.removeServices(selectedServices);
            this.props.clearSelected('copyServices', []);
        }
    }

    saveIfError = async () => {
        const {id_pipedrive} = this.props.dacReducer.society;
        if (id_pipedrive) {
            let res = await Socket.readLean('SaveCrashToolit', {'society.id_pipedrive': id_pipedrive}, {});
            if (res.data.length > 0) {
                Socket.update('SaveCrashToolit', res.data[0]._id, null, this.props.dacReducer);
            } else {
                Socket.insert('SaveCrashToolit', this.props.dacReducer)
            }
        }
    }


    componentDidMount() {
        this.dispatchFinalTotal();

        const services = this.flatRecursive(this.props.sitesReducer, 'services', []);
        const findDuration = Math.max(...services.map(s => Number.isInteger(parseInt(s.duration == 37 ? 0 : s.duration)) ? parseInt(s.duration == 37 ? 0 : s.duration) : 0))
        const duration = findDuration == 0 ? `37` : `${findDuration}`;
        const pa_abo_total = services.reduce((a, c) => a + c.pa_abo, 0)
        const pa_fas_total = services.reduce((a, c) => a + c.pa_fas, 0)
        this.setState(prevState => ({...prevState, subscribe_duration: duration, pa_abo_total, pa_fas_total}));
        TotalFunctions.calcPrice(this.props)
        this.props.editDac(this.countServices());

        if (this.props.dacReducer.society.subscribe_duration) {
            this.setState(() => ({
                subscribe_duration: this.props.dacReducer.society.subscribe_duration
            }))
        }
    }

    componentWillUnmount() {
        this.saveIfError()
        this.props.clearSelected('copySites', []);
        this.props.clearSelected('copyServices', []);
    }

    componentDidCatch(error: any, errorInfo: any) {
        // Vous pouvez aussi enregistrer l'erreur au sein d'un service de rapport.

    }

    flatRecursive = (a: Array<any>, searchKey: string, t: Array<any>) => {
        if (Array.isArray(a)) {
            for (let i = 0; i < a.length; i++) {
                if (typeof a[i] === "object" && !Array.isArray(a[i])) {
                    for (let [key, value] of Object.entries(a[i])) {
                        if (key === searchKey) {
                            //@ts-ignore
                            if (value.length > 0) {
                                //@ts-ignore
                                value = Array.from(value, (x) => ({...x}))
                            }
                            //@ts-ignore
                            t.push(...value);
                        }
                        if (typeof value === "object" && value) {
                            //@ts-ignore
                            this.flatRecursive(value, searchKey, t);
                        }
                    }
                }
            }
        }
        return t;
    };

    dispatchFinalTotal = () => {
        const {discount_abo, discount_fas, mge_abo, mge_fas, tx_abo, tx_fas, abo_ht, fas_ht} = this.props.totalReducer;
        const keys = {
            discount_abo: discount_abo,
            discount_fas: discount_fas,
            mge_abo: this.margeTotal('abo'),
            mge_fas: this.margeTotal('fas'),
            tx_abo: this.margeTaux('abo'),
            tx_fas: this.margeTaux('fas'),
            abo_ht: this.prixVenteTotal('abo'),
            fas_ht: this.prixVenteTotal('fas'),
        };
        for (let [key, value] of Object.entries(keys)) {
            this.props.editSociety(key, value);
        }
    }

    countServices() {
        let countServices = 0;
        for (let {quantity} of this.props.sitesReducer.flatMap(s => s.services)) {
            countServices += quantity;
        }
        return countServices;
    }

    deselectServicesRemove(services: Array<string>) {
        return this.props.selectedServices.filter(s => services.includes(s.idService));
    }

    checkChangeServices(prevSites: Array<SitesState>, sites: Array<SitesState>) {
        const prevServices = prevSites.flatMap((s: any) => s.services);
        const services = sites.flatMap((s: any) => s.services);
        if (JSON.stringify(prevServices) !== JSON.stringify(services)) {
            TotalFunctions.calcPrice(this.props)
            this.props.editDac(this.countServices());
            const newServicesSelected = this.deselectServicesRemove(services.flatMap((service => service.id)));
            this.props.pushCopyServices('copyServices', newServicesSelected)
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if (prevProps.totalReducer !== this.props.totalReducer) {
            this.dispatchFinalTotal();
        }
        if (prevProps.sitesReducer !== this.props.sitesReducer) {
            this.checkChangeServices(prevProps.sitesReducer, this.props.sitesReducer);
            const services = this.flatRecursive(this.props.sitesReducer, 'services', []);
            const findDuration = Math.max(...services.map(s => Number.isInteger(parseInt(s.duration == 37 ? 0 : s.duration)) ? parseInt(s.duration == 37 ? 0 : s.duration) : 0))
            const duration = findDuration == 0 ? `37` : `${findDuration}`;
            const pa_abo_total = services.reduce((a, c) => a + c.pa_abo, 0)
            const pa_fas_total = services.reduce((a, c) => a + c.pa_fas, 0)
            this.setState(prevState => ({...prevState, subscribe_duration: duration, pa_abo_total, pa_fas_total}));
        }
        if (prevProps.sitesReducer.length !== this.props.sitesReducer.length) {
            this.saveIfError();
        }
        if (this.state.subscribe_duration !== prevState.subscribe_duration) {
            this.props.editSociety('subscribe_duration', this.state.subscribe_duration);
        }
    }

    duplicateSites = () => {
        const {selectedSites, sitesReducer} = this.props;
        const sites = sitesReducer.filter(s => selectedSites.includes(s._id));
        this.props.duplicateSites(sites);
    }

    duplicateServices = () => {
        const {selectedServices, selectedSites, sitesReducer} = this.props;
        const allServices = this.flatRecursive(sitesReducer, "services", []);
        const services = allServices.filter(s => Array.from(selectedServices, o => o.idService).includes(s.id));
        this.props.duplicateServices(services, selectedSites);
    }

    editDiscount = (event: React.ChangeEvent<HTMLInputElement>) => {
        let {name, value} = event.target;
        value = (value) ? value : "0";
        this.props.editTotal(name, parseFloat(value))
    }

    crash = () => {
        //@ts-ignore
        this.setState(state => ({crashTest: ''}));
    }

    // margeTaux = (unit: string) => {
    //     // @ts-ignore
    //     const prixVente = this.props.totalReducer[`${unit}_ht`];
    //     // @ts-ignore
    //     let mge = (prixVente * ((100 - this.props.dac_society_reducer[`discount_${unit}`]) / 100)) - this.state[`pa_${unit}_total`];
    //     // @ts-ignore
    //     return parseFloat(`${(mge / prixVente)}`).toFixed(2);
    // }

    renderMarge = (unit: string) => {
        // @ts-ignore
        const prixVente = this.props.totalReducer[`${unit}_ht`];
        // @ts-ignore
        return parseFloat((prixVente * ((100 - this.props.dac_society_reducer[`discount_${unit}`]) / 100)) - this.state[`pa_${unit}_total`]).toFixed(2);
    }


    prixVenteTotal = (unit: string) => {

        //@ts-ignore
        const price = this.discountMultiplicator(unit) * this.props.totalReducer[`${unit}_ht`];
        return price
    }

    prixAchatTotal = (unit: string) => {
        //@ts-ignore
        const price = this.state[`pa_${unit}_total`];
        return price;
    }

    margeTotal = (unit: string) => {
        const marge = this.prixVenteTotal(unit) - this.prixAchatTotal(unit);
        return marge;

    }

    discountMultiplicator = (unit: string) => {
        //@ts-ignore
        const discount = this.props.dac_society_reducer[`discount_${unit}`];
        const nDiscount = discount ? discount : 0;
        return (100 - nDiscount) / 100;
    }


    margeTaux = (unit: string) => {
        const tauxMarge = (this.margeTotal(unit) / this.prixVenteTotal(unit)) * 100;
        return tauxMarge ? tauxMarge : 0;

    }


    render() {
        const {disabledPdf} = this.state;
        const {selectedSites, selectedServices, dac_society_reducer} = this.props;
        return (
            <div id={'ActionsDac'}>
                <div className={"disabled-ctn"}>
                    <input type={'text'} value={selectedSites.length} disabled/>
                    <label>Site selectionné</label>
                </div>
                <div className={"disabled-ctn"}>
                    <input type={'text'} value={selectedServices.length} disabled/>
                    <label>Service selectionné</label>
                </div>
                <div>
                    <input type="number" name={"discount_abo"} placeholder={`${dac_society_reducer.discount_abo}`}
                           onChange={this.editDiscount}/>
                    <label>Remise Abo (%)</label>
                </div>
                <Tooltip placement={'top'} title={
                    <div>
                        <div className={'d-flex ctn_mg'}>
                            <label className={'mr-3'}>Marge: </label>
                            <span> {this.margeTotal('abo')}€</span>
                        </div>
                        <div className={'d-flex  ctn_mg'}>
                            <label className={'mr-3'}>Marge taux: </label>
                            <span> {parseFloat(`${this.margeTaux('abo')}`).toFixed(2)}%</span>
                        </div>
                    </div>
                }>
                    <div className={"bg-green"}>
                        <input type="text" placeholder={`${parseFloat(`${dac_society_reducer.abo_ht}`).toFixed(2)}`}
                               disabled/>
                        <label>Total Abo (€)</label>
                    </div>
                </Tooltip>
                <div>
                    <input type="number" name={"discount_fas"} placeholder={`${dac_society_reducer.discount_fas}`}
                           onChange={this.editDiscount}/>
                    <label>Remise Fas (%)</label>
                </div>

                <Tooltip placement={'top'} title={
                    <div>
                        <div className={'d-flex  ctn_mg'}>
                            <label className={'mr-3'}>Marge: </label>
                            <span> {this.margeTotal('fas')}€</span>
                        </div>
                        <div className={'d-flex ctn_mg'}>
                            <label className={'mr-3'}>Marge taux: </label>
                            <span> {parseFloat(`${this.margeTaux('fas')}`).toFixed(2)}%</span>
                        </div>
                    </div>
                }>
                    <div className={"bg-pink"}>
                        <input type="text" placeholder={`${parseFloat(`${dac_society_reducer.fas_ht}`).toFixed(2)}`}
                               disabled/>
                        <label>Total Fas (€)</label>
                    </div>
                </Tooltip>
                <div>
                    <SelectSearch actionReducer={(_: string, value: string) => this.setState(prevState => ({
                        ...prevState,
                        subscribe_duration: value
                    }))} items={this.props.durations} name={"subscribe_duration"}
                                  placeholder={'Duréee'}
                                  value={`${this.state.subscribe_duration}`}
                                  ctnName={"input__duree"}
                                  valuesDisplay={"label"} valuesKey={"id"} disableLabel={true}/>
                </div>
                <button onClick={(e) => this.props.addService(e, selectedSites)}><IconAdd/>
                    <span>Ajouter service</span>
                </button>
                {selectedServices.length > 0 &&
                    <>
                        {selectedSites.length > 0 &&
                            <button onClick={this.duplicateServices}><IconDuplicate/><span>Dupliquer service</span>
                            </button>
                        }
                        <button onClick={this.removeServices}><IconDelete/><span>Supprimer service</span></button>
                    </>
                }

                <button onClick={(e) => this.props.addSite(e)}><IconAdd/><span>Ajouter site</span></button>


                {selectedSites.length > 0 &&
                    <>
                        <button onClick={this.duplicateSites}><IconDuplicate/><span>Dupliquer site</span></button>
                        <button onClick={this.removeSites}><IconDelete/><span>Supprimer site</span></button>
                    </>
                }
                {!disabledPdf ?
                    <PDFButton dataDac={this.props.dacReducer}/>
                    :
                    <button onClick={this.saveQuote}><FontAwesomeIcon icon={faSave}/> <span>Sauvegarder</span></button>
                }

                {/*{this.state.crashTest.map( r => (*/}
                {/*    <p>{r}</p>*/}
                {/*))}*/}
                {/*<button onClick={this.crash}>CRASH</button>*/}

            </div>
        )
    }
}

export default connector(ActionsDac);
