import React, {Component} from 'react';

class IconStats extends Component {
    render() {
        return (
            <svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                 viewBox="0 0 303.4 323.9" space="preserve">
<path d="M68.4,323.9H21.4C9.6,323.9,0,314.3,0,302.4V136.2c0-11.8,9.6-21.4,21.4-21.4h46.9c11.8,0,21.4,9.6,21.4,21.4v166.3
	C89.8,314.3,80.2,323.9,68.4,323.9z"/>
                <path d="M175.2,323.9h-46.9c-11.8,0-21.4-9.6-21.4-21.4v-281c0-11.8,9.6-21.4,21.4-21.4h46.9c11.8,0,21.4,9.6,21.4,21.4v281
	C196.6,314.3,187,323.9,175.2,323.9z"/>
                <path d="M282,323.9h-46.9c-11.8,0-21.4-9.6-21.4-21.4V193.5c0-11.8,9.6-21.4,21.4-21.4H282c11.8,0,21.4,9.6,21.4,21.4v108.9
	C303.4,314.3,293.8,323.9,282,323.9z"/>
</svg>

        );
    }
}

export default IconStats;