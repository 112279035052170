import React, {Component} from 'react';

class IconCatalogue extends Component {
    render() {
        return (
            <svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                 viewBox="0 0 331.6 323.9" space="preserve">
<path d="M260.5,1.3c17.4-2.5,32.1-0.8,40.3,0.7c1.4,0.3,2.5,1.5,2.5,3l0,257.9c0,2-1.7,3.5-3.6,3.3c-48.9-4.4-83.1,12.4-103.7,28.1
	c-7.1,5.4-13,10.9-17.9,16c-2.1,2.2-5.8,0.7-5.8-2.3c0-43.3,0-232.1,0-247.9c0-0.7,0.2-1.4,0.6-1.9C196.2,25.6,225.6,6.5,260.5,1.3z
	 M28.3,5c0-1.5,1-2.7,2.5-3c8.1-1.5,22.6-3.2,39.8-0.8c35.1,5.1,64.7,24.2,88.2,56.9c0.4,0.6,0.6,1.3,0.6,2l0,247.9
	c0,3-3.7,4.5-5.8,2.3c-4.9-5.2-10.9-10.7-17.9-16.1c-18.3-13.9-47.5-28.7-88.2-28.7c-5.4,0-11.1,0.2-16.9,0.9
	c-1.2,0.1-2.2-0.8-2.2-1.9L28.3,5z M22.8,282.5c29.4-4.3,56.1-1.3,79.8,9.2c3.6,1.6,2,6.9-1.9,6.4c-20.8-3.1-39.7-0.3-54.9,4.1
	C29,307,15,314.4,5.1,320.5c-2.2,1.4-5.1-0.2-5.1-2.8L0,68.9c0-1,0.5-2,1.3-2.6c2.2-1.7,5.4-4,9.1-6.4c2.2-1.4,5.2,0.2,5.2,2.8
	l0,217.2c0,2.1,2,3.7,4,3.3l3.1-0.6C22.7,282.5,22.7,282.5,22.8,282.5z M316.2,279.7V62.6c0-2.7,3-4.3,5.2-2.8
	c3.7,2.4,6.9,4.6,9.1,6.4c0.8,0.6,1.3,1.6,1.3,2.6c0,19.3,0,211.7,0,248.9c0,2.6-2.9,4.2-5.1,2.8c-9.8-6.1-23.9-13.5-40.8-18.5
	c-15-4.4-33.9-7.2-54.6-4.1c-3.9,0.6-5.5-4.8-1.8-6.4c20-8.7,46.4-14.1,79.6-9.1l3.2,0.6C314.3,283.4,316.2,281.8,316.2,279.7z"/>
</svg>

        );
    }
}

export default IconCatalogue;