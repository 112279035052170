class SocietyFunctions {

    static generateIdOrder(id_pipedrive, dateChange) {
        let date;
        // if(dateChange) {
        //     date = new Date(dateChange);
        // } else {
            date = new Date();
        // }
        let objDate = {
            day: date.getDate(),
            month: date.getMonth() + 1,
            year: date.getFullYear(),
            hour: date.getHours(),
            minute: date.getMinutes(),
            second: date.getSeconds()
        };
        return objDate.year + '' + objDate.month + '' + objDate.day + '' + id_pipedrive + '' + objDate.hour + '' + objDate.minute;
    }

    static currentDate() {
        const date = new Date();
        const day = date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`;
        const month = date.getMonth() < 10 ? `0${date.getMonth() + 1}` : `${date.getMonth() + 1}`;
        return `${day}/${month}/${date.getFullYear()}`;
    }
}

export default SocietyFunctions;