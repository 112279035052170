import * as React from 'react';
import './Cursor.scss';

class Cursor extends React.Component<any, any> {
    render() {
        return(
            <svg onMouseUp={this.props.changeSizeNavbar} id="cursor" className={"open"} data-name="Calque 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 494 1564">
                <path id={"cls-1"} className={this.props.left ? "transition-show-cls-1" : "transition-hide-cls-1"} />
                <path  id={"cls-2"} className={this.props.left ? "transition-hide-cls-2" : "transition-show-cls-2"} />
                <circle cx="100" cy="500" r="350" id="color-left"/>
                <path id={"cls-left"} className={this.props.left ? "chevron-left" : "chevron-right"} />
            </svg>
        )
    }
}

export default Cursor;