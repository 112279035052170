import * as React from "react";
import './Society.scss';
import {connect, ConnectedProps} from "react-redux";
import {DatePicker} from "antd";
import SocietyFunctions from "./Functions";
import moment, {Moment} from "moment";
import SelectSearch from "../../Input/SelectSearch/SelectSearch";
import TextField from "../../Input/TextField/TextField";
import {DacCompState} from "../../../views/Dac/Dac";
import {DacState} from "../../../Store/Reducers/Dac/types";
import {editSociety} from "../../../Store/Reducers/Dac/actions";
import AutoCompleteAddress from "../../Input/AutoCompleteAddress/AutoCompleteAddress";
import Socket from "../../../utils/Socket/Socket";
import User from "../../../utils/User/User";

interface RootState {
    dacReducer: DacState
}

const mapState = (state: RootState) => ({
    id_pipedrive_reducer: state.dacReducer.society.id_pipedrive,
    society_reducer: state.dacReducer.society.society,
    ssociety_reducer: state.dacReducer.society,
    address_reducer: state.dacReducer.society.address,
    owner_reducer: state.dacReducer.society.owner,
    order_date_reducer: state.dacReducer.society.order_date,
    title_reducer: state.dacReducer.society.title,
    signatory_reducer: state.dacReducer.society.signatory,
    contact_name_reducer: state.dacReducer.society.contact_name,
    contact_email_reducer: state.dacReducer.society.contact_email,
    id_order_reducer: state.dacReducer.society.id_order,
    error_reducer: state.dacReducer.society.error,
});

const mapDispatch = {
    editSociety: (name: string, value: string | number) => editSociety(name, value),
};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
    dacState: DacCompState
}

interface State {
    owners: Array<string>
}

class Society extends React.Component<Props, State> {

    // Variable pour la verification du montage du component
    // (empeche les maj si le component n'est pas monte)
    _isMounted = false;
    owners: Array<string> = ['Benoît Huard', 'Sebastien Coatannoan', 'Yoann Guez', 'Jerome Suquet', 'Jean-Philippe Mi-Poudou'];


    constructor(props: any) {
        super(props);
        this.state = {
            owners: []
        }
    }
    componentDidMount() {
        this._isMounted = true
        this.props.editSociety('order_date', SocietyFunctions.currentDate());
        this.getOwners();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getOwners = async () => {
        const user = await User.userInfo();
        const entityUser = await Socket.readLean('EntityUser', {}, {});
        const users = await Socket.readLean('User', {parent_id: 'bf7a4c86-6090-475f-87ca-1184b793878f'}, {});
        this.setState(() => ({owners: users.data.map((user: { firstname: string, lastname: string }) => user.firstname + ' ' + user.lastname)}));
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState:Readonly<{}>, snapshot ?: any) {
        if (prevProps.id_pipedrive_reducer !== this.props.id_pipedrive_reducer || prevProps.order_date_reducer !== this.props.order_date_reducer) {
            this.props.editSociety('id_order', SocietyFunctions.generateIdOrder(this.props.id_pipedrive_reducer, this.props.order_date_reducer));
        }
        if(prevProps.order_date_reducer !== this.props.order_date_reducer && !this.props.order_date_reducer) {
            this.props.editSociety('order_date', SocietyFunctions.currentDate());
        }
    }

// change les valeurs pour les selects de la societe dans societyReducer
    handleSelect = (date: Moment | null, dateString: string) => {
        this.props.editSociety('order_date', dateString);
    };

    render() {
        const {
            error_reducer,
            id_pipedrive_reducer,
            society_reducer,
            address_reducer,
            owner_reducer,
            order_date_reducer,
            title_reducer,
            signatory_reducer,
            contact_name_reducer,
            contact_email_reducer,
            id_order_reducer,
            dacState,
            editSociety
        } = this.props;
        const {contacts, contact_emails} = dacState;
        // const contactsEmailsList = contact_emails.length > 0 ? contact_emails.filter(data => data.value.toLocaleLowerCase().includes(contact_email_reducer.toLocaleLowerCase())) : [];
        return (
            <div data-testid={'Society'} id={'Society'}>
                <h2>Référence société</h2>
                <div className={'dac__form'}>
                    <TextField type={'text'}
                               ariaLabel={"id_pipedrive"}
                               name={'id_pipedrive'}
                               placeholder={'Nouveau ID pipedrive'}
                               ctnName={"input__id"}
                               className={error_reducer && error_reducer.id_pipedrive}
                               mandatory={true}
                               inputType={'input'}
                               value={id_pipedrive_reducer}
                               actionReducer={editSociety}
                               disabled={false}
                    />
                    <TextField type={'text'}
                               ariaLabel={'society'}
                               name={'society'}
                               placeholder={'Raison Sociale'}
                               value={society_reducer}
                               actionReducer={editSociety}
                               inputType={'input'}
                               disabled={true}
                               mandatory={false}
                    />
                    <AutoCompleteAddress type={'text'}
                                         ariaLabel={'society_address'}
                                         name={'address'}
                                         placeholder={'Adresse'}
                                         value={address_reducer}
                                         className={error_reducer && error_reducer.address}
                                         actionReducer={editSociety} disabled={false}
                                         inputType={'input'}
                                         mandatory={true}
                                         defaultValue={address_reducer}
                    />
                    <SelectSearch value={owner_reducer}
                                  name={'owner'}
                                  placeholder={'Propriétaire'}
                                  items={this.state.owners} mandatory={true}
                                  className={error_reducer && error_reducer.owner} required={true}
                                  actionReducer={editSociety}/>
                    <div className="ctn__field">
                        <label htmlFor="">Date</label>
                        <div className={'ctn-picker-date'}>
                            <DatePicker aria-label={'order_date'} name={'orderDate'}
                                        onChange={this.handleSelect}
                                        value={moment(order_date_reducer, 'DD/MM/YYYY')}
                                        format={'DD/MM/YYYY'} />
                        </div>
                    </div>
                    <TextField type={'text'} ariaLabel={'title'} name={'title'}
                               placeholder={'Titre de l\'offre'}
                               value={title_reducer} actionReducer={editSociety} disabled={true}
                               mandatory={false} inputType={'input'}
                    />
                    <SelectSearch value={signatory_reducer} name={'signatory'}
                                  placeholder={'Nom du signataire'} mandatory={false}
                                  actionReducer={editSociety} items={contacts} valuesKey={'name'}
                                  valuesDisplay={'name'} unblocked={true}/>
                    <SelectSearch value={contact_name_reducer} mandatory={false}
                                  name={'contact_name'} placeholder={'Nom du contact'}
                                  actionReducer={editSociety} items={contacts} valuesKey={'name'}
                                  valuesDisplay={'name'} unblocked={true}/>
                    <SelectSearch value={contact_email_reducer} mandatory={false}
                                  name={'contact_email'} placeholder={'Mail du contact'}
                                  actionReducer={editSociety} items={contact_emails} valuesKey={'value'}
                                  valuesDisplay={'value'} unblocked={true}/>
                    <TextField type={'text'} name={'id_order'} ariaLabel={'id_order'}
                               mandatory={false}
                               placeholder={'Numéro de bon de commande'} actionReducer={editSociety}
                               value={id_order_reducer} inputType={'input'}
                               disabled={true}
                    />
                </div>
            </div>
        )
    }
}

export default connector(Society);
