import * as React from 'react';
import Pipedrive from '../../utils/Pipedrive/Pipedrive';
import {connect, ConnectedProps} from "react-redux";
import {CollectionsState} from '../../Store/Reducers/Collections/types';
import {DacState} from '../../Store/Reducers/Dac/types';

const categories = ["SPECIAL", "SDWAN", "ACCES INTERNET", "UCAAS", "RFIBER", "AUTRES PRESTATIONS", "MOBILITE", "FIREWALL"];
const subCategories = ['abo_ht', 'fas_ht', 'total'];

interface RootState {
    collectionsReducer: CollectionsState
}

const mapState = (state: RootState) => ({
    ordersReducer: state.collectionsReducer.Order,
    categoryReducer: state.collectionsReducer.Category
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {

}

interface State {
    orgs: Array<any>,
    search: string
}


class Clients extends React.Component<Props, State> {


    constructor(props: any) {
        super(props);
        this.state = {
            orgs: [],
            search: ""
        }
    }

    componentDidMount() {
        this.mergeDealsOrg()
            .then(res => {
                this.setState(state => ({...state, orgs: res}));
            })
    }


    mergeDealsOrg = async () => {
        let deals: any = await this.getDeals();
        let orgs: any = await this.getOrgs();
        for (let i = 0; i < orgs.length; i++) {
            let org = orgs[i];
            let orgDeals = deals.filter((d: any) => (d && d.org_id && d.org_id.value && d.org_id.value === org.id))
            for (let y = 0; y < orgDeals.length; y++) {
                let deal = orgDeals[y];
                deal.orders = this.props.ordersReducer.filter((ord: DacState) => {
                    if (ord.society.id_pipedrive === deal.id) {
                        ord = this.sortByCategory(ord);
                        return ord;
                    }
                })
                deal = await this.calcPerDeal(deal);
            }
            org.deals = orgDeals;
            org = await this.calcPerOrg(org);
            org = await this.calcTotals(org);
        }
        return orgs;
    }

    sortByCategory(order: DacState) {
        categories.forEach(category => {
            //@ts-ignore
            order[`${category}`] = order.sites.flatMap(x => x.services).filter(s => s.family === category);
            //@ts-ignore
            let abo_ht = order[`${category}`].length > 0 ? order[`${category}`].flatMap(x => x.abo_ht * x.quantity).reduce((a, c) => a + c) : 0;
            //@ts-ignore
            let fas_ht = order[`${category}`].length > 0 ? order[`${category}`].flatMap(x => x.fas_ht * x.quantity).reduce((a, c) => a + c) : 0;
            let total = abo_ht + fas_ht;
            //@ts-ignore
            order[`${category}`] = {label: category, total, abo_ht, fas_ht};
        })
        return order;
    }

    async calcTotals(org: any) {
        org.totals = {
            label: "",
            abo_ht: 0,
            fas_ht: 0,
            total: 0
        }
        categories.forEach(cat => {
            org.totals.label = "totals";
            org.totals.abo_ht = org.totals.abo_ht + org[`${cat}`].abo_ht;
            org.totals.fas_ht = org.totals.fas_ht + org[`${cat}`].fas_ht;
            org.totals.total = org.totals.total + org[`${cat}`].total;
        })
    }

    async calcPerOrg(org: any) {
        categories.forEach(category => {
            org[`${category}`] = {
                label: category,
                abo_ht: 0,
                fas_ht: 0,
                total: 0
            }
            subCategories.forEach(n => {
                //@ts-ignore
                org[`${category}`][`${n}`] = org.deals.length > 0 ? org.deals.flatMap(o => o[`${category}`][`${n}`]).reduce((a, c) => a + c) : 0;
            })
        })
        return org;
    }

    async calcPerDeal(deal: any) {
        categories.forEach(category => {
            deal[`${category}`] = {
                label: category,
                abo_ht: 0,
                fas_ht: 0,
                total: 0
            }
            subCategories.forEach(n => {
                //@ts-ignore
                deal[`${category}`][`${n}`] = deal.orders.length > 0 ? deal.orders.flatMap(o => o[`${category}`][`${n}`]).reduce((a, c) => a + c) : 0;
            })
        })
        return deal;
    }

    async getDeals() {
        let deals: any = []
        let i = 0;
        let res;
        while (res = await Pipedrive.getAllDeals(i * 100)) {
            deals = [...deals, ...res];
            i++;
        }
        return deals;
    }

    async getOrgs() {
        let orgs: any = []
        let i = 0;
        let res;
        while (res = await Pipedrive.getOrganisation(i * 100)) {
            orgs = [...orgs, ...res];
            i++;
        }
        return orgs;
    }

    handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        //@ts-ignore
        this.setState({[name]: value});
    }

    render() {

        return (
            <div>
                <input type={"text"} placeholder={"Rechercher..."} name={"search"} onChange={this.handleChange}/>
                <div className={"w-100"}>
                </div>
            </div>
        )
    }
}


export default connector(Clients);
