import Socket from "../Socket/Socket";

class User {

    static storage() {
        return localStorage;
    }

    static token() {
        return this.storage().getItem('userToken');
    }

    static removeToken() {
        this.storage().removeItem('userToken');
    }

    static setToken(token) {
        this.storage().setItem('userToken', token)
    }

    static checkValidateToken(token) {
        return new Promise((resolve, reject) => {
        Socket.checkValidateToken(token)
            .then(res => {
                if (res === 'token expire') {
                    this.removeToken();
                }
                resolve(res)
            })
        })
    }

    static forgotPassword(email, callback) {
        return new Promise((resolve, reject) => {
            Socket.forgotPassword(email)
                .then(res => {
                    callback(res);
                });
        })
    }

    static resetPassword(token, password) {
        return new Promise((resolve, reject) => {
            Socket.resetPassword(token, password)
                .then(res => {
                    if (res === 'success') {
                        resolve(res)
                    } else {
                        reject(res)
                    }
                })
        })
    }

    static checkIsAdmin() {
        return new Promise(resolve => {
            Socket.isAdmin(this.token())
                .then(res => {
                    resolve(res)
                })
        })
    }


    static userInfo() {
        return new Promise((resolve, reject) => {
            Socket.getUserInfo(this.token())
                .then(res => {

                    resolve(res);
                })
                .catch(err => {
                    reject(err)
                })
        })
    }

    static authError(user) {
        let error = false;
        const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        for (let [key, value] of Object.entries(user)) {
            if (value === '') {
                error = true;
            }
            if (key === 'email') {
                if (!regex.test(value)) {
                    error = true;
                }
            }
        }
        return error;
    }

    static login(user) {
        return new Promise((resolve, reject) => {
            if (this.authError(user)) {
                reject('error field')
            } else {
                Socket.loginUser(user)
                    .then(res => {
                        resolve(res)
                    })
            }
        })
    }

    static register(user) {
        return new Promise((resolve, reject) => {
            if (this.authError(user)) {
                reject('error field')
            } else {
                Socket.createUser(user)
                    .then(res => {
                        resolve(res)
                    })
            }
        })
    }
}

export default User;
