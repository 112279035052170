import {CollectionsActionTypes, CollectionsState, UPDATE_COLLECTIONS, INIT_COLLECTIONS} from "./types";
import {collectionsState} from "./state";

const initialState: CollectionsState = collectionsState;

export default function collectionsReducer(state = initialState, action: CollectionsActionTypes) : CollectionsState {
    switch (action.type) {
        case UPDATE_COLLECTIONS:
            return {
                ...state,
                [action.collection]: action.value
            }
        case INIT_COLLECTIONS:
            return action.value
        default:
            return state;
    }
}