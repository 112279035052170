import * as  React from 'react';
import {ServicesState} from "../../../Store/Reducers/Dac/types";
import ReactTable from "react-table-6";
//@ts-ignore
import matchSorter from 'match-sorter';
import ActionsCatalogue from "./ActionsCatalogue/ActionsCatalogue";

interface Props {
    data: Array<ServicesState>
}

interface State {
    selectedItems: Array<string>,
    checkAll: boolean
}

class HeaderTableCatalogue extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            selectedItems: [],
            checkAll: false
        }
    }

    onCheckAllChange = () => {
        if (!this.state.checkAll) {
            const newSelect = Array.from(this.props.data, d => d._id);
            if (newSelect.length > 0) {
                // @ts-ignore
                this.setState({
                    checkAll: true, selectedItems: newSelect
                });
            }
        } else {
            this.setState(prevState => ({...prevState, checkAll: false, selectedItems: []}));
        }
    }

    onSelect = (e: React.FormEvent<HTMLInputElement>, row: any) => {
        const {selectedItems} = this.state;
        if (selectedItems.find(i => i === row.original._id)) {
            this.setState(prevState => ({
                ...prevState,
                selectedItems: selectedItems.filter(i => i !== row.original._id)
            }))
        } else {
            this.setState(prevState => ({...prevState, selectedItems: [...selectedItems, row.original._id]}))
        }

    }

    onDeselectAll = () => {
        this.setState(state => ({...state, selectedItems: []}))
    }
    render() {
        const {selectedItems} = this.state;
        const columns: any = [
            {
                Header: <input
                    onChange={this.onCheckAllChange}
                    type="checkbox"
                    className="check-all"
                />,
                accessor: 'volume',
                Cell: (row: any) =>
                    <input
                        type="checkbox"
                        className="choice-table"
                        checked={!!this.state.selectedItems.find(i => i === row.original._id)}
                        onChange={(e) => this.onSelect(e, row)}
                    />
                ,
                sortable: false,
                maxWidth: 70
            },
            {
                Header: "Famille de service",
                accessor: "family",
                filterable: true,
                filterAll: true,
                filterMethod: (filter: any, rows: any) =>  matchSorter(rows, filter.value, {keys: ['family']}),
                Filter: ({filter, onChange}: any) =>
                    <input placeholder="..." className='text-center inputfilters'
                           onChange={event => onChange(event.target.value)}/>,
            },
            {
                Header: "Désignation",
                accessor: "designation",
                filterable: true,
                filterAll: true,
                filterMethod: (filter: any, rows: any) =>  matchSorter(rows, filter.value, {keys: ['designation']}),
                Filter: ({filter, onChange}: any) =>
                    <input placeholder="..." className='text-center inputfilters'
                           onChange={event => onChange(event.target.value)}/>,
            },
            {
                Header: "Solution",
                accessor: "solution",
                filterable: true,
                filterAll: true,
                filterMethod: (filter: any, rows: any) =>  matchSorter(rows, filter.value, {keys: ['solution']}),
                Filter: ({filter, onChange}: any) =>
                    <input placeholder="..." className='text-center inputfilters'
                           onChange={event => onChange(event.target.value)}/>,
            },
            {
                Header: "Description",
                accessor: "describe_solution",
                filterable: true,
                filterAll: true,
                filterMethod: (filter:any, rows:any) =>  matchSorter(rows, filter.value, {keys: ['describe_solution']}),
                Filter: ({filter, onChange}: any) =>
                    <input placeholder="..." className='text-center inputfilters'
                           onChange={event => onChange(event.target.value)}/>,
            },
            {
                Header: "Durée",
                accessor: "duration",
                filterable: true,
                filterAll: true,
                filterMethod: (filter : any, rows: any) =>  matchSorter(rows, filter.value, {keys: ['duration']}),

                Filter: ({filter, onChange}: any) =>
                    <input placeholder="..." className='text-center inputfilters'
                           onChange={event => onChange(event.target.value)}/>,
            },
            {
                Header: "FRN",
                accessor: "frn",
                filterable: true,
                filterAll: true,
                filterMethod: (filter: any, rows: any) =>  matchSorter(rows, filter.value, {keys: ['frn']}),
                Filter: ({filter, onChange}: any) =>
                    <input placeholder="..." className='text-center inputfilters'
                           onChange={event => onChange(event.target.value)}/>,
            },
            {
                Header: "ABO Mois HT",
                accessor: "abo_ht",
                filterable: true,
                filterAll: true,
                filterMethod: (filter: any, rows: any) =>  matchSorter(rows, filter.value, {keys: ['abo_ht']}),
                Filter: ({filter, onChange}: any) =>
                    <input placeholder="..." className='text-center inputfilters'
                           onChange={event => onChange(event.target.value)}/>,
                Cell: (row: any) => <span>{`${row.original.abo_ht} €`}</span>
            },
            {
                Header: "PA ABO (€)",
                accessor: "pa_abo",
                filterable: true,
                filterAll: true,
                filterMethod: (filter: any, rows: any) =>  matchSorter(rows, filter.value, {keys: ['pa_abo']}),
                Filter: ({filter, onChange}: any) =>
                    <input placeholder="..." className='text-center inputfilters'
                           onChange={event => onChange(event.target.value)}/>,
                Cell: (row: any) => <span>{`${row.original.pa_abo} €`}</span>
            },
            {
                Header: "FAS HT (€)", accessor: "fas_ht",
                filterable: true,
                filterAll: true,
                filterMethod: (filter: any, rows: any) =>  matchSorter(rows, filter.value, {keys: ['fas_ht']}),
                Filter: ({filter, onChange}: any) =>
                    <input placeholder="..." className='text-center inputfilters'
                           onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChange(event.target.value)}/>,
                Cell: (row: any) => <span>{`${row.original.fas_ht} €`}</span>
            },
            {
                Header: "PA FAS (€)",
                accessor: "pa_fas",
                filterable: true,
                filterAll: true,
                filterMethod: (filter: any, rows: any) =>  matchSorter(rows, filter.value, {keys: ['pa_fas']}),
                Filter: ({filter, onChange}: any) =>
                    <input placeholder="..." className='text-center inputfilters'
                           onChange={event => onChange(event.target.value)}/>,
                Cell: (row: any) => <span>{`${row.original.pa_fas} €`}</span>
            },
            {
                Header: "MGE ABO (€)",
                accessor: "mge_abo",
                filterable: true,
                filterAll: true,
                filterMethod: (filter: any, rows: any) =>  matchSorter(rows, filter.value, {keys: ['mge_abo']}),
                Filter: ({filter, onChange}: any) =>
                    <input placeholder="..." className='text-center inputfilters'
                           onChange={event => onChange(event.target.value)}/>,
                Cell: (row: any) => <span>{`${row.original.mge_abo} €`}</span>
            },
            {
                Header: "TX ABO (%)",
                accessor: "tx_abo",
                filterable: true,
                filterAll: true,
                filterMethod: (filter: any, rows: any) =>  matchSorter(rows, filter.value, {keys: ['tx_abo']}),
                Filter: ({filter, onChange}: any) =>
                    <input placeholder="..." className='text-center inputfilters'
                           onChange={event => onChange(event.target.value)}/>,
                Cell: (row: any) => <span>{`${row.original.tx_abo} %`}</span>
            },
            {
                Header: "MGE FAS (€)",
                accessor: "mge_fas",
                filterable: true,
                filterAll: true,
                filterMethod: (filter: any, rows: any) =>  matchSorter(rows, filter.value, {keys: ['mge_fas']}),
                Filter: ({filter, onChange}: any) =>
                    <input placeholder="..." className='text-center inputfilters'
                           onChange={event => onChange(event.target.value)}/>,
                Cell: (row: any) => <span>{`${row.original.mge_fas} €`}</span>
            },
            {
                Header: "TX FAS (%)",
                accessor: "tx_fas",
                filterable: true,
                filterAll: true,
                filterMethod: (filter: any, rows: any) =>  matchSorter(rows, filter.value, {keys: ['tx_fas']}),
                Filter: ({filter, onChange}: any) =>
                    <input placeholder="..." className='text-center inputfilters'
                           onChange={event => onChange(event.target.value)}/>,
                Cell: (row: any) => <span>{`${row.original.tx_fas} %`}</span>
            },
        ];
        return (
            <>
                <ReactTable showPaginationTop={true}
                            showPaginationBottom={false}
                            data={this.props.data}
                            columns={columns}
                            minRows={10}/>
                <ActionsCatalogue deselectAll={this.onDeselectAll} selectedServices={this.props.data.filter(d => selectedItems.includes(d._id))}
                                  selectedItems={selectedItems}/>
            </>
        )
    }
}

export default HeaderTableCatalogue;