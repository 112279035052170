import { v4 as uuidv4 } from 'uuid';

export const servicesState = {
    id: '',
    _id: '',
    id_service: '',
    id_categorie: '',
    family: '',
    designation: '',
    solution: '',
    describe_solution: '',
    duration: '',
    reference: '',
    frn: '',
    number: '',
    abo_ht: 0,
    pa_abo: 0,
    fas_ht: 0,
    pa_fas: 0,
    mge_abo: 0,
    tx_abo: 0,
    mge_fas: 0,
    tx_fas: 0,
    quantity: 0,
    p_router: 0,
    error: {
        id_categorie: '',
        solution: ''
    }
};

export const sitesState = {
    _id: uuidv4(),
    site_name: '',
    contact_name: '',
    contact_tel: '',
    contact_mail: '',
    delivery_address: '',
    services: [servicesState],
    siret: '',
    abo_ht: 0,
    pa_abo: 0,
    fas_ht: 0,
    pa_fas: 0,
    mge_abo: 0,
    tx_abo: 0,
    mge_fas: 0,
    tx_fas: 0,
    quantity: 0,
    error: {
        site_name: '',
        delivery_address: '',
        contact_mail: '',
        contact_tel: '',
        contact_name: ''
    },

};

export const societyState = {
    id_order: '',
    id_pipedrive: '',
    subscribe_duration: '',
    owner: '',
    order_date: '',
    address: '',
    signatory: '',
    contact_name: '',
    contact_email: '',
    society: '',
    title: '',
    discount_abo: 0,
    discount_fas: 0,
    mge_abo: 0,
    mge_fas: 0,
    tx_abo: 0,
    tx_fas: 0,
    org_id: 0,
    abo_ht: 0,
    fas_ht: 0,
    old_id_pipedrive: '',
    old_abo: '',
    error: {
        id_order: '',
        id_pipedrive: '',
        owner: '',
        order_date: '',
        address: '',
        signatory: '',
        billing_contact: '',
        contact_email: '',
        society: '',
        title: '',
    }
};

export const billingState = {
    contract: false,
    contact_name: '',
    contact_tel: '',
    contact_mail: '',
    address: '',
    error: {
        contact_name: '',
        contact_tel: '',
        contact_mail: '',
        address: '',
    }
};


export const dacState = {
    tag: 'open',
    society: societyState,
    billing: billingState,
    sites: [],
    clauses: [],
    countServices: 0
};
