import * as React from 'react';
import TextField from "../../Input/TextField/TextField";
import {DacState} from "../../../Store/Reducers/Dac/types";
import {editSociety} from "../../../Store/Reducers/Dac/actions";
import {connect, ConnectedProps} from "react-redux";
import './ContractInfo.scss';
import Socket from "../../../utils/Socket/Socket";

interface RootState {
    dacReducer: DacState
}

const mapState = (state: RootState) => ({
    societyReducer: state.dacReducer.society,
    dacReducer: state.dacReducer
});

const mapDispatch = {
    editSociety: (name: string, value: string | number) => editSociety(name, value),

};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {

}

interface State {
    oldPrice: number
}

class ContractInfo extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            oldPrice: 0
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
        if(prevProps.societyReducer.old_id_pipedrive !== this.props.societyReducer.old_id_pipedrive) {
            Socket.read('Order', {['society.id_pipedrive']: this.props.societyReducer.old_id_pipedrive})
            .then(res => {
                if(res.data && res.data.length > 0 ){
                    const abo = res.data[res.data.length - 1].society.abo_ht;
                    this.setState(() => ({oldPrice: abo}))
                }
            })
            
        }
    }

    changeOldId = () => {
        
    }
    render() {
        const {oldPrice} = this.state;
        const {societyReducer} = this.props;
        return(
            <div id={'ContractInfo'}>
                    <h2>Info Contrat</h2>
                <div className={'dac__form'}>
                    <TextField ariaLabel={"old_id_pipedrive"} type={'text'} name={'old_id_pipedrive'}
                               placeholder={'Ancien ID pipedrive'}
                               className={societyReducer.error && societyReducer.error.id_pipedrive}
                               inputType={'input'}
                               value={societyReducer.old_id_pipedrive} actionReducer={this.props.editSociety} disabled={false}
                    />
                    <TextField type={'text'} ariaLabel={'old_abo'} name={'abo_ht'} placeholder={'Ancien ABO'}
                               value={oldPrice} actionReducer={editSociety} inputType={'input'}
                               disabled={true}
                    />
                </div>
            </div>
        )
    }
}

export default connector(ContractInfo);