
export const EDIT_ACTION_DAC = "EDIT_ACTION_DAC";

export interface ActionsDacState {
    copySites: Array<string>,
    copyServices: Array<{idSite: string, idService: string}>
}

interface EditActionDac {
    type: typeof EDIT_ACTION_DAC,
    name: string,
    value:Array<{idSite: string, idService: string} | string>,
}


export type ActionsDacTypes =  EditActionDac;