import * as  React from 'react';
import './SelectSearch.scss';
import {Select, Tooltip} from "antd";
import IconMandatory from "../../../assets/mandatory.png";
//@ts-ignore
import matchSorter from 'match-sorter';
import {CloseCircleOutlined} from "@ant-design/icons";
import {nanoid} from "nanoid";

import Highlighter from "react-highlight-words";

const {Option} = Select;

interface Props {
    value: string | null,
    name: string,
    placeholder: string,
    actionReducer: Function,
    mandatory?: boolean,
    className?: string
    items: Array<any>,
    ctnName?: string,
    valuesKey?: string,
    valuesDisplay?: string,
    unblocked?: boolean,
    removeIf?: string,
    funcRemoveIf?: Function
    disableLabel?: boolean,
    required?: boolean
}


interface State {
    search: string
}

class SelectSearch extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            search: ''
        }
    }


    handleChange = (value: string) => {
        const {unblocked, name} = this.props;

        // this.props.actionReducer(name, value)
        if (unblocked) {
            if (value) {
                this.props.actionReducer(name, value);
            }
        } else {
            this.props.actionReducer(name, value);
        }
    }

    onSearch = (value: string) => {
        const {name} = this.props;

        // if(value !== '') {
        this.setState(() => ({search: value}))
        // }
        // this.props.actionReducer(name, value);
    }


    funcRemoveIf = () => {
        if (this.props.funcRemoveIf) {
            this.props.funcRemoveIf();
        }
    }

    render() {
        const {
            value,
            disableLabel,
            placeholder,
            mandatory,
            className,
            items,
            ctnName,
            valuesKey,
            valuesDisplay,
            removeIf,
            required
        } = this.props;
        return (
            <div className={`select_search ctn__field ${ctnName ? ctnName : ''}`}>
                <label className={disableLabel ? 'd-none' : ''} htmlFor="">{placeholder}</label>
                <div className={'ctn-select-search'}>
                    {mandatory &&
                        <Tooltip placement="topLeft" title={'* Champs obligatoire'}>
                            <img src={IconMandatory} className={'icon-mandatory'} alt={'mandatory-icon'}/>
                        </Tooltip>
                    }
                    <Select
                        data-required={required || mandatory}
                        data-value={value}
                        showSearch={true}
                        defaultValue={value ? value : undefined}
                        onSearch={this.onSearch}
                        value={value ? value : undefined}
                        className={className}
                        placeholder={this.props.placeholder}
                        onChange={this.handleChange}
                        filterOption={(input, option) => {
                            const words = input.split(' ');
                            let match = 0;
                            if(option) {
                                if ("title" in option) {
                                    words.forEach(word => {
                                        const paragraph = option.title;
                                        const regex = new RegExp(word, "i");
                                        const found = paragraph.match(regex);
                                        if(found && found.length) match++;
                                    })
                                }
                            }
                            return match === words.length;
                        }}
                    >
                        {items && items.length > 0 && items.map((item, index) => {
                            if (valuesKey && valuesDisplay) {
                                return <Option key={item._id ? item._id : nanoid()} title={item[valuesDisplay]}
                                               value={item[valuesKey]}>

                                    <Highlighter
                                        highlightClassName="YourHighlightClass"
                                        searchWords={this.state.search.split(' ')}
                                        autoEscape={true}
                                        textToHighlight={item[valuesDisplay]}
                                    />
                                    {/*{item[valuesDisplay]}*/}
                                </Option>
                            } else {
                                return <Option key={nanoid()} value={item}>
                                    {/*{item}*/}
                                    <Highlighter
                                        highlightClassName="YourHighlightClass"
                                        searchWords={this.state.search.split(' ')}
                                        autoEscape={true}
                                        textToHighlight={item}
                                    />
                                    {/*{item}*/}
                                </Option>
                            }
                        })}
                    </Select>
                    {removeIf &&
                        <button className={'btn-removeIf'} onClick={() => this.funcRemoveIf()}>
                            <CloseCircleOutlined/>
                        </button>
                    }
                </div>
            </div>
        )
    }
}

export default SelectSearch;