import React from 'react';
import './App.css';
import Layout from "./Components/Layout/Layout";
import Router from "./utils/Router";
import 'antd/dist/antd.css';
import Collections from "./utils/Collections/Collections";
import Socket from "./utils/Socket/Socket";
import User from "./utils/User/User";
import {DacState} from "./Store/Reducers/Dac/types";
import {CollectionsState} from "./Store/Reducers/Collections/types";
import {initCollections, udpateCollections} from "./Store/Reducers/Collections/actions";
import {connect, ConnectedProps} from "react-redux";
import {RouteComponentProps, withRouter} from 'react-router-dom';
import queryString from 'query-string';
import './globalCSS/vars.scss';

interface RootState {
    dacReducer: DacState,
    collectionsReducer: CollectionsState,
}

const mapState = (state: RootState) => ({
    societyReducer: state.dacReducer.society,
    billingReducer: state.dacReducer.billing,
    collectionsReducer: state.collectionsReducer
});

const mapDispatch = {
    updateCollections: (collection: string, value: any) => udpateCollections(collection, value),
    initCollections: (value: any) => initCollections(value)
};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends RouteComponentProps {
    updateCollections: typeof udpateCollections,
    initCollections: typeof initCollections,
}

type PropsType = Props & PropsFromRedux;

interface State {
    auth: boolean
}

class App extends React.Component<PropsType, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            auth: false,
        }
    }

    componentDidMount() {
        if(process.env.NODE_ENV === 'production') {
            this.testToken();
        } else {
            Socket.loginUser({
                email: 'dylan.lobjois@sayse.fr',
                password: 'test123'
            })
                .then(res => {
                    console.log('token', res.token)
                    localStorage.setItem('userToken', res.token);
                    this.testToken();
                })
                .catch(err => console.log(err))
            // this.props.history.push("/Dashboard");
            // Collections.globalRead(this.props.initCollections, this.props.collectionsReducer);
        }
        // Collections.globalRead(this.props.initCollections, this.props.collectionsReducer);
        this.watchCrudLive();
    }

    watchCrudLive() {
        Socket.live((res: any) => {
            this.props.updateCollections(res.collection, res.data);
        })
    }

    testToken = () => {
        const token = queryString.parse(this.props.location.search).token;
        
        if (!User.token()) {
            if (token) {
                this.redirectVerifToken(token);
            } else {
                window.location.replace(process.env.REACT_APP_WANUP + "");
            }
        } else {
            this.redirectVerifToken(User.token(), true);
        }
    }

    redirectVerifToken = (token?: any, exist?: boolean) => {
        User.checkValidateToken(token)
            .then(resToken => {
                if (resToken === "token valid") {
                    if (!exist) {

                        User.setToken(token);
                    }
                    this.props.history.push("/Dashboard");

                    Collections.globalRead(this.props.initCollections, this.props.collectionsReducer);
                } else {
                    User.removeToken();
                    window.location.replace(process.env.REACT_APP_WANUP + "");
                }
            }).catch(err => {
                console.error(err)
                    User.removeToken();
                    window.location.replace(process.env.REACT_APP_WANUP + "");
        })
    }

    render() {
            return (
                <Layout>
                    {/*<Dialog />*/}
                    <Router/>
                </Layout>
            );
    }
}

export default withRouter(connector(App));
