import React from "react";
import {Document, Font, Image, Page, StyleSheet, Text, View} from "@react-pdf/renderer";
import {DacState, ServicesState, SitesState} from "../../../Store/Reducers/Dac/types";
import Logo from '../../../assets/Cover/Sayse.png';
import BgMain from '../../../assets/Cover/Cover.jpg';
import axios from "axios";

Font.register({family: 'PoppinsBold', src: "/font/Poppins-Bold.ttf"});
Font.register({family: 'PoppinsMedium', src: "/font/Poppins-Medium.ttf"});
Font.register({family: 'PoppinsRegular', src: "/font/Poppins-Regular.ttf"});
Font.register({family: 'PoppinsLight', src: "/font/Poppins-Light.ttf"});
Font.register({family: 'Poppins', src: "/font/Poppins-Black.ttf"});
Font.register({family: 'PoppinsItalic', src: "/font/Poppins-Italic.ttf"});

interface Props {
    order: DacState | null,
    Categories: Array<{ _id: string, name: string }>,
}

interface PagePdfState {
    update: boolean,
    durations: Array<{ label: string, id: string }>
}

class PagePDF extends React.Component<Props, PagePdfState> {

    constructor(props: any) {
        super(props);
        this.state = {
            update: false,
            durations: []
        }
    }

    componentDidMount() {
        this.getDurations();
    }

    getDurations = async () => {
        const res = await axios.get('https://api.pipedrive.com/v1/dealFields/12465?api_token=d168467b213a71acdc4ba5cb5b2cfe96529ac061')
        const durations = res.data.data.options;
        this.setState(() => ({
            durations: durations.map((d: { id: string, label: string }) => ({
                id: `${d.id}`,
                label: d.label
            }))
        }))
    }

    categoryName = (id: string) => {
        const {Categories} = this.props;
        return Categories.filter(category => category._id === id).length > 0 ? Categories.filter(category => category._id === id)[0].name : "";
    };


    categoryColor = (category: string) => {
        // if(category) {
        let color;
        switch (category.toUpperCase()) {
            case 'UUCAAS':
                color = '#e74c3c';
                break;
            case 'INTERNET':
                color = '#27ae60';
                break;
            case 'INTEGRATION':
                color = '#2980b9';
                break;
            case 'WIFI & SD-LAN':
                color = '#8e44ad';
                break;
            case 'SD-WAN':
                color = '#e67e22';
                break;
            case 'SPECIAL':
                color = '#f1c40f';
                break;
            default:
                color = '#A17917';
                break;
        }
        return color;
        // }
    };

    render() {
        const {order} = this.props;
        if (order) {
            const {society, sites, billing, clauses} = order;
            return (

                <Document>
                    <Page>
                        <Image src={BgMain} style={{height: '100%', width: '100%', position: 'absolute'}} fixed/>
                        <View style={{
                            color: 'white',
                            textAlign: 'center',
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center'
                        }}>
                            <View style={{marginBottom: 400}}>
                                <Text style={{fontSize: 25, fontFamily: 'PoppinsBold'}}>CONTRAT DE SERVICES</Text>
                                <Text style={{fontSize: 14, fontFamily: 'PoppinsMedium'}}>ID:
                                    #{society.id_pipedrive}</Text>
                            </View>
                        </View>
                    </Page>
                    <Page style={{paddingBottom: 45, paddingTop: 20}}>
                        <View style={{paddingLeft: '8%', paddingTop: '8%', paddingRight: '8%'}}>
                            <View style={styles.header}>
                                <View
                                    style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                                    <Image style={styles.logo} source={Logo}/>
                                    {/*<Text style={styles.txtInfoSayse}>*/}

                                    {/*</Text>*/}
                                    {/*<View>*/}
                                    <Text style={{
                                        marginTop: 'auto',
                                        marginRight: 'auto',
                                        fontSize: 9,
                                        // marginTop: 20,
                                        fontFamily: 'PoppinsBold',
                                        color: "#1162d8"
                                    }}>Bon de commande n° : {society.id_order}</Text>
                                    {/*</View>*/}
                                </View>
                                <View style={styles.txtInfoClient}>
                                    <View style={{marginBottom: 8}}>
                                        <Text style={styles.fontBold}>Date de commande
                                            : {society.order_date}</Text>
                                        <Text style={{marginLeft: 'auto', fontWeight: 'bold'}}>Durée de
                                            souscription: {this.state.durations.find(d => d.id === society.subscribe_duration)?.label}</Text>
                                    </View>
                                    <View style={{marginBottom: 8}}>
                                        <Text style={{
                                            marginLeft: 'auto',
                                            fontWeight: 'bold',
                                            backgroundColor: '#0058d4',
                                            color: 'white',
                                            paddingLeft: 5,
                                            paddingRight: 5,
                                            paddingTop: 1,
                                            paddingBottom: 1,
                                        }}>ID: #{society.id_pipedrive}</Text>
                                        {society.old_id_pipedrive !== "" &&
                                            <Text style={{marginLeft: 'auto', fontWeight: 'bold'}}>#Ancien
                                                ID: {society.old_id_pipedrive}</Text>
                                        }
                                    </View>
                                    <View>
                                        <Text style={{marginLeft: 'auto'}}>{society.society}</Text>
                                        <Text style={{
                                            marginLeft: 'auto',
                                            width: 160,
                                            textAlign: 'right'
                                        }}>{society.address ? society.address : ''}</Text>
                                        <Text
                                            style={{marginLeft: 'auto'}}> {billing.contact_name ? billing.contact_name : ''}</Text>
                                        <Text
                                            style={{marginLeft: 'auto'}}> {billing.contact_tel ? billing.contact_tel : ''}</Text>
                                    </View>
                                </View>
                            </View>
                        </View>
                        <View style={styles.body}>
                            {sites.map((site: SitesState, index: number) => (
                                <View style={styles.ctnSite} key={index}>
                                    <View style={styles.footerSite} wrap={false}>
                                        <View style={{width: '50%'}}>
                                            <View style={styles.footerCaseName}>
                                                <Text style={{
                                                    fontWeight: 'bold',
                                                    marginTop: 'auto',
                                                    marginBottom: 'auto',
                                                }}>Site concernée </Text>
                                                <Text style={{
                                                    backgroundColor: '#0058d4', paddingLeft: 5,
                                                    paddingRight: 5,
                                                    paddingTop: 1,
                                                    paddingBottom: 1, color: 'white'
                                                }}>{site.site_name}</Text>
                                            </View>
                                            {/*<View style={styles.footerCase}>*/}
                                            <Text style={{fontFamily: "PoppinsMedium"}}>Adresse de livraison </Text>
                                            <Text>{site.delivery_address}</Text>
                                            {/*</View>*/}
                                        </View>
                                        <View style={{width: '50%', display: 'flex', textAlign: 'right'}}>
                                            {/*<View style={styles.footerCase}>*/}
                                            {/*    <Text style={{fontFamily: "PoppinsMedium"}}>Siret :</Text>*/}
                                            {/*    <Text>{site.siret}</Text>*/}
                                            {/*</View>*/}
                                            {/*<View style={styles.footerCase}>*/}
                                            <Text style={{fontFamily: "PoppinsMedium", paddingTop: 5}}>Nom du contact
                                                sur site</Text>
                                            <Text>{site.contact_name}</Text>
                                            {/*</View>*/}
                                            {/*<View style={styles.footerCase}>*/}
                                            {/*<Text style={{fontFamily: "PoppinsMedium"}}>Tel du contact : </Text>*/}
                                            <Text>{site.contact_tel}</Text>
                                            {/*</View>*/}
                                            {/*<View style={styles.footerCase}>*/}
                                            {/*<Text style={{fontFamily: "PoppinsMedium"}}>Mail du contact : </Text>*/}
                                            <Text>{site.contact_mail}</Text>
                                            {/*</View>*/}
                                            {/*<View style={[styles.footerCaseName, {marginLeft: 'auto', backgroundColor: '#d7d7d7',*/}
                                            {/*    marginBottom: 10}]}>*/}
                                            {/*    <Text style={{*/}
                                            {/*        paddingLeft: 5,*/}
                                            {/*        paddingTop: 3,*/}
                                            {/*        paddingBottom: 3,*/}
                                            {/*        paddingRight: 5,*/}
                                            {/*        borderTopLeftRadius: 2,*/}
                                            {/*        borderBottomLeftRadius: 2,*/}
                                            {/*        fontWeight: 'bold',*/}
                                            {/*        color: 'white',*/}
                                            {/*        borderRight: 0,*/}
                                            {/*    }}>*/}
                                            {/*        Total Abo/mois: {site.abo_ht} €*/}
                                            {/*    </Text>*/}
                                            {/*</View>*/}
                                            {/*<View style={[styles.footerCaseName, {marginLeft: 'auto', backgroundColor: 'black'}]}>*/}
                                            {/*    <Text style={{*/}
                                            {/*        paddingLeft: 5,*/}
                                            {/*        paddingTop: 3,*/}
                                            {/*        paddingBottom: 3,*/}
                                            {/*        paddingRight: 5,*/}
                                            {/*        borderTopLeftRadius: 2,*/}
                                            {/*        borderBottomLeftRadius: 2,*/}
                                            {/*        fontWeight: 'bold',*/}
                                            {/*        color: 'white',*/}
                                            {/*        borderRight: 0*/}
                                            {/*    }}>Total FAS: {site.fas_ht} €</Text>*/}
                                            {/*</View>*/}
                                        </View>
                                    </View>
                                    <View style={styles.headerSite} wrap={false}>
                                        <Text style={{marginTop: 'auto', fontSize: 7, width: '5%'}}>Durée</Text>
                                        <Text
                                            style={{
                                                marginTop: 'auto',
                                                fontSize: 7,
                                                width: '11%',
                                                textAlign: 'center'
                                            }}>Solution</Text>
                                        <Text style={{marginTop: 'auto', fontSize: 7, width: '30%'}}>Description</Text>

                                        <View
                                            style={{marginTop: 'auto', fontSize: 7, width: '10%', textAlign: 'center'}}>
                                            <Text
                                                style={{color: "#1162d8"}}>Abo/Mois</Text>
                                        </View>
                                        <View
                                            style={{color: "#1162d8", marginTop: 'auto', fontSize: 7, width: '10%', textAlign: 'center'}}>
                                            <Text>Fas Unitaire</Text>
                                        </View>

                                        <Text style={{
                                            marginTop: 'auto',
                                            fontSize: 7,
                                            width: '6%',
                                            textAlign: 'center'
                                        }}>Qté</Text>

                                        {/*<Text style={{fontSize: 7, width: '11%', textAlign: 'center'}}>Abo/Mois {"\n"}Total {"\n"}</Text>*/}
                                        {/*<Text style={{fontSize: 7, width: '9%', textAlign: 'center'}}>FAS{"\n"}Unitaire</Text>*/}
                                        <View
                                            style={{marginTop: 'auto', fontSize: 7, width: '10%', textAlign: 'center'}}><Text
                                            >Total
                                            Abo</Text>

                                        </View>
                                        <View
                                            style={{marginTop: 'auto', fontSize: 7, width: '10%', textAlign: 'center'}}><Text>Total
                                            Fas</Text>

                                        </View>
                                        {/*<Text style={{fontSize: 7, width: '11%', textAlign: 'center'}}>Total {"\n"}FAS</Text>*/}
                                    </View>
                                    {site.services.map((service: ServicesState, index: number) => {
                                        if (service) {
                                            return (
                                                <View key={index} style={styles.row} wrap={false}>
                                                    <Text style={{
                                                        width: '5%',
                                                        color: 'gray',
                                                    }}>{this.state.durations.find(d => d.id === service.duration)?.label}</Text>
                                                    <View style={{
                                                        width: '11%',
                                                        marginTop: 'auto',
                                                        marginBottom: 'auto',
                                                    }}>

                                                        <Text style={{
                                                            color: "white",
                                                            padding: 2,
                                                            marginBottom: 5,
                                                            textAlign: 'center',
                                                            backgroundColor: this.categoryColor(service.family)
                                                        }}>{service.family}</Text>
                                                        <Text style={{fontWeight: 'bold', textAlign: 'center'}}>
                                                            {this.categoryName(service.id_categorie)}
                                                        </Text>
                                                        {/*<Text style={{paddingTop: 5, paddingBottom: 5, textAlign: 'center', height: 'auto', borderBottomWidth: 1, borderColor: 'gray'}}>REF</Text>*/}
                                                        {/*<Text style={{paddingTop: 5, paddingBottom: 5, textAlign: 'center', height: 'auto'}}>{service.reference}</Text>*/}
                                                    </View>
                                                    <View style={{
                                                        width: '30%',
                                                        color: 'gainsboro',
                                                        marginTop: 'auto',
                                                        marginBottom: 'auto',
                                                    }}>
                                                        <Text style={{
                                                            width: '100%',
                                                            textAlign: 'left',
                                                            marginBottom: 10, color: 'gray'
                                                        }}>{service.designation}</Text>
                                                        <Text style={{
                                                            width: '100%',
                                                            textAlign: 'left',
                                                            marginLeft: 'auto', color: 'gray',
                                                            fontFamily: 'PoppinsItalic'
                                                        }}>{service.describe_solution}</Text>
                                                    </View>

                                                    {/*<Text style={{width: '7%', color: 'gray', textAlign: 'center'}}>{service.abo_ht} €</Text>*/}
                                                    {/*<Text*/}
                                                    {/*    style={{width: '11%', color: 'gray', textAlign: 'center'}}></Text>*/}
                                                    <View
                                                        style={{
                                                            width: '10%',
                                                            color: 'gray',
                                                            textAlign: 'center',
                                                            marginTop: 'auto',
                                                            marginBottom: 'auto',
                                                        }}>
                                                        <Text
                                                            style={{color: "#1162d8"}}>{Math.round(parseFloat(`${service.abo_ht}`))} €</Text>


                                                    </View>
                                                    <View
                                                        style={{
                                                            width: '10%',
                                                            color: 'gray',
                                                            textAlign: 'center',
                                                            marginTop: 'auto',
                                                            marginBottom: 'auto',
                                                        }}>
                                                    <Text style={{color: "#1162d8"}}>{Math.round(parseFloat(`${service.fas_ht}`))} €</Text>
                                                    </View>
                                                    <Text style={{
                                                        width: '6%',
                                                        color: 'gray',
                                                        textAlign: 'center',
                                                        marginTop: 'auto', marginBottom: 'auto',
                                                    }}>{service.quantity}</Text>

                                                    <View
                                                        style={{
                                                            width: '10%',
                                                            color: 'gray',
                                                            textAlign: 'center',
                                                            marginTop: 'auto',
                                                            marginBottom: 'auto',
                                                        }}>
                                                        <Text
                                                            >{parseFloat(`${service.abo_ht}`) * parseInt(`${service.quantity}`)} €</Text>


                                                    </View>
                                                    <View
                                                        style={{
                                                            width: '10%',
                                                            color: 'gray',
                                                            textAlign: 'center',
                                                            marginTop: 'auto',
                                                            marginBottom: 'auto',
                                                        }}>
                                                    <Text>{Math.round(parseFloat(`${service.fas_ht}`) * parseInt(`${service.quantity}`))} €</Text>
                                                    </View>
                                                </View>
                                            )
                                        } else {
                                            return false;
                                        }
                                    })}
                                    {/*{site.services.map((service: ServicesState, index: number) => {*/}
                                    {/*    if (service) {*/}
                                    {/*        return (*/}
                                    {/*            <View key={index} style={styles.row} wrap={false}>*/}
                                    {/*                <Text style={{*/}
                                    {/*                    width: '5%',*/}
                                    {/*                    color: 'gray'*/}
                                    {/*                }}>{!isNaN(parseInt(service.duration)) ? `${parseInt(service.duration)} Mois` : "0 Mois"}</Text>*/}
                                    {/*                <View style={{width: '11%'}}>*/}
                                    {/*                    <Text style={{*/}
                                    {/*                        color: "white",*/}
                                    {/*                        padding: 5,*/}
                                    {/*                        textAlign: 'center',*/}
                                    {/*                        backgroundColor: this.categoryColor(this.categoryName(service.id_categorie))*/}
                                    {/*                    }}>{this.categoryName(service.id_categorie)}</Text>*/}
                                    {/*                    /!*<Text style={{paddingTop: 5, paddingBottom: 5, textAlign: 'center', height: 'auto', borderBottomWidth: 1, borderColor: 'gray'}}>REF</Text>*!/*/}
                                    {/*                    /!*<Text style={{paddingTop: 5, paddingBottom: 5, textAlign: 'center', height: 'auto'}}>{service.reference}</Text>*!/*/}
                                    {/*                </View>*/}
                                    {/*                <View style={{width: '30%', color: 'gainsboro'}}>*/}
                                    {/*                    <Text style={{*/}
                                    {/*                        width: '100%',*/}
                                    {/*                        textAlign: 'left',*/}
                                    {/*                        marginBottom: 10, color: 'gray'*/}
                                    {/*                    }}>{service.designation}</Text>*/}
                                    {/*                    <Text style={{*/}
                                    {/*                        width: '100%',*/}
                                    {/*                        textAlign: 'left',*/}
                                    {/*                        marginLeft: 'auto', color: 'gray'*/}
                                    {/*                    }}>{service.describe_solution}</Text>*/}
                                    {/*                </View>*/}
                                    {/*                <Text style={{*/}
                                    {/*                    width: '6%',*/}
                                    {/*                    color: 'gray',*/}
                                    {/*                    textAlign: 'center'*/}
                                    {/*                }}>{service.quantity}</Text>*/}
                                    {/*                <Text style={{*/}
                                    {/*                    width: '7%',*/}
                                    {/*                    color: 'gray',*/}
                                    {/*                    textAlign: 'center'*/}
                                    {/*                }}>{service.abo_ht} €</Text>*/}
                                    {/*                <Text*/}
                                    {/*                    style={{*/}
                                    {/*                        width: '11%',*/}
                                    {/*                        color: 'gray',*/}
                                    {/*                        textAlign: 'center'*/}
                                    {/*                    }}>{parseFloat(`${service.abo_ht}`) * parseInt(`${service.quantity}`)} €</Text>*/}
                                    {/*                <Text*/}
                                    {/*                    style={{*/}
                                    {/*                        width: '9%',*/}
                                    {/*                        color: 'gray',*/}
                                    {/*                        textAlign: 'center'*/}
                                    {/*                    }}>{Math.round(parseFloat(`${service.fas_ht}`))} €</Text>*/}
                                    {/*                <Text*/}
                                    {/*                    style={{*/}
                                    {/*                        width: '11%',*/}
                                    {/*                        color: 'gray',*/}
                                    {/*                        textAlign: 'center'*/}
                                    {/*                    }}>{Math.round(parseFloat(`${service.fas_ht}`) * parseInt(`${service.quantity}`))} €</Text>*/}
                                    {/*            </View>*/}
                                    {/*        )*/}
                                    {/*    } else {*/}
                                    {/*        return false;*/}
                                    {/*    }*/}
                                    {/*})}*/}

                                    <View style={{
                                        width: "100%",
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        paddingTop: 14,
                                    }} wrap={false}>
                                        <View style={{width: "5%"}}>
                                            <Text style={{fontSize: 9, fontFamily: 'PoppinsBold'}}>Total</Text>
                                        </View>
                                        <View style={{width: "11%"}}/>
                                        <View style={{width: "30%"}}/>
                                        {/*<View style={{*/}
                                        {/*    width: "22%",*/}
                                        {/*    display: "flex",*/}
                                        {/*    flexDirection: "row",*/}
                                        {/*    fontSize: 6,*/}
                                        {/*    fontFamily: 'PoppinsLight'*/}
                                        {/*}}>*/}
                                        {/*<View style={{width: "50%", display: "flex"}}>*/}
                                        {/*    <Text style={{margin: "auto"}}>{site.abo_ht} €</Text>*/}
                                        {/*</View>*/}
                                        {/*<View style={{width: "50%", display: "flex"}}>*/}
                                        {/*    <Text style={{margin: "auto"}}>{site.fas_ht} €</Text>*/}
                                        {/*</View>*/}

                                        <View
                                            style={{
                                                width: '10%',
                                                color: 'gray',
                                                textAlign: 'center',
                                                fontSize: 6,
                                                display: 'flex',
                                                marginTop: 'auto',
                                                marginBottom: 'auto',
                                            }}>
                                            {/*<Text*/}
                                            {/*    style={{color: "#1162d8"}}>{*/}
                                            {/*    site.services.map(service => service.abo_ht).reduce((a, c) => a + c, 0)*/}
                                            {/*} €*/}
                                            {/*</Text>*/}

                                        </View>

                                        <View
                                            style={{
                                                width: '10%',
                                                color: 'gray',
                                                textAlign: 'center',
                                                fontSize: 6,
                                                display: 'flex',
                                                marginTop: 'auto',
                                                marginBottom: 'auto',
                                            }}>
                                        {/*<Text style={{color: "#1162d8"}}>{*/}
                                        {/*    site.services.map(service => service.fas_ht).reduce((a, c) => a + c, 0)*/}
                                        {/*} €</Text>*/}
                                        </View>

                                        <View style={{width: "6%"}}/>
                                        <View
                                            style={{
                                                width: '10%',
                                                color: 'gray',
                                                fontSize: 6,
                                                display: 'flex',
                                                textAlign: 'center',
                                                marginTop: 'auto',
                                                marginBottom: 'auto',
                                            }}>
                                            <Text
                                                >{site.abo_ht} €</Text>


                                        </View>
                                        <View
                                            style={{
                                                width: '10%',
                                                color: 'gray',
                                                fontSize: 6,
                                                display: 'flex',
                                                textAlign: 'center',
                                                marginTop: 'auto',
                                                marginBottom: 'auto',
                                            }}>
                                        <Text>{site.fas_ht} €</Text>
                                        </View>
                                    </View>
                                </View>
                            ))}
                        </View>
                        {/*{!billing.contract &&*/}
                        {/*<View style={{*/}
                        {/*    position: 'absolute',*/}
                        {/*    width: '100%',*/}
                        {/*    height: '67%',*/}
                        {/*    paddingRight: '10%',*/}
                        {/*    top: '20%',*/}
                        {/*    display: 'flex',*/}
                        {/*    textAlign: 'center',*/}
                        {/*    //@ts-ignore*/}
                        {/*    zIndex: 9*/}
                        {/*}} fixed>*/}
                        {/*    <Text style={{*/}
                        {/*        fontSize: 170,*/}
                        {/*        margin: 'auto',*/}
                        {/*        transform: 'rotate(-60deg)',*/}
                        {/*        color: '#e3d8df',*/}
                        {/*        opacity: 0.25*/}
                        {/*    }}>DEVIS</Text>*/}
                        {/*</View>*/}
                        {/*}*/}
                        <View style={{height: 1, marginRight: '8%', marginLeft: '8%', backgroundColor: 'gainsboro'}}/>
                        <View style={styles.footer} wrap={false}>
                            <View style={styles.ctnResume}>
                                <Text style={{
                                    fontSize: 9,
                                    width: '90%',
                                    marginRight: 'auto',
                                    marginBottom: 15,
                                    borderRadius: 7,
                                    fontFamily: 'PoppinsBold'
                                }}>Résumé de la commande:</Text>
                                <View style={styles.resume}>
                                    <View style={styles.headerFooter}>
                                        <Text style={{width: '33.33%', fontFamily: 'PoppinsBold'}}>Site</Text>
                                        <Text style={{width: '33.33%', textAlign: 'center', fontFamily: 'PoppinsBold'}}>Abonnement
                                            mensuel HT</Text>
                                        <Text style={{width: '33.33%%', textAlign: 'right', fontFamily: 'PoppinsBold'}}>Frais d'Accès aux Services (FAS)
                                            HT</Text>
                                    </View>
                                    {sites.map((site: SitesState, i: number) => (
                                        <View key={i} style={styles.rowFooter}>
                                            <Text style={{width: '33.33%'}}>{i + 1}: {site.site_name}</Text>
                                            <Text style={{
                                                width: '33.33%',
                                                textAlign: 'center'
                                            }}>{parseFloat(`${site.abo_ht}`).toFixed(2)} €</Text>
                                            <Text style={{
                                                width: '33.33%',
                                                textAlign: 'right'
                                            }}>{parseFloat(`${site.fas_ht}`).toFixed(2)} €</Text>
                                        </View>
                                    ))}
                                    {(society.discount_abo > 0 || society.discount_fas > 0) && (
                                        <View style={styles.footerDiscount}>
                                            <Text style={{width: '33.33%', fontFamily: 'PoppinsBold'}}>Remise</Text>
                                            <Text
                                                style={{
                                                    width: '33.33%',
                                                    textAlign: 'center'
                                                }}>{society.discount_abo} %</Text>
                                            <Text
                                                style={{
                                                    width: '33.33%',
                                                    textAlign: 'right'
                                                }}>{society.discount_fas} %</Text>
                                        </View>
                                    )}
                                    <View style={styles.footerFooter}>
                                        <Text style={{width: '33.33%', fontFamily: 'PoppinsBold'}}>TOTAL HT</Text>
                                        <Text style={{
                                            width: '33.33%',
                                            textAlign: 'center',
                                            fontFamily: 'PoppinsBold'
                                        }}>{parseFloat(`${society.abo_ht}`).toFixed(2)} €</Text>
                                        <Text style={{
                                            width: '33.33%',
                                            textAlign: 'right',
                                            fontFamily: 'PoppinsBold'
                                        }}>{parseFloat(`${society.fas_ht}`).toFixed(2)} €</Text>
                                    </View>
                                    <View style={styles.footerFooterr}>
                                        <Text style={{width: '33.33%', fontWeight: 'bold'}}>TOTAL TVA</Text>
                                        <Text style={{
                                            width: '33.33%',
                                            textAlign: 'center',
                                        }}>{parseFloat(`${(society.abo_ht * 1.2) - society.abo_ht}`).toFixed(2)} €</Text>
                                        <Text style={{
                                            width: '33.33%',
                                            textAlign: 'right'
                                        }}>{parseFloat(`${(society.fas_ht * 1.2) - society.fas_ht}`).toFixed(2)} €</Text>
                                    </View>
                                    <View style={styles.footerFooter}>
                                        <Text style={{width: '33.33%', fontWeight: 'bold'}}>TOTAL
                                            TTC</Text>
                                        <Text style={{
                                            width: '33.33%',
                                            textAlign: 'center',
                                        }}>{parseFloat(`${society.abo_ht * 1.2}`).toFixed(2)} €</Text>
                                        <Text style={{
                                            width: '33.33%',
                                            textAlign: 'right',
                                        }}>{parseFloat(`${society.fas_ht * 1.2}`).toFixed(2)} €</Text>
                                    </View>
                                </View>
                            </View>
                        </View>
                        <Text style={styles.pageNumber} render={() => (
                            `SAYSE SAS au capital de 17 566 € - RCS de Paris 801 891 722 00010 - Nº de TVA Intracommunautaire FR 03 801 891 722 \n
            Siège sociale : 10 rue de la Paix - 75002 PARIS - Tel : 0820 432 000 - www.sayse.fr - contact@sayse.fr`
                        )} fixed/>
                    </Page>
                    <Page style={{paddingBottom: 45}}>
                        <View style={{width: '100%', display: 'flex', padding: '8%'}}>
                            <View style={{marginTop: 30}} wrap={false}>
                                <View style={{
                                    fontSize: 9,
                                    textAlign: 'left',
                                    fontFamily: "PoppinsBold",
                                    marginBottom: 15
                                }}>
                                    <Text style={{paddingLeft: 7}}>Prestations complémentaires</Text>
                                </View>
                                <View>
                                    <View style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        padding: 7,
                                        width: '100%',
                                        fontSize: 6
                                    }}>
                                        <Text style={{width: '55%'}}>Configuration technique à distance</Text>
                                        <Text style={{width: '45%', color: 'gray'}}>90 Euros HT / 30 minutes</Text>
                                    </View>
                                    <View style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        borderTop: 0.2,
                                        borderColor: 'grey',
                                        padding: 7,
                                        width: '100%',
                                        fontSize: 6
                                    }}>
                                        <Text style={{width: '55%'}}>Déplacement d'un technicien sur site, en
                                            Ile-de-France, 1 Heure incluse</Text>
                                        <Text style={{width: '45%', color: 'gray'}}>290 Euros HT/Demi-journée</Text>
                                    </View>
                                    <View style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        borderTop: 0.2,
                                        borderColor: 'grey',
                                        padding: 7,
                                        width: '100%',
                                        fontSize: 6
                                    }}>
                                        <Text style={{width: '55%'}}>Heure supplémentaire</Text>
                                        <Text style={{width: '45%', color: 'gray'}}>130 Euros HT</Text>
                                    </View>
                                    <View style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        borderTop: 0.2,
                                        borderColor: 'grey',
                                        padding: 7,
                                        width: '100%',
                                        fontSize: 6
                                    }}>
                                        <Text style={{width: '55%'}}>Intervention sur site, Hors Ile-de-France</Text>
                                        <Text style={{width: '45%', color: 'gray'}}>Sur devis</Text>
                                    </View>
                                    <View style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        borderTop: 0.2,
                                        borderColor: 'grey',
                                        padding: 7,
                                        width: '100%',
                                        fontSize: 6
                                    }}>
                                        <Text style={{width: '55%'}}>Forfait 4G(carte Sim interne)</Text>
                                        <View style={{width: '45%', color: 'gray'}}>
                                            <Text style={{marginBottom: 5}}>Jusqu'à 1Mo = inclus</Text>
                                            <Text style={{marginBottom: 5}}>Jusqu'à 100Mo = 20 Euros HT</Text>
                                            <Text style={{marginBottom: 5}}>Jusqu'à 500Mo = 30 Euros HT</Text>
                                            <Text style={{marginBottom: 5}}>Jusqu'à 3Go = 40 Euros HT</Text>
                                            <Text style={{marginBottom: 5}}>Jusqu'à 20Go = 70 Euros HT</Text>
                                        </View>
                                    </View>
                                    <View style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        borderTop: 0.2,
                                        borderColor: 'grey',
                                        padding: 7,
                                        width: '100%',
                                        fontSize: 6
                                    }}>
                                        <Text style={{width: '55%'}}>Services UCAAS</Text>
                                        <View style={{width: '45%', color: 'gray'}}>
                                            <Text style={{marginTop: 5}}>Transferts d'appels (Plus de 2heures):
                                                0,023€HT/min
                                                vers les fixes</Text>
                                            <Text style={{marginTop: 5}}>Transferts d'appels (Plus de 2heures):
                                                0,23€HT/min vers
                                                les mobiles</Text>
                                        </View>
                                    </View>
                                    <View style={{
                                        borderTop: 0.2,
                                        borderColor: 'grey',
                                        padding: 7,
                                        width: '100%',
                                        fontSize: 6
                                    }}>
                                        <Text>Dans le cas où le Client souhaite la mise en place de la solution SD-WAN
                                            de SAYSE,
                                            il faudrait procéder à l'ouverture des ports suivants vers internet:</Text>
                                        <View>
                                            <Text style={{marginTop: 3}}>*Ports TCP/UDP 15392</Text>
                                            <Text style={{marginTop: 3}}>*Ports TCP/UDP 53</Text>
                                            <Text style={{marginTop: 3}}>*Port UDP 123</Text>
                                            <Text style={{marginTop: 3}}>*Port TCP 21</Text>
                                        </View>
                                    </View>
                                </View>

                            </View>


                            <View wrap={false}>
                                <View style={{
                                    marginTop: 30,
                                    textAlign: 'left',
                                    fontSize: 9,
                                    fontFamily: "PoppinsBold",
                                    marginBottom: 15
                                }}>
                                    <Text style={{paddingLeft: 7}}>Modalités de règlement des services et
                                        prestations</Text>
                                </View>
                                <View style={{
                                    paddingBottom: 5,
                                    borderBottomLeftRadius: 5,
                                    borderBottomRightRadius: 5
                                }}>
                                    <View style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        border: '1px solid grey',
                                        padding: 7,
                                        width: '100%',
                                        fontSize: 6
                                    }}>
                                        <Text style={{width: '55%'}}>Mode de paiement</Text>
                                        <Text style={{width: '45%', color: 'gray'}}>Prélevement Automatique</Text>
                                    </View>
                                    <View style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        borderTop: 0.2,
                                        borderColor: 'grey',
                                        padding: 7,
                                        width: '100%',
                                        fontSize: 6
                                    }}>
                                        <Text style={{width: '55%'}}>Abonnements (ABO)</Text>
                                        <Text style={{width: '45%', color: 'gray'}}>Trimestriellement terme à échoir, à
                                            date de
                                            facture</Text>
                                    </View>
                                    <View style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        borderTop: 0.2,
                                        borderColor: 'grey',
                                        padding: 7,
                                        width: '100%',
                                        fontSize: 6
                                    }}>
                                        <Text style={{width: '55%'}}>Frais d'Accès aux Services (FAS)</Text>
                                        <Text style={{width: '45%', color: 'gray'}}>50% à la commande et 50% à la
                                            livraison de chaque
                                            service</Text>
                                    </View>
                                    <View style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        borderTop: 0.2,
                                        borderColor: 'grey',
                                        padding: 7,
                                        width: '100%',
                                        fontSize: 6
                                    }}>
                                        <Text style={{width: '55%'}}>Prestations (PRESTA)</Text>
                                        <Text style={{width: '45%', color: 'gray'}}>mensuellement terme échu, à date de
                                            facture</Text>
                                    </View>
                                    <View style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        borderTop: 0.2,
                                        borderColor: 'grey',
                                        padding: 7,
                                        width: '100%',
                                        fontSize: 6
                                    }}>
                                        <Text style={{width: '55%'}}>Consommations (CONSO)</Text>
                                        <Text style={{width: '45%', color: 'gray'}}>mensuellement terme échu, à date de
                                            facture</Text>
                                    </View>
                                    <View style={{
                                        fontSize: 6,
                                        padding: 7,
                                        marginTop: 10,
                                        borderTop: 0.2,
                                        borderColor: 'grey',
                                    }}>
                                        <Text>Les communications hors forfait seront facturées selon les tarifs en
                                            vigueur (cf
                                            Tarif consommations téléphonique par PAYS v1.0)</Text>
                                        <Text>Chaque service livré et conforme aupré requis techniques fournis, sera
                                            réputé
                                            recetté automatiquement et donnera lieu au démarrage de la facturation du ou
                                            des
                                            service(s) concerné(s).</Text>
                                        <Text style={{marginTop: 10}}>En cas de souscription à une liaison/accès IP du
                                            Client
                                            auprès de SAYSE, la desserte interne devra être effectuée par le Client. La
                                            Date de
                                            livraison de l'accès IP sur le site du Client sera considérée comme la Date
                                            de la
                                            recette, même si la desserte interne n'a pas encore été réalisée.</Text>
                                    </View>
                                </View>
                            </View>
                            <View style={{marginTop: 30}}>
                                <View style={{
                                    textAlign: 'left',
                                    fontFamily: "PoppinsBold",
                                    fontSize: 9,
                                    marginBottom: 15
                                }}>
                                    <Text style={{paddingLeft: 7}}>Clauses</Text>
                                </View>
                                <View style={{
                                    fontSize: 6,
                                    paddingBottom: 5,
                                    borderRadius: 7,
                                    border: 1,
                                    borderColor: 'gainsboro',
                                    backgroundColor: 'white'
                                }}>
                                    {clauses.map((clause, index) => (
                                        <View wrap={false} key={index} style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            padding: 7,
                                        }}>
                                            <Text style={{width: '30%'}}>{clause.title}</Text>
                                            <Text style={{width: '70%'}}>{clause.describe_clauses}</Text>
                                        </View>
                                    ))}
                                </View>
                            </View>
                            <View wrap={false}>
                                <View style={{marginTop: 30}} wrap={false}>
                                    <View style={{
                                        textAlign: 'left',
                                        fontFamily: "PoppinsBold",
                                        fontSize: 9,
                                        marginBottom: 15
                                    }}>
                                        <Text style={{paddingLeft: 7}}>Accords des parties</Text>
                                    </View>
                                    <View style={{
                                        fontSize: 6,
                                        paddingBottom: 5,
                                    }}>
                                        <Text style={{padding: 5}}>Le Contrat, constitué du présent Bon de Commande, des
                                            Conditions Générale de Vente et des Conditions Particulières des Services
                                            désignés ci-dessus, dont le Client reconnaît avoir pris connaissance sur le
                                            site
                                            internet de Sayse (https://sayse.fr/contrats-de-services) et qu'il déclare
                                            accepter sans réserve, constitue l'accord unique des Parties. Le Client
                                            certifie
                                            de l'exactitude des informations communiquées au Prestataire.</Text>
                                        <Text style={{marginTop: 10, padding: 5}}>Le Client garantit au Prestataire
                                            qu'il
                                            dispose de l'ensemble des droits et pouvoirs nécessaires à l'effet de signer
                                            le
                                            présent Bon de Commande au nom et pour le compte des entités bénéficiaires
                                            du/des Service(s). En tout état de cause, le Client est solidaire vis-à-vis
                                            du
                                            Prestataire de l'exécution de leurs obligations par les entités
                                            bénéficiaires
                                            du/des Service(s).</Text>
                                    </View>
                                </View>
                                <View style={{
                                    height: 80,
                                    display: 'flex',
                                    flexDirection: 'row',
                                    marginTop: 30,
                                    fontSize: 6
                                }} wrap={false}>
                                    <View style={{width: '65%'}}>
                                        <Text>Pour Signataire-représentant légal:</Text>
                                        <Text style={{marginTop: 5}}>Fonction:</Text>
                                        <Text style={{marginTop: 26}}>Date:</Text>
                                    </View>
                                    <View style={{width: '35%'}}>
                                        <Text style={{marginLeft: 3, marginBottom: 5}}>Cachet et signature du
                                            client</Text>
                                        <View style={{
                                            height: '90%',
                                            width: '100%',
                                            padding: 3,
                                            border: 1,
                                            borderColor: 'gainsboro',
                                            borderRadius: 7,
                                            backgroundColor: 'white'
                                        }}>
                                            <Text>Mention obligatoire «lu et approuvé»</Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                        </View>
                        <Text style={styles.pageNumber} render={() => (
                            `SAYSE SAS au capital de 17 566 € - RCS de Paris 801 891 722 00010 - Nº de TVA Intracommunautaire FR 03 801 891 722 \n
            Siège sociale : 10 rue de la Paix - 75002 PARIS - Tel : 0820 432 000 - www.sayse.fr - contact@sayse.fr`
                        )} fixed/>
                    </Page>
            {/*        <Page style={{paddingBottom: 45}}>*/}
            {/*            /!*<Image src={Round} style={styles.pageBackground} fixed/>*!/*/}
            {/*            <View style={{padding: '8%'}}>*/}
            {/*                <Image style={styles.logo} source={Logo}/>*/}
            {/*                <Text style={{fontFamily: 'PoppinsBold', fontSize: 9, marginBottom: 25, marginTop: 25}}>Mandat*/}
            {/*                    de prélèvement SEPA</Text>*/}
            {/*                <View style={{width: "100%"}}>*/}
            {/*                    <View style={{*/}
            {/*                        fontSize: 8,*/}
            {/*                        flexDirection: 'row',*/}
            {/*                        display: 'flex',*/}
            {/*                        justifyContent: 'space-between'*/}
            {/*                    }}>*/}
            {/*                        <View style={{width: '45%', display: 'flex', justifyContent: 'space-between'}}>*/}
            {/*                            <View style={{*/}
            {/*                                borderWidth: 1,*/}
            {/*                                borderColor: 'gainsboro',*/}
            {/*                                marginBottom: 20,*/}
            {/*                                padding: 5,*/}
            {/*                                borderRadius: 7*/}
            {/*                            }}>*/}
            {/*                                <View style={{*/}
            {/*                                    width: '100%',*/}
            {/*                                    display: 'flex',*/}
            {/*                                    justifyContent: 'center'*/}
            {/*                                }}>*/}
            {/*                                    <View style={{*/}
            {/*                                        width: "100%",*/}
            {/*                                        display: "flex",*/}
            {/*                                        flexDirection: "row",*/}
            {/*                                        marginBottom: 3*/}
            {/*                                    }}>*/}
            {/*                                        <Text*/}
            {/*                                            style={{*/}
            {/*                                                width: "25%",*/}
            {/*                                                fontFamily: 'PoppinsBold'*/}
            {/*                                            }}>Créancier:</Text>*/}
            {/*                                        <Text>SAYSE SAS</Text>*/}
            {/*                                    </View>*/}
            {/*                                    <View style={{*/}
            {/*                                        width: "100%",*/}
            {/*                                        display: "flex",*/}
            {/*                                        flexDirection: "row",*/}
            {/*                                        marginBottom: 3*/}
            {/*                                    }}>*/}
            {/*                                        <Text style={{*/}
            {/*                                            width: "25%",*/}
            {/*                                            fontFamily: 'PoppinsBold'*/}
            {/*                                        }}>Adresse:</Text>*/}
            {/*                                        <Text>10, rue de la Paix {"\n"} 75002 Paris</Text>*/}
            {/*                                    </View>*/}
            {/*                                    <View style={{*/}
            {/*                                        width: "100%",*/}
            {/*                                        display: "flex",*/}
            {/*                                        flexDirection: "row",*/}
            {/*                                        marginBottom: 3*/}
            {/*                                    }}>*/}
            {/*                                        <Text style={{width: "25%", fontFamily: 'PoppinsBold'}}>RCS:</Text>*/}
            {/*                                        <Text>801 891 722 0010</Text>*/}
            {/*                                    </View>*/}
            {/*                                    <View style={{*/}
            {/*                                        width: "100%",*/}
            {/*                                        display: "flex",*/}
            {/*                                        flexDirection: "row",*/}
            {/*                                        marginBottom: 3*/}
            {/*                                    }}>*/}
            {/*                                        <Text style={{width: "25%", fontFamily: 'PoppinsBold'}}>ICS:</Text>*/}
            {/*                                        <Text>FR37ZZZ653900</Text>*/}
            {/*                                    </View>*/}
            {/*                                </View>*/}
            {/*                            </View>*/}
            {/*                            <View style={{*/}
            {/*                                borderWidth: 1,*/}
            {/*                                borderColor: 'gainsboro',*/}
            {/*                                marginBottom: 20,*/}
            {/*                                padding: 5,*/}
            {/*                                borderRadius: 7*/}
            {/*                            }}>*/}
            {/*                                <View style={{*/}
            {/*                                    width: "100%",*/}
            {/*                                    display: "flex",*/}
            {/*                                    flexDirection: "row",*/}
            {/*                                    marginBottom: 3*/}
            {/*                                }}>*/}
            {/*                                    <Text style={{fontFamily: 'PoppinsBold', marginRight: 5}}>Société à*/}
            {/*                                        débiter:</Text>*/}
            {/*                                    <Text>{society.society}</Text>*/}
            {/*                                </View>*/}
            {/*                            </View>*/}
            {/*                        </View>*/}
            
            {/*                        <View style={{width: '45%', display: 'flex', justifyContent: 'space-between'}}>*/}
            {/*                            <View style={{*/}
            {/*                                borderWidth: 1,*/}
            {/*                                borderColor: 'gainsboro',*/}
            {/*                                marginBottom: 20,*/}
            {/*                                padding: 5,*/}
            {/*                                borderRadius: 7*/}
            {/*                            }}>*/}
            {/*                                <View style={{*/}
            {/*                                    width: '100%',*/}
            {/*                                    display: 'flex',*/}
            {/*                                    flexDirection: 'row'*/}
            {/*                                }}>*/}
            {/*                                    <Text style={{fontFamily: 'PoppinsBold', marginRight: 5}}>Type de*/}
            {/*                                        prélèvement:</Text>*/}
            {/*                                    <Text>Récurrent</Text>*/}
            {/*                                </View>*/}
            {/*                            </View>*/}
            {/*                            <View style={{*/}
            {/*                                borderWidth: 1,*/}
            {/*                                borderColor: 'gainsboro',*/}
            {/*                                marginBottom: 20,*/}
            {/*                                padding: 5,*/}
            {/*                                borderRadius: 7*/}
            {/*                            }}>*/}
            {/*                                <View style={{*/}
            {/*                                    width: "100%",*/}
            {/*                                    display: "flex",*/}
            {/*                                    flexDirection: "row",*/}
            {/*                                    marginBottom: 3*/}
            {/*                                }}>*/}
            {/*                                    <Text style={{fontFamily: 'PoppinsBold'}}>BIC:</Text>*/}
            
            {/*                                </View>*/}
            {/*                            </View>*/}
            {/*                            <View style={{*/}
            {/*                                borderWidth: 1,*/}
            {/*                                borderColor: 'gainsboro',*/}
            {/*                                marginBottom: 20,*/}
            {/*                                padding: 5,*/}
            {/*                                borderRadius: 7*/}
            {/*                            }}>*/}
            {/*                                <View style={{*/}
            {/*                                    width: "100%",*/}
            {/*                                    display: "flex",*/}
            {/*                                    flexDirection: "row",*/}
            {/*                                    marginBottom: 3*/}
            {/*                                }}>*/}
            {/*                                    <Text style={{fontFamily: 'PoppinsBold'}}>IBAN:</Text>*/}
            {/*                                </View>*/}
            {/*                            </View>*/}
            {/*                        </View>*/}
            {/*                    </View>*/}
            {/*                    <View style={{*/}
            {/*                        borderWidth: 1,*/}
            {/*                        borderColor: 'gainsboro',*/}
            {/*                        marginBottom: 20,*/}
            {/*                        padding: 5,*/}
            {/*                        borderRadius: 7*/}
            {/*                    }}>*/}
            {/*                        <View style={{*/}
            {/*                            width: "100%",*/}
            {/*                            display: "flex",*/}
            {/*                            flexDirection: "row",*/}
            {/*                            marginBottom: 3,*/}
            {/*                            fontSize: 8,*/}
            {/*                        }}>*/}
            {/*                            <Text style={{fontFamily: 'PoppinsBold', marginRight: 5}}>Adresse:</Text>*/}
            {/*                            <Text>{society.address}</Text>*/}
            {/*                        </View>*/}
            {/*                    </View>*/}
            {/*                </View>*/}
            {/*                <Text style={{fontSize: 8, marginTop: 20}}>*/}
            {/*                    En signant ce formulaire de mandat, vous autorisez Sayse SAS, le créancier, à envoyer*/}
            {/*                    des instructions à votre banque pour débiter votre compte conformément aux instructions*/}
            {/*                    du créancier.{"\n"}{"\n"}*/}
            {/*                    Ce mandat est destiné uniquement aux transactions business-to-business. Vous ne*/}
            {/*                    bénéficier pas d'un droit de remboursement par votre banque après le débit de votre*/}
            {/*                    compte, mais jusqu'à la date d'échéance, vous avez le droit de demander à votre banque*/}
            {/*                    de ne pas débiter votre compte.*/}
            {/*                </Text>*/}
            {/*                <View style={{*/}
            {/*                    display: 'flex',*/}
            {/*                    flexDirection: 'row',*/}
            {/*                    fontSize: 8,*/}
            {/*                    justifyContent: 'space-between',*/}
            {/*                    marginTop: 40*/}
            {/*                }}>*/}
            {/*                    <Text>Le</Text>*/}
            {/*                    <View style={{width: '50%'}}>*/}
            {/*                        <Text style={{paddingLeft: 7, marginBottom: 7}}>Signature et tampon société</Text>*/}
            {/*                        <View style={{*/}
            {/*                            height: 100,*/}
            {/*                            backgroundColor: 'white',*/}
            {/*                            borderRadius: 7,*/}
            {/*                            borderColor: 'black',*/}
            {/*                            borderWidth: 1*/}
            {/*                        }}/>*/}
            {/*                    </View>*/}
            {/*                </View>*/}
            {/*                <Text style={{fontSize: 6, marginTop: 40}}>Tous les champs sont obligatoires</Text>*/}
            {/*            </View>*/}
            {/*            <Text style={styles.pageNumber} render={() => (*/}
            {/*                `SAYSE SAS au capital de 17 566 € - RCS de Paris 801 891 722 00010 - Nº de TVA Intracommunautaire FR 03 801 891 722 \n*/}
            {/*Siège sociale : 10 rue de la Paix - 75002 PARIS - Tel : 0820 432 000 - www.sayse.fr - contact@sayse.fr`*/}
            {/*            )} fixed/>*/}
            {/*        </Page>*/}
            {/*        <Page>*/}
            {/*            <Image src={Cgv1} style={{height: '100%', width: '100%'}}/>*/}
            {/*        </Page>*/}
            {/*        <Page>*/}
            {/*            <Image src={'/assets/cgv2.jpg'} style={{height: '100%', width: '100%'}}/>*/}
            {/*        </Page>*/}
            {/*        <Page>*/}
            {/*            <Image src={'/assets/cgv3.jpg'} style={{height: '100%', width: '100%'}}/>*/}
            {/*        </Page> <Page style={{paddingBottom: 45}}>*/}
            {/*            /!*<Image src={Round} style={styles.pageBackground} fixed/>*!/*/}
            {/*            <View style={{padding: '8%'}}>*/}
            {/*                <Image style={styles.logo} source={Logo}/>*/}
            {/*                <Text style={{fontFamily: 'PoppinsBold', fontSize: 9, marginBottom: 25, marginTop: 25}}>Mandat*/}
            {/*                    de prélèvement SEPA</Text>*/}
            {/*                <View style={{width: "100%"}}>*/}
            {/*                    <View style={{*/}
            {/*                        fontSize: 8,*/}
            {/*                        flexDirection: 'row',*/}
            {/*                        display: 'flex',*/}
            {/*                        justifyContent: 'space-between'*/}
            {/*                    }}>*/}
            {/*                        <View style={{width: '45%', display: 'flex', justifyContent: 'space-between'}}>*/}
            {/*                            <View style={{*/}
            {/*                                borderWidth: 1,*/}
            {/*                                borderColor: 'gainsboro',*/}
            {/*                                marginBottom: 20,*/}
            {/*                                padding: 5,*/}
            {/*                                borderRadius: 7*/}
            {/*                            }}>*/}
            {/*                                <View style={{*/}
            {/*                                    width: '100%',*/}
            {/*                                    display: 'flex',*/}
            {/*                                    justifyContent: 'center'*/}
            {/*                                }}>*/}
            {/*                                    <View style={{*/}
            {/*                                        width: "100%",*/}
            {/*                                        display: "flex",*/}
            {/*                                        flexDirection: "row",*/}
            {/*                                        marginBottom: 3*/}
            {/*                                    }}>*/}
            {/*                                        <Text*/}
            {/*                                            style={{*/}
            {/*                                                width: "25%",*/}
            {/*                                                fontFamily: 'PoppinsBold'*/}
            {/*                                            }}>Créancier:</Text>*/}
            {/*                                        <Text>SAYSE SAS</Text>*/}
            {/*                                    </View>*/}
            {/*                                    <View style={{*/}
            {/*                                        width: "100%",*/}
            {/*                                        display: "flex",*/}
            {/*                                        flexDirection: "row",*/}
            {/*                                        marginBottom: 3*/}
            {/*                                    }}>*/}
            {/*                                        <Text style={{*/}
            {/*                                            width: "25%",*/}
            {/*                                            fontFamily: 'PoppinsBold'*/}
            {/*                                        }}>Adresse:</Text>*/}
            {/*                                        <Text>10, rue de la Paix {"\n"} 75002 Paris</Text>*/}
            {/*                                    </View>*/}
            {/*                                    <View style={{*/}
            {/*                                        width: "100%",*/}
            {/*                                        display: "flex",*/}
            {/*                                        flexDirection: "row",*/}
            {/*                                        marginBottom: 3*/}
            {/*                                    }}>*/}
            {/*                                        <Text style={{width: "25%", fontFamily: 'PoppinsBold'}}>RCS:</Text>*/}
            {/*                                        <Text>801 891 722 0010</Text>*/}
            {/*                                    </View>*/}
            {/*                                    <View style={{*/}
            {/*                                        width: "100%",*/}
            {/*                                        display: "flex",*/}
            {/*                                        flexDirection: "row",*/}
            {/*                                        marginBottom: 3*/}
            {/*                                    }}>*/}
            {/*                                        <Text style={{width: "25%", fontFamily: 'PoppinsBold'}}>ICS:</Text>*/}
            {/*                                        <Text>FR37ZZZ653900</Text>*/}
            {/*                                    </View>*/}
            {/*                                </View>*/}
            {/*                            </View>*/}
            {/*                            <View style={{*/}
            {/*                                borderWidth: 1,*/}
            {/*                                borderColor: 'gainsboro',*/}
            {/*                                marginBottom: 20,*/}
            {/*                                padding: 5,*/}
            {/*                                borderRadius: 7*/}
            {/*                            }}>*/}
            {/*                                <View style={{*/}
            {/*                                    width: "100%",*/}
            {/*                                    display: "flex",*/}
            {/*                                    flexDirection: "row",*/}
            {/*                                    marginBottom: 3*/}
            {/*                                }}>*/}
            {/*                                    <Text style={{fontFamily: 'PoppinsBold', marginRight: 5}}>Société à*/}
            {/*                                        débiter:</Text>*/}
            {/*                                    <Text>{society.society}</Text>*/}
            {/*                                </View>*/}
            {/*                            </View>*/}
            {/*                        </View>*/}
            
            {/*                        <View style={{width: '45%', display: 'flex', justifyContent: 'space-between'}}>*/}
            {/*                            <View style={{*/}
            {/*                                borderWidth: 1,*/}
            {/*                                borderColor: 'gainsboro',*/}
            {/*                                marginBottom: 20,*/}
            {/*                                padding: 5,*/}
            {/*                                borderRadius: 7*/}
            {/*                            }}>*/}
            {/*                                <View style={{*/}
            {/*                                    width: '100%',*/}
            {/*                                    display: 'flex',*/}
            {/*                                    flexDirection: 'row'*/}
            {/*                                }}>*/}
            {/*                                    <Text style={{fontFamily: 'PoppinsBold', marginRight: 5}}>Type de*/}
            {/*                                        prélèvement:</Text>*/}
            {/*                                    <Text>Récurrent</Text>*/}
            {/*                                </View>*/}
            {/*                            </View>*/}
            {/*                            <View style={{*/}
            {/*                                borderWidth: 1,*/}
            {/*                                borderColor: 'gainsboro',*/}
            {/*                                marginBottom: 20,*/}
            {/*                                padding: 5,*/}
            {/*                                borderRadius: 7*/}
            {/*                            }}>*/}
            {/*                                <View style={{*/}
            {/*                                    width: "100%",*/}
            {/*                                    display: "flex",*/}
            {/*                                    flexDirection: "row",*/}
            {/*                                    marginBottom: 3*/}
            {/*                                }}>*/}
            {/*                                    <Text style={{fontFamily: 'PoppinsBold'}}>BIC:</Text>*/}
            
            {/*                                </View>*/}
            {/*                            </View>*/}
            {/*                            <View style={{*/}
            {/*                                borderWidth: 1,*/}
            {/*                                borderColor: 'gainsboro',*/}
            {/*                                marginBottom: 20,*/}
            {/*                                padding: 5,*/}
            {/*                                borderRadius: 7*/}
            {/*                            }}>*/}
            {/*                                <View style={{*/}
            {/*                                    width: "100%",*/}
            {/*                                    display: "flex",*/}
            {/*                                    flexDirection: "row",*/}
            {/*                                    marginBottom: 3*/}
            {/*                                }}>*/}
            {/*                                    <Text style={{fontFamily: 'PoppinsBold'}}>IBAN:</Text>*/}
            {/*                                </View>*/}
            {/*                            </View>*/}
            {/*                        </View>*/}
            {/*                    </View>*/}
            {/*                    <View style={{*/}
            {/*                        borderWidth: 1,*/}
            {/*                        borderColor: 'gainsboro',*/}
            {/*                        marginBottom: 20,*/}
            {/*                        padding: 5,*/}
            {/*                        borderRadius: 7*/}
            {/*                    }}>*/}
            {/*                        <View style={{*/}
            {/*                            width: "100%",*/}
            {/*                            display: "flex",*/}
            {/*                            flexDirection: "row",*/}
            {/*                            marginBottom: 3,*/}
            {/*                            fontSize: 8,*/}
            {/*                        }}>*/}
            {/*                            <Text style={{fontFamily: 'PoppinsBold', marginRight: 5}}>Adresse:</Text>*/}
            {/*                            <Text>{society.address}</Text>*/}
            {/*                        </View>*/}
            {/*                    </View>*/}
            {/*                </View>*/}
            {/*                <Text style={{fontSize: 8, marginTop: 20}}>*/}
            {/*                    En signant ce formulaire de mandat, vous autorisez Sayse SAS, le créancier, à envoyer*/}
            {/*                    des instructions à votre banque pour débiter votre compte conformément aux instructions*/}
            {/*                    du créancier.{"\n"}{"\n"}*/}
            {/*                    Ce mandat est destiné uniquement aux transactions business-to-business. Vous ne*/}
            {/*                    bénéficier pas d'un droit de remboursement par votre banque après le débit de votre*/}
            {/*                    compte, mais jusqu'à la date d'échéance, vous avez le droit de demander à votre banque*/}
            {/*                    de ne pas débiter votre compte.*/}
            {/*                </Text>*/}
            {/*                <View style={{*/}
            {/*                    display: 'flex',*/}
            {/*                    flexDirection: 'row',*/}
            {/*                    fontSize: 8,*/}
            {/*                    justifyContent: 'space-between',*/}
            {/*                    marginTop: 40*/}
            {/*                }}>*/}
            {/*                    <Text>Le</Text>*/}
            {/*                    <View style={{width: '50%'}}>*/}
            {/*                        <Text style={{paddingLeft: 7, marginBottom: 7}}>Signature et tampon société</Text>*/}
            {/*                        <View style={{*/}
            {/*                            height: 100,*/}
            {/*                            backgroundColor: 'white',*/}
            {/*                            borderRadius: 7,*/}
            {/*                            borderColor: 'black',*/}
            {/*                            borderWidth: 1*/}
            {/*                        }}/>*/}
            {/*                    </View>*/}
            {/*                </View>*/}
            {/*                <Text style={{fontSize: 6, marginTop: 40}}>Tous les champs sont obligatoires</Text>*/}
            {/*            </View>*/}
            {/*            <Text style={styles.pageNumber} render={() => (*/}
            {/*                `SAYSE SAS au capital de 17 566 € - RCS de Paris 801 891 722 00010 - Nº de TVA Intracommunautaire FR 03 801 891 722 \n*/}
            {/*Siège sociale : 10 rue de la Paix - 75002 PARIS - Tel : 0820 432 000 - www.sayse.fr - contact@sayse.fr`*/}
            {/*            )} fixed/>*/}
            {/*        </Page>*/}
            {/*        <Page>*/}
            {/*            <Image src={Cgv1} style={{height: '100%', width: '100%'}}/>*/}
            {/*        </Page>*/}
            {/*        <Page>*/}
            {/*            <Image src={'/assets/cgv2.jpg'} style={{height: '100%', width: '100%'}}/>*/}
            {/*        </Page>*/}
            {/*        <Page>*/}
            {/*            <Image src={'/assets/cgv3.jpg'} style={{height: '100%', width: '100%'}}/>*/}
            {/*        </Page>*/}

                </Document>
            );
        } else {
            return <Document/>
        }
    }
}

// @ts-ignore
const styles = StyleSheet.create({
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 20
    },
    fontBold: {
        marginLeft: 'auto',
    },
    logo: {
        height: 40,
        width: 100,
    },
    txtInfoSayse: {
        fontSize: 9,
    },
    pageBackground: {
        position: 'absolute',
        minHeight: '100%',
        // display: 'block',
        height: '100%',
    },
    pageFiliBackground: {
        position: 'absolute',
        minHeight: '90%',
        width: '100%',
        top: '45%',
        //@ts-ignore
        zIndex: 9999,
        transform: 'rotate(-60deg)'
    },
    txtInfoClient: {
        // paddingLeft: 15,
        fontFamily: 'PoppinsLight',
        fontSize: 9,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        borderBottomLeftRadius: 15,
        minWidth: 260,
    },
    body: {
        color: 'black',
        width: '100%',
        display: 'flex',
        paddingLeft: '8%',
        paddingRight: '8%',
    },
    ctnSite: {
        // marginLeft: 'auto',
        // marginRight: 'auto',
        // paddingTop: 20,
        // paddingBottom: 20,
        marginBottom: 20,
        backgroundColor: "#fff",
        borderColor: 'gray',
        borderRadius: 7,
        borderWidth: 1,

        padding: 14,
    },
    headerSite: {
        display: 'flex',
        flexDirection: 'row',
        fontSize: 9,
        paddingBottom: 5,
        paddingTop: 7,
        borderBottomWidth: 1,
        justifyContent: 'space-between',
        borderColor: 'gainsboro'
    },
    footerSite: {
        display: "flex",
        flexDirection: 'row',
        // marginTop: 20,
        marginBottom: 20,
        fontFamily: 'PoppinsLight',
        fontSize: 9,
        // borderWidth: 1,
        // borderColor: 'grey',
        // borderRadius: 7,
        // padding: 7,
        // backgroundColor: 'white'
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        fontSize: 6,
        paddingTop: 19,
        paddingBottom: 19,
        borderColor: 'gainsboro',
        justifyContent: 'space-between',
        borderBottomWidth: 1
    },
    footerCase: {
        display: 'flex',
        flexDirection: 'row',
        paddingLeft: 5,
        paddingTop: 3,
        paddingBottom: 3,

    },
    footerCaseName: {
        display: 'flex',
        flexDirection: 'row',
        fontFamily: 'PoppinsBold',


    },
    footer: {
        borderWidth: 1,
        borderColor: 'gray',
        borderRadius: 7,
        marginTop: 20,
        marginBottom: 20,
        marginLeft: '8%',
        marginRight: '8%'
    },
    headerFooter: {
        display: 'flex',
        flexDirection: 'row',
        fontSize: 6,
        paddingBottom: 2,
        borderBottomWidth: 1,
        borderColor: 'gainsboro'
    },
    footerFooter: {
        display: 'flex',
        flexDirection: 'row',
        fontSize: 6,
        marginTop: 5,
        paddingTop: 5,
        borderTopWidth: 0.5,
        borderColor: 'gainsboro'
    },
    footerFooterr: {
        display: 'flex',
        flexDirection: 'row',
        fontSize: 6,
        marginTop: 5,
        paddingTop: 5,
    },
    rowFooter: {
        display: 'flex',
        flexDirection: 'row',
        fontSize: 6,
        paddingTop: 3,
        paddingBottom: 3,
    },
    footerDiscount: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        fontSize: 6,
        paddingTop: 5,
        paddingBottom: 1
    },
    ctnResume: {
        padding: '8%',
        display: 'flex',
        // backgroundColor: 'white'
    },
    resume: {
        margin: 'auto',
    },
    canvas: {
        backgroundColor: '#f0f9fd',
        height: 65,
        width: 180,
        position: 'absolute',
        //@ts-ignore
        zIndex: 99,
        top: 80
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 6,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: 'center',
    },
});

export default PagePDF;
