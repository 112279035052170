import * as React from 'react';
import './Services.scss';
import {connect, ConnectedProps} from "react-redux";
import {DacState, ServicesState} from "../../../../Store/Reducers/Dac/types";
import {editDac, editServiceInput, editServiceObject, initialService} from "../../../../Store/Reducers/Dac/actions";
import TextField from "../../../Input/TextField/TextField";
import {CollectionsState} from "../../../../Store/Reducers/Collections/types";
import InfoPrice from "./InfoPrice/InfoPrice";
import {TotalState} from "../../../../Store/Reducers/Dac/Total/types";
import SelectSearch from "../../../Input/SelectSearch/SelectSearch";
import {editActionsDac} from "../../../../Store/Reducers/Dac/ActionsDac/actions";
import {ActionsDacState} from "../../../../Store/Reducers/Dac/ActionsDac/types";
import Socket from "../../../../utils/Socket/Socket";

interface RootState {
    dacReducer: DacState,
    collectionsReducer: CollectionsState,
    actionsDacReducer: ActionsDacState
    totalReducer: TotalState
}

const mapState = (state: RootState, ownProps: { nbSite: number, nbService: number }) => {
    return ({
        serviceReducer: state.dacReducer.sites[ownProps.nbSite].services[ownProps.nbService],
        serviceCollectionsReducer: state.collectionsReducer.Service,
        servicesCopy: state.actionsDacReducer.copyServices,
        selectedServices: state.actionsDacReducer.copyServices,
        discount_abo: state.totalReducer.discount_abo,
        discount_fas: state.totalReducer.discount_fas,
        countServices: state.dacReducer.countServices,
        categoryReducer: state.collectionsReducer.Category
    })
};

const mapDispatch = (dispatch: Function, ownProps: { nbSite: number, nbService: number }) => ({
    editServiceInput: (name: string, value: string | number) => dispatch(editServiceInput(name, value, ownProps.nbSite, ownProps.nbService)),
    editServiceObject: (value: ServicesState) => dispatch(editServiceObject(value, ownProps.nbSite, ownProps.nbService)),
    pushCopyServices: (name: string, value: Array<{ idSite: string, idService: string }>) => dispatch(editActionsDac(name, value)),
    initialService: () => dispatch(initialService(ownProps.nbSite, ownProps.nbService)),
    editDac: (nb: number) => dispatch(editDac('countServices', nb))
});

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
    nbSite: number,
    nbService: number,
    idService: string,
    idSite: string,
    siteName: string | null,
    durations: Array<{ label: string, id: string }>
}

interface ServicesCompState {
    unlockPrice: boolean,
    special: boolean,
}

class Services extends React.PureComponent<Props, ServicesCompState> {

    // Variable pour s'assurer que le composant est montee (evite les fuites memoires)
    _isMounted = false;

    constructor(props: any) {
        super(props);
        this.state = {
            unlockPrice: true,
            special: true,
        }
    }

    editService = (name: string, value: any) => {
        if ((name === "id_service" && this.props.serviceReducer) && !this.props.serviceReducer.id_categorie) {
            //@ts-ignore
            this.props.editServiceInput('id_categorie', this.getService())
        }
        if (name === "quantity") {
            value = value ? value : 1;
        }
        this.props.editServiceInput(name, value)
    };

    // Recupere  le service selectionne
    getService = () => {
        const {serviceReducer} = this.props;
        if (serviceReducer) {
            const services = this.props.serviceCollectionsReducer;
            // const services = this.props.serviceCollectionsReducer.filter(service => service.id_categorie === serviceReducer.id_categorie);
            const service = services.find(service => service.id_service === serviceReducer.id_service);
            return service ? service : null;
        }
    };

    // Dispatch les informations du services apres selection d'une solution
    dispatchService = () => {
        const {serviceReducer, editServiceInput, editServiceObject} = this.props;
        if (serviceReducer) {
            const service = this.getService();
            let newService = {};
            if (service) {
                for (let [key, value] of Object.entries(service)) {
                    if (Object.keys(serviceReducer).includes(key)) {
                        newService = {...newService, [`${key}`]: value};
                    }
                }
                // @ts-ignore
                editServiceObject(newService)
                if (!serviceReducer.quantity) {
                    editServiceInput("quantity", 1);
                } else {
                    editServiceInput("quantity", serviceReducer.quantity);
                }
            }
        }
    };

    componentDidMount() {
        this._isMounted = true;
        if (this.props.serviceReducer && this.props.serviceReducer._id && this.state.unlockPrice) {
            this.setState(prevState => ({...prevState, unlockPrice: false}));

        }
    }

    async componentWillUnmount() {
        this.props.editDac(this.props.countServices - this.props.serviceReducer.quantity);
        this._isMounted = false;
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<ServicesCompState>, snapshot?: any) {

        if (this.props.serviceReducer && prevProps.serviceReducer) {

            if (prevProps.serviceReducer.id_categorie !== this.props.serviceReducer.id_categorie) {
                if (this.props.serviceReducer.id_categorie === "BrmwsR9tDNMMX4zdt") {
                    this.setState(prevState => ({...prevState, special: false, unlockPrice: false}))
                }

            }
            if (prevProps.serviceReducer.id_service !== this.props.serviceReducer.id_service) {
                if (this.props.serviceReducer.id_service) {
                    this.setState(prevState => ({...prevState, unlockPrice: false}));
                    this.dispatchService();
                }
            }

            // Debloque les tout champs de saisie pour la categorie special
            if (prevProps.serviceReducer.family !== this.props.serviceReducer.family) {
                const isSpecial = this.props.serviceReducer.family !== 'SPECIAL';
                this.setState(prevState => ({...prevState, special: isSpecial}));
            }

            // Deverouilles les champs de saisie si une solution a ete selectionner
            if (prevProps.serviceReducer.designation !== this.props.serviceReducer.designation) {
                this.setState(prevState => ({...prevState, special: true}));
            }

            if (prevProps.serviceReducer.duration !== this.props.serviceReducer.duration) {

            }

        }
    }

    renderPrice = (unit: string) => {
        const {serviceReducer, discount_fas, discount_abo} = this.props;
        let res;
        // @ts-ignore
        if (serviceReducer && serviceReducer.quantity === 1 && (serviceReducer[`${unit}_ht`] || serviceReducer[`${unit}_ht`] == 0)) {
            const discount = unit === 'fas' ? discount_fas : discount_abo;
            // @ts-ignore
            let calc = (serviceReducer[`${unit}_ht`] * (1 - ((discount / this.props.countServices) / 100)));
            res = parseFloat(`${calc}`).toFixed(2) + `€ ${unit === 'fas' ? '' : '/mois'}`;
        } else {
            res = `Prix ${unit.toUpperCase()}`
        }
        return res;
    };

    clearService = () => {
        this.editService('id_categorie', '');
        this.props.initialService();
    }

    selectedService = () => {
        const {serviceReducer, servicesCopy, idSite} = this.props;
        let newCopyServices = [];
        if (serviceReducer && serviceReducer.id) {

            if (!servicesCopy.find(s => s.idService === serviceReducer.id)) {
                newCopyServices = [...servicesCopy, {idSite, idService: serviceReducer.id}];
            } else {
                newCopyServices = servicesCopy.filter(s => s.idService !== serviceReducer.id);
            }
            this.props.pushCopyServices('copyServices', newCopyServices)
        }
    }

    render() {
        const {serviceReducer, nbSite, nbService, siteName, servicesCopy, categoryReducer} = this.props;

        const {unlockPrice, special} = this.state;
        const servicesFilter = (serviceReducer && serviceReducer.id_categorie) ?
            this.props.serviceCollectionsReducer.filter(service => service.id_categorie === serviceReducer.id_categorie)
            : this.props.serviceCollectionsReducer;
        let isSpecial;
        let cat: { _id: string, name: string, id_family: string } | undefined;
        if (categoryReducer) {
            cat = categoryReducer.find(cat => cat._id === serviceReducer.id_categorie);
            if (cat) {
                isSpecial = cat.name === 'SPECIAL';
            }
        }
        return (
            <div className={`Services`} data-testid={`Service_${nbSite}_${nbService}`}>
                <div className={'ctn-header-services'}>
                    <input type="checkbox" className={'checkbox'}
                           defaultChecked={!!servicesCopy.find(s => s.idService === serviceReducer.id)}
                           onChange={this.selectedService} title={"Selectionner le site"}/>

                    <h2>Service {nbService + 1} &nbsp;{siteName && `du site ${siteName}`}</h2>
                </div>
                <div className={'services__form'}>
                    <SelectSearch actionReducer={this.editService} items={categoryReducer} name={'id_categorie'}
                                  placeholder={'Services'} value={serviceReducer && serviceReducer.id_categorie}
                                  ctnName={"input__service"}
                                  className={(serviceReducer && serviceReducer.error) ? serviceReducer.error.id_categorie : ""}
                                  valuesDisplay={"name"} valuesKey={"_id"}
                                  removeIf={serviceReducer ? serviceReducer.id_categorie : ''}
                                  funcRemoveIf={() => this.clearService()} required={true}/>
                    {isSpecial ?
                        <TextField ariaLabel={`service_solution_${nbSite}_${nbService}`} type={'text'}
                                   name={'designation'} placeholder={'Solution'}
                                   value={serviceReducer && serviceReducer.designation}
                                   actionReducer={(name: string, value: string | number | null) => {
                                       this.editService(name, value);
                                       this.editService('solution', value);
                                       this.editService('family', 'SPECIAL');

                                   }} inputType={'input'} ctnName={"solution"}/>
                        :
                        <SelectSearch actionReducer={this.editService} items={servicesFilter} name={'id_service'}
                                      placeholder={'Solution'} value={serviceReducer && serviceReducer.designation}
                                      ctnName={"solution"}
                                      valuesDisplay={'designation'} valuesKey={'id_service'} required={true}/>
                    }
                    <TextField ariaLabel={`service_frn_${nbSite}_${nbService}`} type={'text'} name={'frn'}
                               placeholder={'Fournisseur'} value={serviceReducer && serviceReducer.frn}
                               ctnName={"input__fournisseur"}
                               actionReducer={this.editService} className={"input__fournisseur"}
                               disabled={!isSpecial} inputType={'input'}
                    />

                    {isSpecial &&
                        <>
                            <TextField ariaLabel={`service_pa_abo_${nbSite}_${nbService}`} ctnName={"input__pa__abo"}
                                       type={'number'} name={'pa_abo'}
                                       placeholder={'Prix Achat ABO'} value={serviceReducer && serviceReducer.pa_abo}
                                       disabled={!isSpecial}
                                       actionReducer={this.editService} inputType={'input'}
                                       className={''}
                            />


                            <TextField ariaLabel={`service_pa_fas_${nbSite}_${nbService}`} type={'number'}
                                       name={'pa_fas'}
                                       placeholder={'Prix Achat FAS'} value={serviceReducer && serviceReducer.pa_fas}
                                       disabled={!isSpecial}
                                       actionReducer={this.editService} inputType={'input'}
                                       className={''} ctnName={"input__pa__fas"}
                            />
                        </>

                    }
                    <TextField ariaLabel={`service_describe_solution_${nbSite}_${nbService}`} type={'text'}
                               name={'describe_solution'} placeholder={'Description'} inputType={'textarea'}
                               className={(serviceReducer && serviceReducer.error) ? serviceReducer.error.solution : ""}
                               value={serviceReducer && serviceReducer.describe_solution}
                               actionReducer={this.editService}
                               ctnName={"input__description"}

                    />

                    <TextField ariaLabel={`service_quantity_${nbSite}_${nbService}`} type={'number'}
                               name={'quantity'} className={"input__quantite"} label={"Quantité"}
                               placeholder={(serviceReducer && serviceReducer.quantity) ? `${serviceReducer.quantity}` : 'Quantité'}
                               inputType={'input'} value={serviceReducer && serviceReducer.quantity}
                               actionReducer={this.editService} ctnName={"input__quantite" } required={true}
                    />

                    <TextField ariaLabel={`service_abo_ht_${nbSite}_${nbService}`} type={'number'} name={'abo_ht'}
                               value={serviceReducer.abo_ht }
                               placeholder={this.renderPrice("abo")}
                               actionReducer={this.editService} label={"Prix unitaire ABO"}
                               inputType={'input'} className={'abo-ht-service'}
                               disabled={isSpecial ? !isSpecial : unlockPrice} ctnName={"input__abo__ht"}
                               tooltip={<InfoPrice nbSite={nbSite} nbService={nbService} unit={'abo'}
                                                   ctnName={"info__price__abo"}/>} required={true}
                    />


                    {/*<TextField ariaLabel={`service_duration_${nbSite}_${nbService}`} type={'text'} name={'duration'}*/}
                    {/*           placeholder={'Durée'} value={serviceReducer && serviceReducer.duration}*/}
                    {/*           actionReducer={this.editService} ctnName={"input__duree"}*/}
                    {/*           disabled={special} inputType={'input'}*/}
                    {/*/>*/}
                    <SelectSearch actionReducer={this.editService} items={this.props.durations} name={'duration'}
                                  placeholder={'Durée'} value={serviceReducer && serviceReducer.duration}
                                  ctnName={"input__duree"}
                                  className={(serviceReducer && serviceReducer.error) ? serviceReducer.error.duration : ""}
                                  valuesDisplay={"label"} valuesKey={"id"}/>
                    <TextField ariaLabel={`service_fas_ht_${nbSite}_${nbService}`} type={'number'} name={'fas_ht'}
                               placeholder={this.renderPrice('fas')}
                               value={serviceReducer.fas_ht}
                               actionReducer={this.editService} label={"Prix unitaire FAS"}
                               disabled={isSpecial ? !isSpecial : unlockPrice} inputType={'input'}
                               className={''} ctnName={"input__fas__ht"} required={true}
                               tooltip={<InfoPrice nbSite={nbSite} nbService={nbService} ctnName={"info__price__fas"}
                                                   unit={'fas'}/>}
                    />

                </div>
            </div>
        )
    }
}

export default connector(Services);
