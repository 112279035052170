import * as React from 'react';
import './Billing.scss';
import {connect, ConnectedProps} from "react-redux";
import SelectSearch from "../../Input/SelectSearch/SelectSearch";
import TextField from "../../Input/TextField/TextField";
import {DacCompState} from "../../../views/Dac/Dac";
import {DacState} from "../../../Store/Reducers/Dac/types";
import {editBilling} from "../../../Store/Reducers/Dac/actions";
import {Switch} from "antd";
import AutoCompleteAddress from "../../Input/AutoCompleteAddress/AutoCompleteAddress";
import {Contact} from "../../../utils/Types/Pipedrive";

interface RootState {
    dacReducer: DacState
}

const mapState = (state: RootState) => ({
    contact_name_reducer: state.dacReducer.billing.contact_name,
    contact_tel_reducer: state.dacReducer.billing.contact_tel,
    contact_mail_reducer: state.dacReducer.billing.contact_mail,
    contact_address_reducer: state.dacReducer.billing.address,
    contract_reducer: state.dacReducer.billing.contract,
    error_reducer: state.dacReducer.billing.error
});

const mapDispatch = {
    editBilling: (name: string, value: string | number | boolean) => editBilling(name, value),
};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
    dacState: DacCompState

}

interface BillingCompState {
    contact: Contact,
}

class Billing extends React.Component<Props, BillingCompState> {

    constructor(props: any) {
        super(props);
        this.state = {
            contact: {
                id: 0,
                name: '',
                first_name: '',
                last_name: '',
                phone: [],
                email: [],
            },
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<BillingCompState>, snapshot?: any) {
        if (prevProps.contact_name_reducer !== this.props.contact_name_reducer) {
            const contacts = this.props.dacState.contacts.filter(contact => contact.name === this.props.contact_name_reducer);
            if (contacts.length > 0) {
                this.setState({contact: contacts[0]});
                this.props.editBilling('contact_mail', contacts[0].email[0].value);
            }
        }
    }

    changeMandatory = (e: boolean) => {
        this.props.editBilling("contract", e);
    };

    render() {
        const {contact_address_reducer, contact_mail_reducer, contract_reducer, error_reducer, contact_name_reducer, contact_tel_reducer, dacState, editBilling} = this.props;
        const {contacts} = dacState;
        const {contact} = this.state;
        const contactsTelList = contact.phone.length > 0 ? contact.phone.filter(data => data.value) : [];
        // const contactsNameList = contacts.length > 0 ? contacts.filter(data => data.name.toLocaleLowerCase().includes(billingReducer.contact_name.toLocaleLowerCase())) : [];
        console.log(contacts)
        return (
            <div id={'Billing'} data-testid={'Billing'}>
                <div className={"ctn__title"}>
                    <h2>Référence facturation</h2>
                    <Switch
                        onChange={this.changeMandatory}
                        className={"mt-auto mb-auto"}
                        checkedChildren={"Contrat"}
                        unCheckedChildren={"Devis"}
                    />
                </div>
                <div className={'dac__form'}>
                    <SelectSearch value={contact_name_reducer}
                                  name={'contact_name'}
                                  placeholder={'Contact comptabilité'}
                                  mandatory={contract_reducer}
                                  actionReducer={editBilling} unblocked={true}
                                  className={error_reducer && error_reducer.contact_name}
                                  items={contacts} valuesDisplay={'name'} valuesKey={'name'}
                    />

                    <SelectSearch value={contact_tel_reducer}
                                  name={'contact_tel'} unblocked={true}
                                  placeholder={'Tel du contact'}
                                  mandatory={contract_reducer}
                                  actionReducer={editBilling}
                                  className={error_reducer && error_reducer.contact_tel}
                                  items={contactsTelList} valuesKey={'value'} valuesDisplay={'value'}
                    />
                    <TextField type={'text'}
                               ariaLabel={'billing_contact_mail'}
                               name={'contact_mail'}
                               placeholder={'Mail du contact'}
                               inputType={'input'}
                               mandatory={contract_reducer}
                               value={contact_mail_reducer}
                               actionReducer={editBilling}
                               disabled={true}
                               className={error_reducer && error_reducer.contact_mail}
                    />
                    <AutoCompleteAddress type={'text'}
                               ariaLabel={'billing_contact_address'}
                               name={'address'}
                               placeholder={'Adresse'}
                               mandatory={contract_reducer}
                               inputType={'input'}
                               value={contact_address_reducer}
                               actionReducer={editBilling}
                               disabled={false}
                               className={error_reducer && error_reducer.address}
                    />
                    {/*<AutoCompleteAddress value={contact_address_reducer} placeholder={'Adresse'} change={editBilling} name={'address'} />*/}
                </div>
            </div>
        )
    }
}


export default connector(Billing)
