import {ActionsDacTypes} from "./types";

export function editActionsDac(name: string, value: Array<{idSite: string, idService: string} | string>): ActionsDacTypes {
    return {
        type: 'EDIT_ACTION_DAC',
        name: name,
        value: value,
    }
}

