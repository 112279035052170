import {EDIT_TOTAL, RECEIVE_TOTAL ,TotalActionTypes, TotalState} from "./types";
import {totalState} from "./state";


const initialState: TotalState = totalState;

export default function totalReducer(state = initialState, action: TotalActionTypes): TotalState  {
    switch(action.type) {
        case EDIT_TOTAL:
            return {
                ...state,
                [action.name]: action.value
            };
        case RECEIVE_TOTAL:
            return action.value;
        default:
            return state;
    }
}
