import {DacState, ServicesState} from "../Dac/types";

export const UPDATE_COLLECTIONS = 'UPDATE_COLLECTIONS';
export const INIT_COLLECTIONS = 'INIT_COLLECTIONS';

export interface CategoryState {
    _id: string,
    name: string
    id_family: string,
    orders?: Array<DacState>
    family? : string
    abo_ht? : number
    fas_ht? : number
    ids_orders? : Array<string>
}

interface FamilyState {
    categories: CategoryState[];
    _id: string,
    name: string,
}

export interface ToolitLogsState {
    title: string,
    message: string,
    details: string,
    createdAt: string
}
export interface CollectionsState {
    Clause: Array<{_id: string, title: string, describe_clauses: string}>,
    Order: Array<DacState>,
    Family: Array<FamilyState>,
    Service: Array<ServicesState>,
    Category: Array<CategoryState>
    ToolitLogs: Array<ToolitLogsState>,

}

interface UpdateCollections {
    type: typeof UPDATE_COLLECTIONS,
    collection: string
    value: any
}

interface InitCollections {
    type: typeof INIT_COLLECTIONS,
    value: any
}

export type CollectionsActionTypes = UpdateCollections | InitCollections
