import {ActionsDacState, ActionsDacTypes, EDIT_ACTION_DAC} from "./types";
import {actionsDacState} from "./state";


const initialState: ActionsDacState = actionsDacState;

export default function ActionsDacReducer(state = initialState, action: ActionsDacTypes): ActionsDacState  {
    switch(action.type) {
        case EDIT_ACTION_DAC:
            return {
                ...state,
                [action.name]: action.value
            };
        default:
            return state;
    }
}
