import React, {Component} from 'react';

class IconDevis extends Component {
    render() {
        return (
            <svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                 viewBox="0 0 290.4 415.3"  space="preserve">
<path d="M282.9,0H7.5C3.4,0,0,3.4,0,7.5v400.3c0,4.1,3.4,7.5,7.5,7.5h275.4c4.1,0,7.5-3.4,7.5-7.5V7.5C290.4,3.4,287.1,0,282.9,0z
	 M171.2,224.8H51.1c-4.1,0-7.5-3.4-7.5-7.5c0-4.1,3.4-7.5,7.5-7.5h120.1c4.1,0,7.5,3.4,7.5,7.5C178.7,221.5,175.4,224.8,171.2,224.8
	z M239.3,178.5H51.1c-4.1,0-7.5-3.4-7.5-7.5c0-4.1,3.4-7.5,7.5-7.5h188.2c4.1,0,7.5,3.4,7.5,7.5
	C246.8,175.1,243.5,178.5,239.3,178.5z M239.3,132.1H51.1c-4.1,0-7.5-3.4-7.5-7.5c0-4.1,3.4-7.5,7.5-7.5h188.2
	c4.1,0,7.5,3.4,7.5,7.5C246.8,128.7,243.5,132.1,239.3,132.1z M239.3,85.7H51.1c-4.1,0-7.5-3.4-7.5-7.5c0-4.1,3.4-7.5,7.5-7.5h188.2
	c4.1,0,7.5,3.4,7.5,7.5C246.8,82.4,243.5,85.7,239.3,85.7z"/>
</svg>
        );
    }
}

export default IconDevis;