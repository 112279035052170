import * as React from 'react';
import './TextField.scss';
import {faLock,} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button, Input, Tooltip} from 'antd';
import IconMandatory from '../../../assets/mandatory.png';
import {debounce} from "throttle-debounce";

interface Props {
    ariaLabel: string,
    type: string,
    name: string,
    placeholder: string,
    value?: string | number | null,
    actionReducer: Function,
    disabled?: boolean,
    mandatory?: boolean,
    inputType: string,
    className?: string,
    ctnName?: string,
    label?: string
    tooltip?: JSX.Element
    required?: boolean
}

interface State {
    name: string
    q: string,
}

class TextField extends React.Component<Props, State> {
    private autocompleteSearchDebounced: Function;


    constructor(props: any) {
        super(props);
        this.state = {
            name: '',
            q: '',
        }
        this.autocompleteSearchDebounced = debounce(500, this.autocompleteSearch);
    }

    handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const {disabled} = this.props;
        if (!disabled) {
            const {name, value} = e.target;
            this.props.actionReducer(name, value)
        }
    }

    autocompleteSearch = (q: string) => {
        const {disabled} = this.props;
        if (!disabled) {
            this.props.actionReducer(this.state.name, q);
        }
    };

    componentDidMount() {
        if (this.props.value) {
            //@ts-ignore
            this.setState(prevState => ({...prevState, q: this.props.value}));
        }
        if (this.props.value == '0') {
            //@ts-ignore
            this.setState(prevState => ({...prevState, q: this.props.value}));
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {

        if (prevProps.value !== this.props.value && this.props.value) {
            //@ts-ignore
            this.setState(prevState => ({...prevState, q: this.props.value}));
        }
        if (prevProps.value !== this.props.value &&  this.props.value == '0') {
            //@ts-ignore
            this.setState(prevState => ({...prevState, q: this.props.value}));
        }

        if (prevProps.value !== this.props.value &&  this.props.value == '') {
            //@ts-ignore
            this.setState(prevState => ({...prevState, q: this.props.value}));
        }
    }


    changeQuery = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState(prevState => ({name: event.target.name, q: event.target.value}), () => {
            this.autocompleteSearchDebounced(this.state.q);
        });
    }

    rend = () => {
        const {
            ariaLabel,
            name,
            placeholder,
            type,
            disabled,
            className,
            mandatory,
            ctnName,
            inputType,
            label,
            tooltip,
            required
        } = this.props;
        let fieldProps = {};
        if(required) fieldProps = {...fieldProps, 'data-required': `${mandatory || required}`};
        return (
            <div
                className={`ctn__field ${ctnName ? ctnName : ""} ${inputType === "textarea" ? "ctn__field__textarea" : ""} `}>
                <label htmlFor="">{label ? label : placeholder}</label>
                <div className={'ctn-text-field'}>
                    {mandatory &&
                    <Tooltip placement="topLeft" title={'* Champs obligatoire'}>
                        <img src={IconMandatory} className={'icon-mandatory'} alt={'mandatory-icon'}/>
                    </Tooltip>
                    }
                    {disabled &&
                    <FontAwesomeIcon className={'icon-lock'} icon={faLock}/>
                    }
                    {inputType === 'input' ?
                        <Input className={disabled ? `disabled ${className}` : className} aria-label={ariaLabel}
                               type={type} name={name}
                               placeholder={placeholder} data-required={mandatory || required}
                               value={this.state.q} onChange={this.changeQuery} step={'any'} disabled={disabled} />
                        :
                        <Input.TextArea className={disabled ? 'textarea disabled ' : 'textarea'} aria-label={ariaLabel}
                                        name={name}
                                        placeholder={placeholder}
                                        value={this.state.q} onChange={this.changeQuery} disabled={disabled} data-required={mandatory || required}/>
                    }
                </div>
            </div>
        )
    }

    render() {
        if(this.props.tooltip) {
            return <Tooltip placement="bottom" title={this.props.tooltip}>
                {this.rend()}
            </Tooltip>
        } else {
            return this.rend()
        }
    }
}

export default TextField;
