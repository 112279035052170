import * as React from 'react';
import {CollectionsState} from "../../Store/Reducers/Collections/types";
import {connect, ConnectedProps} from "react-redux";
import Socket from "../../utils/Socket/Socket";

interface RootState {
    collectionsReducer: CollectionsState,
}

const mapState = (state: RootState) => ({
    logs: state.collectionsReducer.ToolitLogs
});


const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
}

interface State {
}

class DesktopNotification extends React.Component<Props, State> {

    componentDidMount(): void {
        if(!("Notification" in window)) {
            console.error("This browser does not support desktop notification");
        } else {
            Notification.requestPermission();
        }
    }


    showNotif = () => {
        Socket.receiveNotif()
            .then(res => {
                let options = {
                    body: res.message,
                    icon: `https://byos-dev.sayse.fr:4243/img/logo_sayse_blue.png`
                };
                let notification = new Notification(res.title, options);
                notification.close();
            })
    }

    render() {
        this.showNotif();
        return(
            <div>
            </div>
        )
    }
}

export default connector(DesktopNotification);
