import {TotalActionTypes, TotalState} from "./types";

export function editTotal(name: string, value: number): TotalActionTypes {
    return {
        type: 'EDIT_TOTAL',
        name: name,
        value: value,
    }
}

export function receiveTotal(value: TotalState): TotalActionTypes {
    return {
        type: 'RECEIVE_TOTAL',
        value: value,
    }
}

export function addTotal(name: string, value: number): TotalActionTypes {
    return {
        type: 'ADD_TOTAL',
        name: name,
        value: value
    }
}

export function editTxTotal(name: string, value: number, nbService: number): TotalActionTypes {
    return {
        type: 'EDIT_TX_TOTAL',
        name: name,
        value: value,
        nbService: nbService
    }
}
