import * as React from 'react';
import './Accueil.scss';


class Accueil extends React.Component {


    render() {
        return(
            <div id={"Accueil"} className={'d-flex w-100'}>
                <div className="title">
                    <h1>Business Dashboard</h1>
                </div>

                <div className="geck">
                <iframe
                    className={'gb-dashboard-iframe d-flex w-100'}
                    id="gb-dashboard-iframe"
                    src="https://app.geckoboard.com/v5/dashboards/PAZ7YF7Q5HHKC4AY/inception"
                />
                </div>
            </div>

        )
    }
}

export default Accueil;
