import * as React from 'react';
import {CollectionsState} from "../../Store/Reducers/Collections/types";
import {connect, ConnectedProps} from "react-redux";
import "./Historique.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUsers} from "@fortawesome/free-solid-svg-icons";
import {Tabs} from "antd";
import HistoriqueTable from "../../Components/Historique/HistoriqueTable/HistoriqueTable";
import Socket from "../../utils/Socket/Socket";

const {TabPane} = Tabs;
const tabs = [
    {name: "En cours", tag: "open"},
    {name: "Signe", tag: "won"},
    {name: "Perdu", tag: "lost"},
]
interface RootState {
    collectionsReducer: CollectionsState,
}

const mapState = (state: RootState) => ({
    orders: state.collectionsReducer.Order
});

const mapDispatch = {};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {

}

interface HistoriqueCompState {
    displaySearch: boolean,
    status: string
}


class Historique extends React.Component<Props, HistoriqueCompState> {


    constructor(props: any) {
        super(props);
        this.state = {
            displaySearch: false,
            status: "open"
        }
    }

    changeStatus() {
        // orders.forEach(order => {
        //     axios.get(`https://sayse.pipedrive.com/v1/deals/${order.society.id_pipedrive}?api_token=d168467b213a71acdc4ba5cb5b2cfe96529ac061&limit=500`)
        //         .then(res => {
        //             if (res.status === 200) {
        //                 if (`${res.data.data.status}` !== `${order.tag}`) {
        //                     const tag = res.data.data.status;
        //                     Meteor.call('orders.updateTag', order._id, tag);
        //                 }
        //             }
        //         })
        // });
    }

    handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        // @ts-ignore
        this.setState({[name]: value});
    };

    render() {
        const {orders} = this.props;
        return (
            <div id={"Historique"}>
                <div className={"title_page"}>
                    <FontAwesomeIcon icon={faUsers}/>.
                    <h2>Historique</h2>
                </div>
                <Tabs className={"tabs"} defaultActiveKey={tabs[0].name}>
                    {tabs.map(tab => (
                    <TabPane tab={<span>{tab.name}</span>} key={tab.name}>
                        <HistoriqueTable data={orders.filter(order => order.tag === tab.tag).reverse()}/>
                    </TabPane>
                    ))}
                </Tabs>
            </div>
        )

    }
}

export default connector(Historique);
