import React, {Component} from 'react';

class IconAdd extends Component {
    render() {
        return (
            <svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                 viewBox="0 0 278.7 278.7" space="preserve">
<path d="M139.3,0C62.4,0,0,62.4,0,139.3c0,77,62.4,139.3,139.3,139.3s139.3-62.4,139.3-139.3C278.7,62.4,216.3,0,139.3,0z
	 M214.1,147.3c-2,2-4.8,3.2-7.9,3.2h-55.8v55.8c0,3.1-1.2,5.9-3.2,7.9s-4.8,3.2-7.9,3.2c-6.1,0-11.2-5-11.2-11.2v-55.7H72.4
	c-6.1,0-11.2-5-11.2-11.2s5-11.2,11.2-11.2l55.8,0V72.5c0-6.1,5-11.2,11.2-11.2c6.2,0,11.2,4.9,11.2,11.1v55.8l55.8,0
	c6.1,0,11.2,5,11.2,11.2C217.4,142.5,216.1,145.3,214.1,147.3z"/>
</svg>

        );
    }
}

export default IconAdd;