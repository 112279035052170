import * as React from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import {Input, Tooltip} from "antd";
import IconMandatory from "../../../assets/mandatory.png";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLock} from "@fortawesome/free-solid-svg-icons";
import './AutoCompleteAddress.scss';
import {nanoid} from "nanoid";
import {debounce, throttle} from "throttle-debounce";

interface Props {
    // value: string,
    // placeholder: string,
    // change: Function,
    // name: string,
    title?: string,
    // errorCSS?: string
    ariaLabel: string,
    type: string,
    name: string,
    placeholder: string,
    value?: string | number,
    actionReducer: Function,
    disabled?: boolean,
    mandatory?: boolean,
    inputType: string,
    className?: string,
    ctnName?: string,
    label?: string,
    defaultValue?: string
}

// typingTimeout: setTimeout(() => {
//     this.props.actionReducer(this.props.name, this.state.address);
// }, 1000)

interface State {
    address: string
}

class AutoCompleteAddress extends React.Component<Props, State> {
    private autocompleteSearchThrottle: throttle<(q: string) => void>;

    constructor(props: any) {
        super(props);
        this.state = {
            address: ""
        }
        this.autocompleteSearchThrottle = throttle(500, this.autocompleteSearch);
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if(prevProps.value !== this.props.value && this.props.value !== "") {
            //@ts-ignore
            this.setState(() => ({address: this.props.value ? this.props.value : ""}))
        }
    }

    autocompleteSearch = (q: string) => {
        const {disabled} = this.props;
        if (!disabled) {
            this.props.actionReducer(this.props.name, q);
        }
    };

    onChange = (address: string) => {
        this.setState(prevState => ({address}), () => {
            this.autocompleteSearchThrottle(this.state.address);
        });
    }

    render() {
        const {
            placeholder,
            title,
            className,
            mandatory,
            disabled,
            ctnName,
            label,
            defaultValue,
            value,
            inputType
        } = this.props;
        return (
            <PlacesAutocomplete
                value={this.state.address}
                onChange={this.onChange}
                onSelect={this.onChange}
                searchOptions={{componentRestrictions: {country: ['fr']}}}
            >
                {({getInputProps, suggestions, getSuggestionItemProps, loading}) => (
                    <div
                        className={`AutoCompleteAddress ctn__field ${ctnName ? ctnName : ""} ${inputType === "textarea" ? "ctn__field__textarea" : ""} `}>
                        <label htmlFor="">{label ? label : placeholder}</label>
                        <div className={'ctn-text-field'}>
                            {mandatory &&
                            <Tooltip placement="topLeft" title={'* Champs obligatoire'}>
                                <img src={IconMandatory} className={'icon-mandatory'} alt={'mandatory-icon'}/>
                            </Tooltip>
                            }
                            {disabled &&
                            <FontAwesomeIcon className={'icon-lock'} icon={faLock}/>
                            }
                            <Input
                                {...getInputProps({
                                    placeholder: placeholder,
                                    className: `w-100 ${className}`,
                                    title: title,
                                })}
                                value={this.state.address ? this.state.address : this.props.value}
                                data-required={mandatory}
                                data-value={this.state.address}
                            />
                            <div className="autocomplete-dropdown-container position-absolute">
                                {loading && <div>Loading...</div>}
                                {suggestions.map(suggestion => {
                                    const className = suggestion.active
                                        ? 'suggestion-item--active'
                                        : 'suggestion-item ';
                                    const style = suggestion.active
                                        ? {backgroundColor: '#cfe6fb', padding: '10px', cursor: 'pointer'}
                                        : {backgroundColor: '#ffffff', padding: '10px', cursor: 'pointer'};
                                    return (
                                        <div
                                            {...getSuggestionItemProps(suggestion, {
                                                className,
                                                style,
                                            })}
                                            key={nanoid()}
                                        >
                                            <span>{suggestion.description}</span>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                )}

            </PlacesAutocomplete>
        )
    }
}

export default AutoCompleteAddress;