import {
    ADD_INFO_DEAL,
    ADD_SERVICE,
    ADD_SITE,
    DacActionTypes,
    DacState,
    DUPLICATE_SITES,
    EDIT_BILLING,
    EDIT_DAC,
    EDIT_ERROR,
    EDIT_FINAL_TOTAL,
    EDIT_SERVICE_INPUT,
    INITIAL_SERVICE,
    EDIT_SERVICE_OBJECT,
    EDIT_SITES,
    EDIT_SOCIETY,
    GET_DAC,
    INIT_DAC,
    PASTE_SERVICE,
    REMOVE_INFO_CLIENT,
    REMOVE_SERVICES,
    REMOVE_SITES,
    SET_DAC
} from "./types";
import {v4 as uuidv4} from 'uuid';

import {dacState} from "./state";

function generateId(ids: any) {
    let newId = uuidv4();
    if(ids && ids.length > 0) {
        while (ids.includes(newId)) {
            newId = uuidv4();
        }
    }
    return newId;
}

const initialState: DacState = dacState;

export default function dacReducer(state = initialState, action: DacActionTypes): DacState {
    switch (action.type) {
        case SET_DAC:
            return action.value
        case GET_DAC:
            return action.quote;
        case EDIT_DAC:
            return {
                ...state,
                [action.name]: action.value,
            };
        case EDIT_BILLING:
            return {
                ...state,
                billing: {
                    ...state.billing,
                    [action.name]: action.value,
                }
            };
        case EDIT_SOCIETY:
            return {
                ...state,
                society: {
                    ...state.society,
                    [action.name]: action.value,
                }
            };
        case EDIT_ERROR:
            const newEditErr = {...state};
            if (action.section === 'sites') {
                if (action.nbSite || action.nbSite === 0) {
                    newEditErr.sites[action.nbSite] = {
                        ...newEditErr.sites[action.nbSite],
                        error: {...newEditErr.sites[action.nbSite].error, [action.key]: action.value}
                    }
                }
            } else if (action.section === 'services') {
                if (action.nbSite && (action.nbService || action.nbSite === 0 || action.nbService === 0)) {
                    //@ts-ignore
                    newEditErr.sites[action.nbSite].services[action.nbService] = {...newEditErr.sites[action.nbSite].services[action.nbService], error: {...newEditErr.sites[action.nbSite].services[action.nbService].error,[action.key]: action.value}
                    }
                }
            } else {
                // @ts-ignore
                newEditErr[action.section] = {...newEditErr[action.section], error: {...newEditErr[action.section].error, [action.key]: action.value}
                }
            }
            return newEditErr;
        case REMOVE_INFO_CLIENT:
            return {
                ...state,
                society: {
                    ...state.society,
                    society: '',
                    address: '',
                    title: '',
                    org_id: 0,
                    signatory: '',
                    contact_name: '',
                    contact_email: '',
                },
                billing: {
                    ...state.billing,
                    contact_name: '',
                    contact_tel: '',
                    contact_mail: '',
                    address: '',
                },
                sites: state.sites.map(site => {
                    return {
                        ...site,
                        contact_name: '',
                        contact_tel: '',
                        contact_mail: '',
                    }
                })
            }
        case ADD_INFO_DEAL:
            return {
                ...state,
                society: {
                    ...state.society,
                    society: action.deal.org_id.name,
                    address: action.deal.org_id.address,
                    title: action.deal.title,
                    org_id: action.deal.org_id.value
                }
            };

        case EDIT_SITES:
            return {
                ...state,
                sites: state.sites.map((site, index) => {
                    return index === action.nbSite ? {
                        ...site,
                        [action.name]: action.value
                    } : site
                })
            };
        case DUPLICATE_SITES:
            return {
                ...state,
                sites: [...state.sites, ...action.sites.map((site, index) => (
                    {
                        ...site,
                        _id: generateId(state.sites.flatMap(s => s._id)),
                        services: site.services.map(s => ({...s, id: generateId(state.sites.flatMap(s => s.services).flatMap(serv => serv.id))}))
                    })
                )]
            }
        case REMOVE_SITES:
            return {
                ...state,
                sites: state.sites.filter(s => !action.ids.includes(s._id))
            }
        case EDIT_SERVICE_OBJECT:
            return {
                ...state,
                sites: state.sites.map((site, index) => {
                    return index === action.nbSite ? {
                        ...site,
                        services: site.services.map((service, index) => {
                            return index === action.nbService ?
                                {
                                    ...service,
                                    ...action.value
                                }
                                : service
                        })
                    } : site
                })
            };
        case EDIT_SERVICE_INPUT:

            return {
                ...state,
                sites: state.sites.map((site, index) => {
                    return index === action.nbSite ? {
                        ...site,
                        services: site.services.map((service, index) => {
                            return index === action.nbService ?
                                {
                                    ...service,
                                    [action.name]: action.value
                                }
                                : service
                        })
                    } : site
                })
            };
        case PASTE_SERVICE:
            return {
                ...state,
                sites: state.sites.map((site, iSite) => {
                    if (action.sites.includes(site._id)) {
                        return {
                            ...site,
                            services: [...site.services, ...action.services.map(s => ({...s, id: generateId(state.sites.flatMap(s => s.services).flatMap(serv => serv.id))}))]
                        }
                    } else {
                        return site;
                    }
                })
            }
        case REMOVE_SERVICES:
            const sitesIds = action.ids.flatMap(i => i.idSite);
            const servicesIds = action.ids.flatMap(i => i.idService);
            return {
                ...state,
                sites: state.sites.map((site, iSite) => {
                    if (sitesIds.includes(site._id)) {
                        return {
                            ...site,
                            services: site.services.filter(service => !servicesIds.includes(service.id as string))
                        }
                    } else {
                        return site;
                    }
                })
            }
        case INITIAL_SERVICE:
            const initialService = {
                id: generateId(state.sites.flatMap(s => s.services).flatMap(serv => serv.id)),
                _id: '',
                id_service: '',
                id_categorie: '',
                family: '',
                designation: '',
                solution: '',
                describe_solution: '',
                duration: '',
                reference: '',
                frn: '',
                number: '',
                abo_ht: 0,
                pa_abo: 0,
                fas_ht: 0,
                pa_fas: 0,
                mge_abo: 0,
                tx_abo: 0,
                mge_fas: 0,
                tx_fas: 0,
                quantity: 0,
                p_router: 0,
                error: {
                    id_categorie: '',
                    solution: ''
                }
            };
            return {
                ...state,
                sites: state.sites.map((site, index) => {
                    if (index === action.nbSite) {
                        return {
                            ...site, services: site.services.map((service, i) => {
                                if (i === action.nbService) {
                                    return initialService;
                                } else {
                                    return service;
                                }
                            })
                        }
                    } else {
                        return site;
                    }
                })
            };
        case ADD_SITE:
            const site = {
                _id: generateId(state.sites.flatMap(s => s._id)),
                site_name: '',
                contact_name: '',
                contact_tel: '',
                contact_mail: '',
                delivery_address: '',
                services: [{
                    id: generateId(state.sites.flatMap(s => s.services).flatMap(serv => serv.id)),
                    _id: '',
                    id_service: '',
                    id_categorie: '',
                    family: '',
                    designation: '',
                    solution: '',
                    describe_solution: '',
                    duration: '',
                    reference: '',
                    frn: '',
                    number: '',
                    abo_ht: 0,
                    pa_abo: 0,
                    fas_ht: 0,
                    pa_fas: 0,
                    mge_abo: 0,
                    tx_abo: 0,
                    mge_fas: 0,
                    tx_fas: 0,
                    quantity: 0,
                    p_router: 0,
                    error: {
                        id_categorie: '',
                        solution: ''
                    }
                }],
                siret: '',
                abo_ht: 0,
                pa_abo: 0,
                fas_ht: 0,
                pa_fas: 0,
                mge_abo: 0,
                tx_abo: 0,
                mge_fas: 0,
                tx_fas: 0,
                quantity: 0,
                error: {
                    site_name: '',
                    delivery_address: '',
                    contact_mail: '',
                    contact_tel: '',
                    contact_name: ''
                }
            };
            return {
                ...state,
                sites: [...state.sites, site]
            }
        case ADD_SERVICE:
            const service = {
                id: generateId(state.sites.flatMap(s => s.services).flatMap(serv => serv.id)),
                _id: '',
                id_service: '',
                id_categorie: '',
                family: '',
                designation: '',
                solution: '',
                describe_solution: '',
                duration: '',
                reference: '',
                frn: '',
                number: '',
                abo_ht: 0,
                pa_abo: 0,
                fas_ht: 0,
                pa_fas: 0,
                mge_abo: 0,
                tx_abo: 0,
                mge_fas: 0,
                tx_fas: 0,
                quantity: 0,
                p_router: 0,
                error: {
                    id_categorie: '',
                    solution: ''
                }
            };
            return {
                ...state,
                sites: state.sites.map((site, index) => {
                    if(action.idSites.length > 0) {
                        if (action.idSites.includes(site._id)) {
                            return {...site, services: [...site.services, service]}
                        } else {
                            return site;
                        }
                    } else {
                        if(index === state.sites.length - 1) {
                            return {...site, services: [...site.services, service]}
                        } else {
                            return site;
                        }
                    }
                })
            };
        case EDIT_FINAL_TOTAL:
            return {
                ...state,
                society: {
                    ...state.society,
                    [action.key]: action.value
                }
            };
        case INIT_DAC:
            return dacState;
        default:
            return state;
    }
}
