import {CollectionsActionTypes} from "./types";

export function udpateCollections(collection: string, value: any): CollectionsActionTypes {
    return {
        type: 'UPDATE_COLLECTIONS',
        collection: collection,
        value: value
    }
}

export function initCollections(value: any): CollectionsActionTypes {
    return {
        type: 'INIT_COLLECTIONS',
        value: value
    }
}
