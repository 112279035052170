export const totalState = {
    id_service: "",
    abo_ht: 0,
    pa_abo: 0,
    fas_ht: 0,
    pa_fas: 0,
    mge_abo: 0,
    tx_abo: 0,
    mge_fas: 0,
    tx_fas: 0,
    discount_abo: 0,
    discount_fas: 0,
};
