import React from 'react';
import './ActionHistorique.scss';
import IconTrello from "./IconTrello/IconTrello";
import IconEdit from "./IconEdit/IconEdit";
import {DacState} from "../../../Store/Reducers/Dac/types";
import {connect, ConnectedProps} from "react-redux";
import {getDac} from "../../../Store/Reducers/Dac/actions";
import {withRouter} from "react-router-dom";
import {RouteComponentProps} from "react-router";
import PDFButton from '../../Dac/PDF/PDFButton';
import Socket from "../../../utils/Socket/Socket";
import {CollectionsState} from "../../../Store/Reducers/Collections/types";
import Corbeille from "../../IconSVG/Corbeille/Corbeille";
import Trello from "../../../utils/Trello";
import Pipedrive from "../../../utils/Pipedrive/Pipedrive";
import axios from "axios";

interface RootState {
    collectionsReducer: CollectionsState
}

const mapState = (state: RootState) => ({
    categoryReducer: state.collectionsReducer.Category
})

const mapDispatch = (dispatch: Function) => ({
    editDac: (quote: DacState) => dispatch(getDac(quote))
})

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector> & RouteComponentProps<any>

interface Props extends PropsFromRedux {
    selectedItems: Array<string>,
    orders: Array<DacState>,
    deselectAll: Function
}

interface State {

}

class ActionHistorique extends React.Component<Props, State> {


    onEdit = () => {
        const {selectedItems, orders} = this.props;
        const order = orders.find(o => o._id === selectedItems[0]);
        if (order) {
            const dac = {...order,  countServices: order.sites.flatMap(s => s.services).flatMap(s => s.quantity ? s.quantity : 0).reduce((a, c) => a + c)};
            this.props.editDac(dac);
            localStorage.setItem('dac', JSON.stringify(dac))
            this.props.history.push('/Devis')
        }

    }

    onDuplicate = () => {
        const {selectedItems, orders} = this.props;
        let order = orders.find(o => o._id === selectedItems[0]);
        if (order) {
            const dac = {...order, _id: '', countServices: order.sites.flatMap(s => s.services).flatMap(s => s.quantity).reduce((a, c) => a + c) ,society: {...order.society, id_order: ''}};
            this.props.editDac(dac);
            localStorage.setItem('dac', JSON.stringify(dac))
            this.props.history.push('/Devis')
        }

    }

    onDelete = () => {
        Socket.deleteMany("Order", null, this.props.selectedItems);
        this.props.deselectAll();
    }

    onCreateTrello = () => {
        const {orders, selectedItems} = this.props;
        //@ts-ignore
        const selectedOrders = orders.filter(o => selectedItems.includes(o._id));

        selectedOrders.forEach(order => {
            Pipedrive.updateDeal(order.society.id_pipedrive)
                .then(res => {
                    Socket.update('Order', order._id, '', {...order, tag: 'won'})
                        Trello.getTrello(order).then(r => {})

                })
                .catch(err => {
                    console.error(err)
                })
        })

    }

    uploadFile = async (filename: string) => {
        const link = document.createElement('a');
        console.log(process.env.REACT_APP_API_DAC + `/excel/orders/download`)
        link.href = process.env.REACT_APP_API_DAC + `/excel/orders/download/` + filename;
        link.setAttribute('download', 'catalogue.xlsx');
        document.body.appendChild(link);
        link.click();
        link.remove();
    };

    test = () => {
        axios.post(`${process.env.REACT_APP_API_DAC}/excel/orders`, {ids: this.props.selectedItems})
            .then((response) => {
                console.log(response)
                this.uploadFile(response.data)
            });
    }
    render() {
        const {orders, selectedItems} = this.props;
        return (
            <div id={"ActionHistorique"}>
                <button onClick={this.onCreateTrello}>
                    <IconTrello/>
                    <span>Checklist Trello</span>
                </button>
                <button onClick={this.test}>Excel commande</button>
                {selectedItems.length === 1 &&
                <button onMouseUp={this.onEdit}>
                    <IconEdit/>
                    <span>Editer</span>
                </button>
                }
                {selectedItems.length === 1 &&
                <button onMouseUp={this.onDuplicate}>
                    <IconEdit/>
                    <span>Dupliquer</span>
                </button>
                }
                {selectedItems.length === 1 &&
                <PDFButton dataDac={orders.find(o => o._id === selectedItems[0])}/>
                }
                {selectedItems.length === 1 &&
                <PDFButton dataDac={orders.find(o => o._id === selectedItems[0])} type={"Facture"}/>
                }
                <button onMouseUp={this.onDelete}>
                    <Corbeille/>
                    <span>Supprimer</span>
                </button>
            </div>
        )
    }
}

export default connector(withRouter(ActionHistorique));