import * as React from 'react';
import './ActionsCatalogue.scss';
import {ServicesState} from "../../../../Store/Reducers/Dac/types";
import {Drawer} from "antd";
import EditService from "./EditService/EditService";
import Socket from "../../../../utils/Socket/Socket";
import ExcelInput from "../../ExcelInput/ExcelInput";

interface Props {
    selectedItems: Array<string>,
    selectedServices: Array<ServicesState>,
    deselectAll: Function
}

interface State {
    visibleUpdate: boolean,
    visibleInsert: boolean
}

class ActionsCatalogue extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            visibleUpdate: false,
            visibleInsert: false
        }
    }

    onClose = (name: string) => {
        //@ts-ignore
        this.setState({[`${name}`]: false});
    }

    showDrawer = (name: string) => {
        //@ts-ignore
        this.setState({[`${name}`]: true});
    }

    delete = () => {
        const {selectedItems} = this.props;
        Socket.deleteMany('Service', null, selectedItems);
        this.props.deselectAll();
    }

    render() {
        const {selectedItems, selectedServices} = this.props;
        return (
            <>
                <div id={"ActionsCatalogue"}>
                    <ExcelInput/>
                    <>
                        <button onClick={() => this.showDrawer('visibleInsert')}>
                            <span>Ajouter</span>
                        </button>
                        <Drawer forceRender={true} width={'35%'} placement={"right"} title={'Ajouter un service'} closable={false}
                                onClose={() => this.onClose('visibleInsert')} visible={this.state.visibleInsert}>
                            <EditService visibleInsert={this.state.visibleInsert}  onClose={() => this.onClose('visibleInsert')}/>
                        </Drawer>
                    </>
                    {(selectedItems.length > 0 && selectedItems.length < 2) &&
                    <>
                        <button onClick={() => this.showDrawer('visibleUpdate')}>
                            <span>Modifier</span>
                        </button>
                        <Drawer width={'35%'} forceRender={true} placement={"right"} title={'Modifier'} closable={false}
                                onClose={() => this.onClose('visibleUpdate')} visible={this.state.visibleUpdate}>
                            <EditService update={true}  onClose={() => this.onClose('visibleUpdate')} visibleUpdate={this.state.visibleUpdate} serviceProps={selectedServices[0]}/>
                        </Drawer>
                    </>
                    }
                    {(selectedItems.length > 0 && selectedItems.length < 2) &&
                    <>
                        <button onClick={() => this.showDrawer('visibleUpdate')}>
                            <span>Dupliquer</span>
                        </button>
                        <Drawer width={'35%'} forceRender={true} placement={"right"} title={'Modifier'} closable={false}
                                onClose={() => this.onClose('visibleUpdate')} visible={this.state.visibleUpdate}>
                            <EditService duplicate={true}  onClose={() => this.onClose('visibleUpdate')} visibleUpdate={this.state.visibleUpdate} serviceProps={selectedServices[0]}/>
                        </Drawer>
                    </>
                    }
                    {selectedItems.length > 0 &&
                    <button onClick={this.delete}>
                        <span>Supprimer</span>
                    </button>
                    }
                </div>
            </>
        )
    }

}

export default ActionsCatalogue;