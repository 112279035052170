import Socket from "../Socket/Socket";

class Collections {


    static async globalRead(initCollections, collectionsReducer) {
        const promises = []
        console.log(collectionsReducer)
        for (let key of Object.keys(collectionsReducer)) {
            promises.push(Socket.read(key));
        }
        try {

            const res = await Promise.all(promises);
            const collections = {};
            for (let {collection, data} of res) {
                collections[collection] = data;
            }

            initCollections(collections)
        } catch (e) {
            console.error('Error: global read:', e)
        }
    }

    static flatRecursive = (a, searchKey, t, entityId) => {
        if (Array.isArray(a)) {
            for (let i = 0; i < a.length; i++) {
                if (a[i].profile && !entityId) {
                    entityId = a[i]._id;
                }
                if (typeof a[i] === "object" && !Array.isArray(a[i])) {
                    for (let [key, value] of Object.entries(a[i])) {
                        if (key === searchKey) {
                            if (value.length > 0) {
                                value = Array.from(value, (x) => ({...x, entityId}))
                            }
                            t.push(...value);
                        }
                        if (typeof value === "object" && value) {
                            this.flatRecursive(value, searchKey, t, entityId);
                        }
                    }
                }
            }
        }
        return t;
    };

}

export default Collections;