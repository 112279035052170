import {DacActionTypes, DacState, ServicesState, SET_DAC, SitesState} from "./types";
import {servicesState} from "./state";
import {Deal} from "../../../utils/Types/Pipedrive";

export function editDac(name: string, value: any): DacActionTypes {
    return {
        type: 'EDIT_DAC',
        name: name,
        value: value
    }
}

export function editSociety(name: string, value: any): DacActionTypes {
    return {
        type: 'EDIT_SOCIETY',
        name: name,
        value: value
    }
}

export function removeInfoSociety(): DacActionTypes {
    return {
        type: 'REMOVE_INFO_SOCIETY'
    }
}

export function editSites(name: string, value: number | string, nbSite: number): DacActionTypes {
    return {
        type: 'EDIT_SITES',
        name: name,
        value: value,
        nbSite: nbSite
    }
}

export function duplicateSites(sites: Array<SitesState>): DacActionTypes {
    return {
        type: 'DUPLICATE_SITES',
        sites
    }
}

export function removeSites(ids: Array<string>): DacActionTypes {
    return {
        type: 'REMOVE_SITES',
        ids: ids
    }
}

export function editBilling(name: string, value: any): DacActionTypes {
    return {
        type: 'EDIT_BILLING',
        name: name,
        value: value
    }
}

export function addInfoDeal(deal: Deal): DacActionTypes {
    return {
        type: 'ADD_INFO_DEAL',
        deal: {
            length: deal.length,
            id: deal.id,
            title: deal.title,
            org_id: {name: deal.org_id.name, address: deal.org_id.address, value: deal.org_id.value},
        }
    }
}

export function editServiceObject(value: ServicesState, nbSite: number, nbService: number): DacActionTypes {
    return {
        type: 'EDIT_SERVICE_OBJECT',
        nbSite: nbSite,
        nbService: nbService,
        value: value
    }
}

export function editServiceInput(name: string, value: any, nbSite: number, nbService: number): DacActionTypes {
    // @ts-ignore
    value = typeof servicesState[name] === "number" ? parseFloat(value) : value;
    return {
        type: 'EDIT_SERVICE_INPUT',
        nbSite: nbSite,
        nbService: nbService,
        name: name,
        value: value
    }
}

export function initialService( nbSite: number, nbService: number): DacActionTypes {
    return {
        type: 'INITIAL_SERVICE',
        nbSite: nbSite,
        nbService: nbService,
    }
}

export function copyService(nbSite: number, nbService: number): DacActionTypes {
    return {
        type: 'COPY_SERVICE',
        nbSite: nbSite,
        nbService: nbService,
    }
}

export function removeServices(ids: Array<{idSite: string, idService: string}>): DacActionTypes {
    return {
        type: 'REMOVE_SERVICES',
        ids
    }
}

export function pasteServices(services: Array<ServicesState>, sites: Array<string>): DacActionTypes {
    return {
        type: 'PASTE_SERVICE',
        services,
        sites
    }
}

export function addSite(): DacActionTypes {
    return {
        type: 'ADD_SITE'
    }
}

export function addService(idSites: Array<string>): DacActionTypes {
    return {
        type: 'ADD_SERVICE',
        idSites
    }
}

export function getDac(quote: DacState): DacActionTypes {
    return {
        type: 'GET_DAC',
        quote: quote
    }
}

export function editError(section: string, key: string, value: string, nbSite: number | null | undefined, nbService: number | null | undefined): DacActionTypes {
    return {
        type: 'EDIT_ERROR',
        section: section,
        key: key,
        value: value,
        nbSite: nbSite,
        nbService: nbService
    }
}

export function editFinalTotal(key: string, value: string): DacActionTypes {
    return {
        type: 'EDIT_FINAL_TOTAL',
        key: key,
        value: value
    }
}

export function initDac(): DacActionTypes {
    return {
        type: 'INIT_DAC',
    }
}

export function removeInfoCLient(): DacActionTypes {
    return {
        type: 'REMOVE_INFO_CLIENT'
    }
}


export function setDAC(value: DacState): DacActionTypes {
    return {
        type: "SET_DAC",
        value
    }
}