import * as React from 'react';
import {Route, Switch} from 'react-router';
import Dac from "../views/Dac/Dac";
import Historique from "../views/Historique/Historique";
import Statistiques from "../views/Statistiques/Statistiques";
import Accueil from "../views/Accueil/Accueil";
import Catalogue from "../views/Catalogue/Catalogue";
import Logs from "../views/Logs/Logs";
import Clients from '../views/Clients/Clients';


const routes = [
    {name: "Dashboard", component: Accueil},
    {name: "Devis", component: Dac},
    {name: "Historique", component: Historique},
    // {name: "Eligiblité", component: },
    // {name: "Bibliothèque", component: faMapMarkedAlt},
    {name: "Catalogue", component: Catalogue},
    {name: "Statistiques", component: Statistiques},
    {name: "Logs", component: Logs},
    {name: "Clients", component: Clients},
]
const Router = () => {

    return (
        <Switch>
            {routes.map(route => (
                <Route key={route.name} exact path={`/${route.name}`} component={route.component} />
            ))}
        </Switch>
    )
};

export default Router;
