import React, {Component} from 'react';

class IconHistorique extends Component {
    render() {
        return (
            <svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                 viewBox="0 0 357.4 357.4"  space="preserve">
<path d="M178.7,0C80,0,0,80,0,178.7s80,178.7,178.7,178.7s178.7-80,178.7-178.7S277.4,0,178.7,0z M177.5,310.9h-0.2
	c-36.6,0-71.9-15.4-96.8-42.2c-1.4-1.5-2.2-3.5-2.1-5.7c0.1-2.1,1-4,2.5-5.5c1.5-1.4,3.4-2.1,5.4-2.1c2.2,0,4.3,0.9,5.8,2.5
	c21.9,23.6,53,37.1,85.2,37.1h0.1c64.4,0,116.7-52.4,116.7-116.7c0-64.4-52.4-116.7-116.7-116.7c-29.2,0-57.2,10.9-78.8,30.7
	c-19.7,18.1-32.5,42.1-36.5,68.2l15-13.1c1.4-1.3,3.3-1.9,5.2-1.9c2.3,0,4.4,1,5.9,2.7c1.4,1.6,2.1,3.6,1.9,5.7
	c-0.1,2.1-1.1,4-2.7,5.4l-29.4,25.6c-1.4,1.3-3.3,1.9-5.2,1.9c-2.3,0-4.4-1-5.9-2.7L21.4,155c-2.9-3.3-2.5-8.3,0.8-11.1
	c1.4-1.3,3.3-1.9,5.2-1.9c2.3,0,4.4,1,5.9,2.7l13.1,15.1c4.3-30.3,19-58.1,41.8-79C112.6,58.3,144.4,46,177.5,46
	c73,0,132.5,59.4,132.5,132.5C310,251.5,250.6,310.9,177.5,310.9z M223.9,228.3c1.5,1.5,2.3,3.5,2.3,5.6c0,2.1-0.8,4.1-2.3,5.6
	c-1.5,1.5-3.5,2.3-5.6,2.3c-2.1,0-4.1-0.8-5.6-2.3L169,195.7c-1.5-1.5-2.3-3.5-2.3-5.6v-78.8c0-4.3,3.5-7.9,7.9-7.9
	c4.3,0,7.9,3.5,7.9,7.9v75.5L223.9,228.3z"/>
</svg>
        );
    }
}

export default IconHistorique;