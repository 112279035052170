import * as React from 'react';
import './Logs.scss';
import {connect, ConnectedProps} from "react-redux";
import {CollectionsState} from "../../Store/Reducers/Collections/types";

interface RootState {
    collectionsReducer: CollectionsState
}

const mapState = (state: RootState) => ({
    logs: state.collectionsReducer.ToolitLogs,
});

const mapDispatch = {
};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
}

class Logs extends React.Component<Props> {

    componentDidMount(): void {

    }

    remove = () => {
        // this.props.logs.forEach(log => {
        //     //@ts-ignore
        //     Socket.remove('ToolitLogs', log._id)
        // })
    };

    render() {
        return(
            <div>
                {this.props.logs.map((log, index) => (
                    <div className={"log_line"} key={index}>
                        <span>{log.createdAt}</span>
                        <span>{log.details}</span>
                    </div>
                ))}
            </div>
        )
    }
}

export default connector(Logs);
