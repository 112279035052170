import * as React from 'react';
import './HistoriqueTable.scss';
import ReactTable from 'react-table-6';
import ActionHistorique from "../ActionHistorique/ActionHistorique";
import {DacState} from "../../../Store/Reducers/Dac/types";
//@ts-ignore
import matchSorter from 'match-sorter';

interface Props {
    data: Array<DacState>
}

interface State {
    checkAll: boolean,
    selectedItems: Array<string>
}

class HistoriqueTable extends React.Component<Props, State> {


    constructor(props: any) {
        super(props);
        this.state = {
            checkAll: false,
            selectedItems: []
        }
    }

    onSelect = (e: any, row: any) => {
        if (this.state.selectedItems.find(i => i === row.original._id)) {
            this.setState(prevState => ({
                ...prevState,
                selectedItems: this.state.selectedItems.filter(i => i !== row.original._id)
            }));
        } else {
            this.setState(prevState => ({
                ...prevState,
                selectedItems: [...this.state.selectedItems, row.original._id]
            }));
        }

    }

    onCheckAllChange = (e: any) => {
        if (!this.state.checkAll) {
            const newSelect = Array.from(this.props.data, d => d._id);
            if (newSelect.length > 0) {
                //@ts-ignore
                this.setState(state => ({...state, checkAll: true, selectedItems: newSelect}));
            }
        } else {
            this.setState(prevState => ({...prevState, checkAll: false, selectedItems: []}));
        }
    }

    onDeselectAll = () => {
        this.setState(state => ({...state, selectedItems: []}));
    }

    render() {
        const columns: any = [
            {
                Header: <input
                    onChange={this.onCheckAllChange}
                    type="checkbox"
                    className="check-all"
                />,
                accessor: 'volume',
                Cell: (row: any) =>
                    <input
                        type="checkbox"
                        className="choice-table"
                        checked={!!this.state.selectedItems.find(i => i === row.original._id)}
                        onChange={(e) => this.onSelect(e, row)}
                    />
                ,
                sortable: false,
                maxWidth: 70
            },
            {
                Header: "Société",
                accessor: "society.society",
                filterable: true,
                filterAll: true,
                filterMethod: (filter: any, rows: any) => matchSorter(rows, filter.value, {keys: ['_original.society.society']}),
                Filter: ({filter, onChange}: any) =>
                    <input placeholder="..." className='text-center inputfilters'
                           onChange={event => onChange(event.target.value)}/>
            },
            {
                Header: "N de commande",
                accessor: "society.id_order",
                filterable: true,
                filterAll: true,
                filterMethod: (filter: any, rows: any) => matchSorter(rows, filter.value, {keys: ['_original.society.id_order']}),
                Filter: ({filter, onChange}: any) =>
                    <input placeholder="..." className='text-center inputfilters'
                           onChange={event => onChange(event.target.value)}/>
            },
            {
                Header: "ID Pipedrive",
                accessor: "society.id_pipedrive",
                filterable: true,
                filterAll: true,
                filterMethod: (filter: any, rows: any) => matchSorter(rows, filter.value, {keys: ['_original.society.id_pipedrive']}),
                Filter: ({filter, onChange}: any) =>
                    <input placeholder="..." className='text-center inputfilters'
                           onChange={event => onChange(event.target.value)}/>
            },
            {
                Header: "Status",
                accessor: "tag",
                filterable: true,
                filterAll: true,
                filterMethod: (filter: any, rows: any) => matchSorter(rows, filter.value, {keys: ['tag']}),
                Filter: ({filter, onChange}: any) =>
                    <input placeholder="..." className='text-center inputfilters'
                           onChange={event => onChange(event.target.value)}/>,
                Cell: (row: any) => {

                    let status;
                    if (row.original.tag === 'open') status = 'En cours';
                    if (row.original.tag === 'won') status = 'Signé';
                    if (row.original.tag === 'loss') status = 'Perdu';
                    return <span className={row.original.tag}>{status}</span>
                }
            },
            {
                Header: "Date",
                accessor: "society.order_date",
                filterable: true,
                filterAll: true,
                filterMethod: (filter: any, rows: any) => matchSorter(rows, filter.value, {keys: ['tag']}),
                Filter: ({filter, onChange}: any) =>
                    <input placeholder="..." className='text-center inputfilters'
                           onChange={event => onChange(event.target.value)}/>
            },
            {
                Header: "Propriétaire",
                accessor: "society.owner",
                filterable: true,
                filterAll: true,
                filterMethod: (filter: any, rows: any) => matchSorter(rows, filter.value, {keys: ['_original.society.owner']}),
                Filter: ({filter, onChange}: any) =>
                    <input placeholder="..." className='text-center inputfilters'
                           onChange={event => onChange(event.target.value)}/>
            },
        ];
        return (
            <>
                <ReactTable showPaginationTop={false}
                            showPaginationBottom={true}
                            data={this.props.data}
                            columns={columns}
                            minRows={10}/>
                {this.state.selectedItems.length > 0 &&
                <ActionHistorique deselectAll={this.onDeselectAll} selectedItems={this.state.selectedItems}
                                  orders={this.props.data}/>
                }
            </>
        )
    }
}

export default HistoriqueTable;