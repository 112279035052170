import * as React from 'react';
import {editDac} from "../../../Store/Reducers/Dac/actions";
import {connect, ConnectedProps} from "react-redux";
import {CollectionsState} from "../../../Store/Reducers/Collections/types";
import {Drawer} from "antd";
import TextField from "../../Input/TextField/TextField";
import './Clauses.scss';
import {nanoid} from "nanoid";


interface RootState {
    collectionsReducer: CollectionsState
}

const mapState = (state: RootState) => ({
    Clauses: state.collectionsReducer.Clause
});


const mapDispatch = {
    editDac: (name: string, value: any) => editDac(name, value),
};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
}

interface State {
    clauses: Array<{ _id: string, title: string, describe_clauses: string }>,
    clauseId: string,
    openDrawer: boolean,
    selectedIds: Array<string>,
    clausesPerso: Array<{ _id: string, title: string, describe_clauses: string }>,
    title: string,
    describe_clauses: string
}

class Clauses extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            clauses: [],
            openDrawer: false,
            clauseId: '',
            title: '',
            describe_clauses: '',
            selectedIds: [],
            clausesPerso: []
        }
    }

    componentDidMount() {
        if (this.props.Clauses.length > 0) {
            this.setState(prevState => ({...prevState, clauses: this.props.Clauses}));
        }
    }


    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if (this.state.clauses.length === 0 && prevProps.Clauses !== this.props.Clauses) {
            this.setState(prevState => ({...prevState, clauses: this.props.Clauses}));
        }
        if (prevState.selectedIds !== this.state.selectedIds || prevState.clauses !== this.state.clauses) {
            this.addClausesReducer();
        }
    }

    addClausesReducer = () => {
        const {selectedIds, clauses, clausesPerso} = this.state;
        const newClauses = [
            ...clauses.filter(c => selectedIds.includes(c._id)),
            ...clausesPerso.filter(c => selectedIds.includes(c._id))
        ];

        this.props.editDac('clauses', newClauses);
    }

    openDrawer = (clauseId?: string) => {
        this.setState(prevState => ({...prevState, openDrawer: true, clauseId: clauseId ? clauseId : ''}));
    }

    closeDrawer = () => {
        this.setState(prevState => ({...prevState, openDrawer: false, clauseId: ''}));

    }

    edit = (name: string, value: string) => {
        if (this.state.clauseId) {
            const {clauses, clauseId} = this.state;
            const newClauses = clauses.map(c => {
                if (c._id === clauseId) {
                    return {...c, [`${name}`]: value};
                } else {
                    return c;
                }
            });
            this.setState(prevState => ({...prevState, clauses: newClauses}));
        } else {
            this.setState(state => ({...state, [name]: value}))
        }
    }

    selected = (clauseIds: string) => {
        const {selectedIds} = this.state;
        if (selectedIds.find(i => i === clauseIds)) {
            this.setState(prevState => ({
                ...prevState,
                selectedIds: selectedIds.filter(i => i !== clauseIds)
            }))
        } else {
            this.setState(prevState => ({...prevState, selectedIds: [...selectedIds, clauseIds]}))
        }
    }

    addClauses = () => {
        this.setState(state => ({
            clausesPerso: [...state.clausesPerso, {
                _id: nanoid(),
                title: state.title,
                describe_clauses: state.describe_clauses
            }]
        }), () => this.setState(state => ({title: '', describe_clauses: ''})));
        this.closeDrawer();
    }

    render() {
        const {clauses, openDrawer, clauseId, clausesPerso} = this.state;
        const clause = clauses.find(c => c._id === clauseId);
        return (
            <div id={'Clauses'}>
                <h2>Clauses</h2>
                <div className={'dac__form list_clauses'}>
                    {clauses.map(clause => (
                        <div key={clause._id} className={'ctn_clause'}>
                            <div className={'ctn-checkbox'}>
                                <input onChange={() => this.selected(clause._id)} type={'checkbox'}/>
                            </div>
                            <button onClick={() => this.openDrawer(clause._id)}>{clause.title}</button>
                        </div>
                    ))}
                    {clausesPerso.map(clause => (
                        <div key={clause._id} className={'ctn_clause'}>
                            <div className={'ctn-checkbox'}>
                                <input onChange={() => this.selected(clause._id)} type={'checkbox'}/>
                            </div>
                            <button onClick={() => this.openDrawer(clause._id)}>{clause.title}</button>
                        </div>
                    ))}
                    <div className={'ctn_clause'}>
                        <div className={'ctn-checkbox'}>
                        </div>
                        <button onClick={() => this.openDrawer()}>+ Ajouter</button>
                    </div>
                </div>
                <Drawer
                    title={clause ? "Modification de la clause" : "Ajouter une clause"}
                    placement="right"
                    closable={false}
                    onClose={this.closeDrawer}
                    width={'41%'}
                    visible={openDrawer}
                >
                    <>
                        <TextField ariaLabel={'clauses_title'} type={'text'} name={'title'} label={'Titre'}
                                   placeholder={clause ? clause.title : 'Titre de la clause'}
                                   actionReducer={this.edit} inputType={'input'}/>
                        <TextField ariaLabel={'clauses_describe_clauses'} type={'text'} name={'describe_clauses'}
                                   label={'Description'}
                                   placeholder={clause ? clause.describe_clauses : 'Description de la clauses'}
                                   actionReducer={this.edit} inputType={'textarea'}/>
                        {!clause &&
                        <div>
                            <button onClick={this.addClauses}>Ajouter</button>
                            <button onClick={this.closeDrawer}>Annuler</button>
                        </div>
                        }
                    </>
                </Drawer>
            </div>
        )
    }
}

export default connector(Clauses);