import {Deal} from "../../../utils/Types/Pipedrive";

export const EDIT_DAC = 'EDIT_DAC';
export const EDIT_SITES = 'EDIT_SITES';
export const EDIT_SOCIETY = 'EDIT_SOCIETY';
export const REMOVE_INFO_SOCIETY = 'REMOVE_INFO_SOCIETY';
export const ADD_INFO_DEAL = 'ADD_INFO_DEAL';
export const EDIT_SERVICE_INPUT = 'EDIT_SERVICE_INPUT';
export const INITIAL_SERVICE = 'INITIAL_SERVICE';
export const EDIT_SERVICE_OBJECT = 'EDIT_SERVICE_OBJECT';
export const EDIT_BILLING = 'EDIT_BILLING';
export const DUPLICATE_SITES = 'DUPLICATE_SITES';
export const REMOVE_SITES = 'REMOVE_SITES';
export const COPY_SERVICE = 'COPY_SERVICE';
export const REMOVE_SERVICES = 'REMOVE_SERVICES';
export const PASTE_SERVICE = 'PASTE_SERVICE';
export const ADD_SITE = 'ADD_SITE';
export const ADD_SERVICE = 'ADD_SERVICE';
export const EDIT_ERROR = 'EDIT_ERROR';
export const EDIT_FINAL_TOTAL = 'EDIT_FINAL_TOTAL';
export const INIT_DAC = 'INIT_DAC';
export const GET_DAC = 'GET_DAC';
export const REMOVE_INFO_CLIENT = 'REMOVE_INFO_CLIENT';
export const SET_DAC = "SET_DAC";

export interface BillingState {
    contract: boolean,
    contact_name: string,
    contact_tel: string,
    contact_mail: string,
    address: string,
    error: {
        contact_name: string,
        contact_tel: string,
        contact_mail: string,
        address: string,
    }
}


export interface ServicesState {
    id?: string,
    _id: string,
    id_service: string,
    id_categorie: string,
    family: string,
    designation: string,
    solution: string,
    describe_solution: string,
    duration: string,
    reference: string,
    frn: string,
    number: string,
    abo_ht: number,
    pa_abo: number,
    fas_ht: number,
    pa_fas: number,
    mge_abo: number,
    tx_abo: number,
    mge_fas: number,
    tx_fas: number,
    quantity: number,
    p_router: number,
    error: {
        id_categorie: string,
        solution: string,
        _id?: string,
        id_service?: string,
        family?: string,
        designation?: string,
        describe_solution?: string,
        duration?: string,
        reference?: string,
        frn?: string,
        number?: string,
        abo_ht?: string,
        pa_abo?: string,
        fas_ht?: string,
        pa_fas?: string,
        mge_abo?: string,
        tx_abo?: string,
        mge_fas?: string,
        tx_fas?: string,
        quantity?: string,
        p_router?: string,
    }
}


export interface SocietyState {
    _id?: string;
    id_order: string,
    id_pipedrive: string,
    subscribe_duration: string,
    owner: string,
    order_date: string,
    address: string,
    signatory: string,
    contact_name: string,
    contact_email: string,
    society: string,
    title: string,
    discount_abo: number,
    discount_fas: number,
    mge_abo: number,
    mge_fas: number,
    tx_abo: number,
    tx_fas: number,
    org_id: number,
    abo_ht: number,
    fas_ht: number,
    old_id_pipedrive: string,
    old_abo: string,
    error: {
        id_order: string,
        id_pipedrive: string,
        owner: string,
        order_date: string,
        address: string,
        signatory: string,
        billing_contact: string,
        contact_email: string,
        society: string,
        title: string,
    }
}


export interface SitesState {
    _id: string,
    site_name: string,
    contact_name: string,
    contact_tel: string,
    contact_mail: string,
    delivery_address: string,
    services: Array<ServicesState>,
    siret: string,
    abo_ht: number,
    pa_abo: number,
    fas_ht: number,
    pa_fas: number,
    mge_abo: number,
    tx_abo: number,
    mge_fas: number,
    tx_fas: number,
    quantity: number,
    error: {
        site_name: string,
        delivery_address: string,
        contact_mail: string,
        contact_tel: string,
        contact_name: string,
    },
    total_abo?: any,
    total_fas?: any
}

export interface DacState {
    _id?: string,
    tag: string,
    society: SocietyState,
    billing: BillingState,
    sites: Array<SitesState>,
    clauses: Array<any>,
    countServices: number,
}

interface EditSites {
    type: typeof EDIT_SITES
    name: string,
    value: string | number,
    nbSite: number
}

interface DuplicateSites {
    type: typeof DUPLICATE_SITES,
    sites: Array<SitesState>
}

interface RemoveSites {
    type: typeof REMOVE_SITES,
    ids: Array<string>,

}

interface EditDac {
    type: typeof EDIT_DAC
    name: string,
    value: string | number
}

interface EditSociety {
    type: typeof EDIT_SOCIETY
    name: string,
    value: string | number
}

interface RemoveInfoSociety {
    type: typeof REMOVE_INFO_SOCIETY
}

interface AddInfoDeal {
    type: typeof ADD_INFO_DEAL,
    deal: Deal
}

interface EditServiceObject {
    type: typeof EDIT_SERVICE_OBJECT,
    nbSite: number,
    nbService: number,
    value: ServicesState
}

interface EditServiceInput {
    type: typeof EDIT_SERVICE_INPUT,
    nbSite: number,
    nbService: number,
    name: string,
    value: string | number
}

interface InitialService {
    type: typeof INITIAL_SERVICE,
    nbSite: number,
    nbService: number,
}

interface EditBilling {
    type: typeof EDIT_BILLING
    name: string,
    value: string | number
}

interface CopyService {
    type: typeof COPY_SERVICE,
    nbSite: number,
    nbService: number,
}

interface RemoveServices {
    type: typeof REMOVE_SERVICES,
    ids: Array<{ idSite: string, idService: string }>,
}

interface PasteService {
    type: typeof PASTE_SERVICE,
    services: Array<ServicesState>,
    sites: Array<string>
}

interface AddSite {
    type: typeof ADD_SITE
}

interface AddService {
    type: typeof ADD_SERVICE,
    idSites: Array<string>
}

interface EditError {
    type: typeof EDIT_ERROR,
    section: string,
    key: string,
    value: string,
    nbSite: number | null | undefined
    nbService: number | null | undefined
}

interface EditFinalTotal {
    type: typeof EDIT_FINAL_TOTAL,
    key: string,
    value: string
}

interface InitDac {
    type: typeof INIT_DAC,
}

interface GetDac {
    type: typeof GET_DAC,
    quote: DacState
}

interface RemoveInfoClient {
    type: typeof REMOVE_INFO_CLIENT
}

interface SetDAC {
    type: typeof SET_DAC,
    value: DacState
}
// export type ServicesActionTypes = EditServices
export type DacActionTypes =
    EditDac
    | EditSites
    | EditSociety
    | RemoveInfoSociety
    | AddInfoDeal
    | EditServiceInput
    | InitialService
    | EditServiceObject
    | EditBilling
    | DuplicateSites
    | RemoveSites
    | CopyService
    | RemoveServices
    | PasteService
    | AddSite
    | AddService
    | EditError
    | EditFinalTotal
    | InitDac
    | GetDac
    | RemoveInfoClient
    | SetDAC
