import * as React from 'react';
import './EditService.scss';
import {ServicesState} from "../../../../../Store/Reducers/Dac/types";
import TextField from "../../../../Input/TextField/TextField";
import Socket from "../../../../../utils/Socket/Socket";
import {CollectionsState} from "../../../../../Store/Reducers/Collections/types";
import {addInfoDeal} from "../../../../../Store/Reducers/Dac/actions";
import {connect, ConnectedProps} from "react-redux";
import SelectSearch from "../../../../Input/SelectSearch/SelectSearch";
import {notification} from "antd";
import {Deal} from "../../../../../utils/Types/Pipedrive";


interface RootState {
    collectionsReducer: CollectionsState
}

const mapState = (state: RootState) => ({
    servicesReducer: state.collectionsReducer.Service,
    categoriesReducer: state.collectionsReducer.Category,
});

const mapDispatch = {
    addInfoDeal: (client: Deal) => addInfoDeal(client),
};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
    serviceProps?: ServicesState,
    update?: boolean,
    duplicate?: boolean,
    visibleUpdate?: boolean,
    visibleInsert?: boolean,
    onClose: Function
}

interface State {
    service: ServicesState
}

const initialStateService = {
    _id: '',
    id_service: '',
    id_categorie: '',
    family: '',
    designation: '',
    solution: '',
    describe_solution: '',
    duration: '',
    reference: '',
    frn: '',
    number: '',
    abo_ht: 0,
    pa_abo: 0,
    fas_ht: 0,
    pa_fas: 0,
    mge_abo: 0,
    tx_abo: 0,
    mge_fas: 0,
    tx_fas: 0,
    quantity: 0,
    p_router: 0,
    error: {
        _id: '',
        id_service: '',
        id_categorie: '',
        family: '',
        designation: '',
        solution: '',
        describe_solution: '',
        duration: '',
        reference: '',
        frn: '',
        number: '',
        abo_ht: '',
        pa_abo: '',
        fas_ht: '',
        pa_fas: '',
        mge_abo: '',
        tx_abo: '',
        mge_fas: '',
        tx_fas: '',
        quantity: '',
        p_router: '',
    }
}

class EditService extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            service: initialStateService
        }
    }


    componentDidMount() {
        if (this.props.servicesReducer.length > 0) {
            this.setState(prevState => ({
                ...prevState,
                service: {
                    ...this.state.service,
                    id_service: this.props.servicesReducer[this.props.servicesReducer.length - 1].id_service + 1,
                    number: `${parseInt(this.props.servicesReducer[this.props.servicesReducer.length - 1].number) + 1}`
                }
            }))
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if (prevProps.visibleUpdate !== this.props.visibleUpdate) {
            if (this.props.visibleUpdate && this.props.serviceProps) {
                this.setState({service: {...this.props.serviceProps, error: {...this.state.service.error}}})
            }
        }
        if (prevProps.servicesReducer !== this.props.servicesReducer) {
            this.setState(prevState => ({
                ...prevState,
                service: {
                    ...this.state.service,
                    id_service: this.props.servicesReducer[this.props.servicesReducer.length - 1].id_service + 1,
                    number: `${parseInt(this.props.servicesReducer[this.props.servicesReducer.length - 1].number) + 1}`
                }
            }))
        }
        if (prevState.service.abo_ht !== this.state.service.abo_ht || prevState.service.pa_abo !== this.state.service.pa_abo) {
            this.setState(prevState => ({
                ...prevState,
                service: {
                    ...this.state.service,
                    mge_abo: this.calcMarge(this.state.service.abo_ht, this.state.service.pa_abo),
                    tx_abo: this.calcTaux(this.state.service.abo_ht, this.state.service.pa_abo),
                }
            }));
        }
        if (prevState.service.fas_ht !== this.state.service.fas_ht || prevState.service.pa_fas !== this.state.service.pa_fas) {
            this.setState(prevState => ({
                ...prevState,
                service: {
                    ...this.state.service,
                    mge_fas: this.calcMarge(this.state.service.fas_ht, this.state.service.pa_fas),
                    tx_fas: this.calcTaux(this.state.service.fas_ht, this.state.service.pa_fas),
                }
            }));
        }
    }

    calcMarge = (pV: number, pA: number) => {
        return Number(parseFloat(`${pV - pA}`).toFixed(2))
    }

    calcTaux = (pV: number, pA: number) => {
        if (Number(parseFloat(`${100 - (pA / pV * 100)}`).toFixed(2)) === Infinity || Number(parseFloat(`${100 - (pA / pV * 100)}`).toFixed(2)) === -Infinity) {
            return Number(parseFloat(`${100 - (pA * 100)}`).toFixed(2))
        } else {
            return Number(parseFloat(`${100 - (pA / pV * 100)}`).toFixed(2))
        }
    }

    checkError = (service: ServicesState) => {
        let newError = initialStateService.error;
        this.setState({service: {...this.state.service, error: newError}});
        let err = false;
        for (let [key, value] of Object.entries(service)) {
            if (!value && value !== 0) {
                err = true;
                newError = {...newError, [key]: 'errorField'}
            }
        }
        //@ts-ignore
        this.setState({service: {...this.state.service, error: newError}});
        return err;
    }

    onChange = (name: string, value: any) => {
        const numbers = ['abo_ht', 'fas_ht', 'mge_abo', 'mge_fas', 'tx_abo', 'tx_fas', 'pa_abo', 'pa_fas'];
        if (value || value === 0) {
            let nvalue = numbers.includes(name) ? Number(value) : value;
            this.setState(prevState => ({...prevState, service: {...this.state.service, [`${name}`]: nvalue}}))
        } else {
            if (this.props.serviceProps) {
                // @ts-ignore
                this.setState(prevState => ({...prevState, service: {...this.state.service, [`${name}`]: this.props.serviceProps[`${name}`]}
                }))
            }
        }
    }


    initState = () => {
        this.setState({service: initialStateService})
    }

    onUpdate = () => {
        const {service} = this.state;
        if (!this.checkError(service)) {
            Socket.update('Service', service._id, null, service)
                .then(res => {
                    notification.success({message: "La modification a bien ete pris en compte."});
                    this.initState();
                    this.props.onClose();
                })
                .catch(err => {
                    console.error(err);
                    notification.error({message: "Une erreur c'est produit lors de la modification"});
                })
        }
    }

    onInsert = () => {
        const {service} = this.state;
        //@ts-ignore
        delete service.error; delete service._id;
        if (!this.checkError(service)) {
            Socket.insert('Service', service)
                .then(res => {
                    notification.success({message: "Le service a bien ete sauvegarder."})
                    this.props.onClose();
                    this.initState();
                })
                .catch(err => {
                    console.error(err)
                    notification.error({message: "Une erreur s'est produit lors de l'enregistrement."})
                })
        }
    }

    render() {
        const {update, categoriesReducer} = this.props;
        const {service} = this.state;
        return (
            <div id={'EditService'}>
                {!update &&
                <SelectSearch actionReducer={this.onChange} items={categoriesReducer} name={'id_categorie'}
                              placeholder={'Categorie'} value={service.id_categorie}
                              ctnName={"input__service"} className={service.error.id_categorie}
                              valuesDisplay={"name"} valuesKey={"_id"}/>
                }
                <TextField label={'Famille de service'}
                           className={service.error.family} ariaLabel={'catalogue_family'} type={'text'} name={'family'}
                           placeholder={service.family} actionReducer={this.onChange} inputType={'input'}/>
                <TextField label={'Designation'} ariaLabel={'catalogue_designation'}
                           className={service.error.designation} type={'text'} name={'designation'}
                           placeholder={service.designation} actionReducer={this.onChange} inputType={'input'}/>
                <TextField label={'Solution'} ariaLabel={'catalogue_solution'} className={service.error.solution}
                           type={'text'} name={'solution'}
                           placeholder={service.solution} actionReducer={this.onChange} inputType={'input'}/>
                <TextField label={'Description'} ariaLabel={'catalogue_describe_solution'}
                           className={service.error.describe_solution} type={'text'}
                           name={'describe_solution'} placeholder={service.describe_solution}
                           actionReducer={this.onChange} inputType={'textarea'}/>
                <TextField label={'Duree'} ariaLabel={'catalogue_duration'} className={service.error.duration}
                           type={'text'} name={'duration'}
                           placeholder={service.duration} actionReducer={this.onChange} inputType={'input'}/>
                <TextField label={'Reference'} ariaLabel={'catalogue_reference'} type={'text'}
                           className={service.error.reference} name={'reference'}
                           placeholder={service.reference} actionReducer={this.onChange} inputType={'input'}/>
                <TextField label={'Fournisseur'} className={service.error.frn} ariaLabel={'catalogue_fournisseur'}
                           type={'text'} name={'frn'}
                           placeholder={service.frn} actionReducer={this.onChange} inputType={'input'}/>
                <div className={'ctn-price'}>
                    <div className={'ctn-stn-price'}>
                        <TextField label={'Prix achat ABO (€)'} className={service.error.pa_abo}
                                   ariaLabel={'catalogue_pa_abo'} type={'number'}
                                   name={'pa_abo'} placeholder={`${service.pa_abo}€`} actionReducer={this.onChange}
                                   inputType={'input'}/>
                        <TextField label={'ABO ht/mois (€)'} ariaLabel={'catalogue_abo_ht'}
                                   className={service.error.abo_ht} type={'number'}
                                   name={'abo_ht'} placeholder={`${service.abo_ht}€`} actionReducer={this.onChange}
                                   inputType={'input'}/>
                        <TextField label={'Marge ABO (€)'} ariaLabel={'catalogue_mge_abo'} type={'number'}
                                   name={'mge_abo'} placeholder={`${service.mge_abo}€`} actionReducer={this.onChange}
                                   inputType={'input'} disabled={true}/>
                        <TextField label={'Taux ABO (%)'} ariaLabel={'catalogue_tx_abo'} type={'number'} name={'tx_abo'}
                                   placeholder={`${service.tx_abo}%`} actionReducer={this.onChange} inputType={'input'}
                                   disabled={true}/>
                    </div>
                    <div className={'ctn-stn-price'}>
                        <TextField label={'Prix achat FAS (€)'} ariaLabel={'catalogue_pa_fas'} type={'number'}
                                   className={service.error.pa_fas}
                                   name={'pa_fas'} placeholder={`${service.pa_fas}€`} actionReducer={this.onChange}
                                   inputType={'input'}/>
                        <TextField label={'FAS ht/mois (€)'} ariaLabel={'catalogue_fas_ht'} type={'number'}
                                   className={service.error.fas_ht}
                                   name={'fas_ht'} placeholder={`${service.fas_ht}€`} actionReducer={this.onChange}
                                   inputType={'input'}/>
                        <TextField label={'Marge FAS (€)'} ariaLabel={'catalogue_mge_fas'} type={'number'}
                                   name={'mge_fas'} placeholder={`${service.mge_fas}€`} actionReducer={this.onChange}
                                   inputType={'input'} disabled={true}/>
                        <TextField label={'Taux FAS (%)'} ariaLabel={'catalogue_tx_fas'} type={'number'} name={'tx_fas'}
                                   placeholder={`${service.tx_fas}%`} actionReducer={this.onChange} inputType={'input'}
                                   disabled={true}/>
                    </div>
                </div>
                <button onClick={update ? this.onUpdate : this.onInsert}>Confirmer</button>
                {/*<Input name={'quantity'} onChange={this.onChange} placeholder={""} defaultValue={service.quantity}/>*/}
                {/*<Input name={'p_router'} onChange={this.onChange} placeholder={""} defaultValue={service.p_router}/>*/}
            </div>
        )
    }
}

export default connector(EditService);