import * as React from 'react';
import './Dac.scss';
import Society from "../../Components/Dac/Society/Society";
import {connect, ConnectedProps} from "react-redux";
import Pipedrive from "../../utils/Pipedrive/Pipedrive";
import Billing from "../../Components/Dac/Billing/Billing";
import Sites from "../../Components/Dac/Sites/Sites";
import {DacState} from "../../Store/Reducers/Dac/types";
import {addInfoDeal, addSite, editSites, initDac, removeInfoCLient, setDAC} from "../../Store/Reducers/Dac/actions";
import ActionsDac from "../../Components/Dac/ActionsDac/ActionsDac";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUsers} from "@fortawesome/free-solid-svg-icons";
import {Tabs} from 'antd';
import ContractInfo from "../../Components/Dac/ContractInfo/ContractInfo";
import Clauses from "../../Components/Dac/Clauses/Clauses";
import {Contact, Deal} from "../../utils/Types/Pipedrive";
import {TotalState} from "../../Store/Reducers/Dac/Total/types";
import {editTotal, receiveTotal} from "../../Store/Reducers/Dac/Total/actions";
import axios from "axios";
//@ts-ignore
import Sellsy from "node-sellsy";
import {dacState} from "../../Store/Reducers/Dac/state";

const {TabPane} = Tabs;

interface RootState {
    dacReducer: DacState,
}

const mapState = (state: RootState) => ({
    societyReducer_idPipedrive: state.dacReducer.society.id_pipedrive,
    societyReducer_contactName: state.dacReducer.society.contact_name,
    sitesReducer: state.dacReducer.sites,
    dacReducer: state.dacReducer
});


const mapDispatch = {
    addInfoDeal: (client: Deal) => addInfoDeal(client),
    initDac: () => initDac(),
    removeInfoClient: () => removeInfoCLient(),
    editTotal: (name: string, value: number) => editTotal(name, value),
    addSite: () => addSite(),
    receiveTotal: (value: TotalState) => receiveTotal(value),
    editSites: (name: string, value: string | number, nbSite: number) => editSites(name, value, nbSite),
    setDAC: (value: DacState) => setDAC(value)
};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
}

export interface DacCompState {
    contacts: Array<Contact>,
    contact_emails: Array<{ value: string }>,
    durations: Array<{label: string, id: string}>
}

class Dac extends React.Component<Props, DacCompState> {

    // Variable pour la verification du montage du component
    // (empeche les maj si le component n'est pas monte)
    // _isMounted = false;
    constructor(props: any) {
        super(props);
        this.state = {
            contacts: [],
            contact_emails: [],
            durations: []
        }

    }

    async componentDidMount() {
        if(localStorage.getItem('dac')) {
            this.props.setDAC(JSON.parse(localStorage.getItem('dac')))
        }
        if ( this.props.societyReducer_idPipedrive) {
            this.sendInfoDeal();
        }
        this.getDurations();

        const sellsy = new Sellsy({
           
          })
    }
    componentWillUnmount() {
        this.props.initDac();
    }

    getDurations = async () => {
        const res = await axios.get('https://api.pipedrive.com/v1/dealFields/12465?api_token=d168467b213a71acdc4ba5cb5b2cfe96529ac061')
        const durations = res.data.data.options;
        this.setState(() => ({durations: durations.map((d: {id: string, label: string}) => ({id: `${d.id}`, label: d.label}))}))
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<DacCompState>, snapshot?: any) {
        if(prevProps.dacReducer !== this.props.dacReducer) {
            localStorage.setItem('dac', JSON.stringify(this.props.dacReducer))
        }
        if (prevProps.societyReducer_idPipedrive !== this.props.societyReducer_idPipedrive) {
            this.sendInfoDeal();
        }
        if (prevProps.societyReducer_contactName !== this.props.societyReducer_contactName) {
            const contacts = this.state.contacts.filter(contact => contact.name === this.props.societyReducer_contactName);
            if (contacts.length > 0) {
                const emails = this.state.contacts.filter(contact => contact.name === this.props.societyReducer_contactName)[0].email;
                this.setState(state => ({contact_emails: emails.length > 0 ? emails : []}));
            }
        }
    }


    // envoie les valeurs recuperer sur pipedrive a societyReducer
    sendInfoDeal = () => {
        Pipedrive.getDeal(this.props.societyReducer_idPipedrive)
            .then(client => {
                if (client.title) {
                    this.props.addInfoDeal(client);
                    this.getContacts(client.org_id.value);
                }
            }).catch(err => {
            if (err) {
                this.removeInfoClient();
            }
        })
    };

    // Supprime les infos du client dans societyReducer
    removeInfoClient = () => {
        this.props.removeInfoClient();
        this.setState(() => ({
            contacts: [],
            contact_emails: [],
        }))
    }

    // recupere les contacts pour la liste des signataires
    getContacts = (id: number) => {
        Pipedrive.getContacts(id)
            .then(contacts => {
                this.setState(state => ({contacts: contacts}));
            })
    };

    clear = () => {
        localStorage.removeItem('dac');
        this.props.setDAC(dacState)
        // console.log
        if (this.props.sitesReducer.length === 0) {
            this.props.addSite()
        }
    }
    render() {
        const {sitesReducer} = this.props;
        const {durations} = this.state;

        return (
            <div id={'Dac'}>
                <div className={"title_page"}>
                    <FontAwesomeIcon icon={faUsers}/>.
                    <h2>Création de Devis</h2>
                    <button className={"btn__refresh__dac"} onClick={this.clear}>Effacer le formulaire</button>
                </div>
                <Tabs className={"tabs"} defaultActiveKey="1">
                    <TabPane tab={<span>Nouveau</span>} key="1">
                        <Society dacState={this.state}/>
                        <Billing dacState={this.state}/>
                        {sitesReducer.map((site, index) => (
                            <Sites key={site._id} nbSite={index} durations={this.state.durations} dacState={this.state} />
                        ))}
                        <Clauses/>
                        <ActionsDac durations={this.state.durations}/>
                    </TabPane>
                    <TabPane tab={<span>Annule et remplace</span>} key="2">
                        <ContractInfo/>
                        <Society dacState={this.state}/>
                        <Billing dacState={this.state}/>
                        {sitesReducer.length > 0 && sitesReducer.map((site, index) => (
                            <Sites key={site._id} nbSite={index} dacState={this.state} durations={this.state.durations}/>
                        ))}
                        <Clauses/>
                        <ActionsDac durations={this.state.durations}/>
                    </TabPane>
                </Tabs>
            </div>
        )
    }
}

export default connector(Dac);
