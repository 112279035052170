
export const EDIT_TOTAL = 'EDIT_TOTAL';
export const RECEIVE_TOTAL = 'RECEIVE_TOTAL';
export const ADD_TOTAL = 'ADD_TOTAL';
export const EDIT_TX_TOTAL = 'EDIT_TX_TOTAL';

export interface TotalState  {
    id_service: string,
    abo_ht: number,
    pa_abo: number,
    fas_ht: number,
    pa_fas: number,
    mge_abo: number,
    tx_abo: number,
    mge_fas: number,
    tx_fas: number,
    discount_abo: number,
    discount_fas: number,
}

interface addTotal {
    type: typeof ADD_TOTAL,
    name: string,
    value: number ,
}

interface EditTotal {
    type: typeof EDIT_TOTAL,
    name: string,
    value: number,
}

interface ReceiveTotal {
    type: typeof RECEIVE_TOTAL,
    value: TotalState,
}

interface EditTxTotal {
    type: typeof EDIT_TX_TOTAL,
    name: string,
    value: number,
    nbService: number
}
export type TotalActionTypes = EditTotal | addTotal | EditTxTotal | ReceiveTotal;
