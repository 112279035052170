import * as React from 'react';
import './Sites.scss';
import SelectSearch from "../../Input/SelectSearch/SelectSearch";
import TextField from "../../Input/TextField/TextField";
import {connect, ConnectedProps} from "react-redux";
import {DacCompState} from "../../../views/Dac/Dac";
import Services from "./Services/Services";
import {DacState} from "../../../Store/Reducers/Dac/types";
import {editDac, editSites} from "../../../Store/Reducers/Dac/actions";
import {editActionsDac} from "../../../Store/Reducers/Dac/ActionsDac/actions";
import {ActionsDacState} from "../../../Store/Reducers/Dac/ActionsDac/types";
import AutoCompleteAddress from "../../Input/AutoCompleteAddress/AutoCompleteAddress";
import {TotalState} from "../../../Store/Reducers/Dac/Total/types";
import {Contact} from "../../../utils/Types/Pipedrive";
import { Select } from 'antd';
import {nanoid} from "nanoid";
import Highlighter from "react-highlight-words";

const { Option } = Select;

interface RootState {
    dacReducer: DacState,
    actionsDacReducer: ActionsDacState,
    totalReducer: TotalState
}

const mapState = (state: RootState, ownProps: { nbSite: number }) => {
    return {
        siteReducer_id: state.dacReducer.sites[ownProps.nbSite]._id,
        siteReducer_contact_name: state.dacReducer.sites[ownProps.nbSite].contact_name,
        siteReducer_contact_tel: state.dacReducer.sites[ownProps.nbSite].contact_tel,
        siteReducer_error: state.dacReducer.sites[ownProps.nbSite].error,
        siteReducer_site_name: state.dacReducer.sites[ownProps.nbSite].site_name,
        siteReducer_delivery_address: state.dacReducer.sites[ownProps.nbSite].delivery_address,
        siteReducer_contact_mail: state.dacReducer.sites[ownProps.nbSite].contact_mail,
        siteReducer_siret: state.dacReducer.sites[ownProps.nbSite].siret,
        siteReducer_services: state.dacReducer.sites[ownProps.nbSite].services,
        billingReducer_contract: state.dacReducer.billing.contract,
        sitesCopy: state.actionsDacReducer.copySites,
        countServices: state.dacReducer.countServices
    }
};

const mapDispatch = (dispatch: Function, ownProps: { nbSite: number }) => ({
    editSites: (name: string, value: string | number) => dispatch(editSites(name, value, ownProps.nbSite)),
    pushCopySites: (name: string, value: Array<string>) => dispatch(editActionsDac(name, value)),
    editDac: (nb: number) => dispatch(editDac('countServices', nb))
});

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
    dacState: DacCompState,
    nbSite: number,
    durations: Array<{label: string, id: string}>
}

interface SitesCompState {
    contact: Contact,
}

class Sites extends React.PureComponent<Props, SitesCompState> {

    constructor(props: any) {
        super(props);
        this.state = {
            contact: {
                id: 0,
                name: '',
                first_name: '',
                last_name: '',
                phone: [],
                email: [],
            },
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<SitesCompState>, snapshot?: any) {
        if (prevProps.siteReducer_contact_name !== this.props.siteReducer_contact_name) {
            const contacts = this.props.dacState.contacts.filter(contact => contact.name === this.props.siteReducer_contact_name);
            if (contacts.length > 0) {
                this.setState({contact: contacts[0]});
                this.editSite('contact_mail', contacts[0].email[0].value);
            }
        }
    }

    selectedSite = () => {
        const {siteReducer_id, sitesCopy} = this.props;
        let newCopySites = [];
        if (!sitesCopy.find(s => s === siteReducer_id)) {
            newCopySites = [...this.props.sitesCopy, siteReducer_id];
        } else {
            newCopySites = sitesCopy.filter(s => s !== siteReducer_id);
        }
        this.props.pushCopySites('copySites', newCopySites);
    }


    editSite = (name: string, value: string | number) => {
        this.props.editSites(name, value)
    }

    changeContact = (value: string) => {

        this.props.editSites('contact_tel', value);
    }

    render() {
        const {
            siteReducer_id,
            siteReducer_error,
            siteReducer_site_name,
            siteReducer_contact_name,
            siteReducer_contact_mail,
            siteReducer_contact_tel,
            siteReducer_delivery_address,
            siteReducer_siret,
            siteReducer_services,
            sitesCopy,
            nbSite,
            dacState,
            billingReducer_contract,
        } = this.props;
        const {contact} = this.state;
        const {contacts} = dacState;
        const contactsTellList = contact.phone.length > 0 ?
            contact.phone.filter(data => data.value)
            : [];

        return (
            <div data-testid={`Site_${nbSite}`} className={'Site'}>
                <div className={'ctn-header-sites'}>
                    <>
                        <div className={'d-flex site_title'}>
                            <input type="checkbox" className={'checkbox'} defaultChecked={!!sitesCopy.find(s => s === siteReducer_id)} onChange={this.selectedSite} title={"Selectionner le site"}/>

                            <h2>
                                Site n° {nbSite + 1}
                            </h2>
                            <TextField ariaLabel={`site_${nbSite}_title`} type={'text'} name={`site_name`}
                                       placeholder={'NOM DU SITE'} inputType={'input'} ctnName={"site_name"}
                                       className={siteReducer_error && siteReducer_error.site_name} mandatory={true}
                                       value={siteReducer_site_name} actionReducer={this.editSite}
                            />
                        </div>
                    </>
                </div>
                <div className={'dac__form'}>
                    <SelectSearch value={siteReducer_contact_name}
                                  name={'contact_name'}
                                  placeholder={'Nom du contact sur site'}
                                  actionReducer={this.editSite} unblocked={true}
                                  items={contacts} valuesDisplay={'name'} valuesKey={'name'}
                                  className={siteReducer_error && siteReducer_error.contact_name}
                                  mandatory={true}
                    />
                    <SelectSearch value={siteReducer_contact_tel}
                                  name={'contact_tel'} unblocked={true}
                                  placeholder={'Tel du contact'}
                                  actionReducer={this.editSite}
                                  className={siteReducer_error && siteReducer_error.contact_tel}
                                  items={contactsTellList} valuesDisplay={'value'} valuesKey={'value'}
                                  mandatory={true}
                    />

                    <TextField ariaLabel={`site_${nbSite}_contact_mail`}
                               type={'text'}
                               name={'contact_mail'}
                               placeholder={'Mail du contact'}
                               inputType={'input'}
                               value={siteReducer_contact_mail}
                               mandatory={true}
                               className={siteReducer_error && siteReducer_error.contact_mail}
                               actionReducer={this.editSite}
                    />
                    <AutoCompleteAddress ariaLabel={`site_${nbSite}_delivery_address`}
                                         type={'text'}
                                         name={'delivery_address'}
                                         placeholder={'Adresse de livraison'}
                                         inputType={'input'}
                                         actionReducer={this.editSite}
                                         value={siteReducer_delivery_address}
                                         mandatory={billingReducer_contract}
                                         className={siteReducer_error && siteReducer_error.delivery_address}
                    />
                    <TextField ariaLabel={`site_${nbSite}_siret`}
                               type={'text'}
                               name={'siret'}
                               placeholder={'Siret'}
                               inputType={'input'}
                               value={siteReducer_siret}
                               actionReducer={this.editSite}
                    />
                    {(siteReducer_services && siteReducer_services.length > 0) && siteReducer_services.map((service, index) => (
                        <Services durations={this.props.durations} nbSite={nbSite} siteName={siteReducer_site_name} nbService={index} key={service.id}
                                  idService={(service && service.id) ? service.id : service._id}
                                  idSite={siteReducer_id} />
                    ))}
                </div>
            </div>
        )
    }
}

export default connector(Sites);
