import * as React from "react";
import PagePDF from "./PagePdf";
import {PDFDownloadLink} from "@react-pdf/renderer";
import {notification} from "antd";
import {DacState} from "../../../Store/Reducers/Dac/types";
import {DownloadOutlined, LoadingOutlined} from "@ant-design/icons/lib";
import './PDFButton.scss';
import {CollectionsState} from "../../../Store/Reducers/Collections/types";
import {connect, ConnectedProps} from "react-redux";
import IconPDF from "./IconPDF/IconPDF";
import PagePdfHistorique from "./PagePdfHistorique";
import axios from "axios";


interface RootState {
    collectionsReducer: CollectionsState
}

const mapState = (state: RootState) => ({
    categoryReducer: state.collectionsReducer.Category
})

const mapDispatch = () => ({});

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
    checkError?: Function,
    onMouseEnter?: Function,
    onMouseLeave?: Function,
    dataDac: DacState | undefined,
    disabledPdf?: boolean,
    type?: string
}

interface State {
    data: DacState | null,
    ready: boolean,
    durations: Array<{label: string, id: string}>
}

class PDFButton extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            data: null,
            ready: false,
            durations: []
        }
    }

    componentDidMount() {
        this.getDurations();
    }

    getDurations = async () => {
        const res = await axios.get('https://api.pipedrive.com/v1/dealFields/12465?api_token=d168467b213a71acdc4ba5cb5b2cfe96529ac061')
        const durations = res.data.data.options;
        this.setState(() => ({durations: durations.map((d: {id: string, label: string}) => ({id: `${d.id}`, label: d.label}))}))
    }
    refactorData = (data: DacState) => {

        let newData = data;
        //@ts-ignore
        newData.society.subscribe_duration = this.state.durations.find(d => d.id == data.society.subscribe_duration).label;
        newData.sites.map(site => {
            site.services =  site.services.map(service => {
                //@ts-ignore
                service.duration = this.state.durations.find(d => d.id == service.duration).label;
                return service;
            })
            return  site;
        })
        return newData;
    }
    sendDataPDf = () => {
        // @ts-ignore
        this.setState(prevState => ({...prevState, data: this.props.dataDac}));
        if (this.props.checkError) {
            const {checkError} = this.props;
            if (checkError()) {
                if (!checkError()) {
                    setTimeout(() => {
                        this.setState(prevState => ({ready: true}))
                    }, 500)
                } else {
                    notification.error({
                        message: "Une erreur c'est produit lors de l'exportation du PDFHistorique",
                        description: "Veuillez vérifier tout les champs"
                    })
                }

            } else {
                setTimeout(() => {
                    this.setState(prevState => ({...prevState, ready: true}))
                }, 500)
            }
        } else {
            setTimeout(() => {
                this.setState(prevState => ({...prevState, ready: true}))
            }, 500)
        }
    };

    render() {
        const {disabledPdf, type} = this.props;
        let fileName = this.state.data ? `${this.state.data.society.title.replace(/ /g, "")}_${this.state.data.society.society}_${this.state.data.society.order_date.replace(/_/g, "")}.pdf` : 'devis.pdf';
        return (
            <button onClick={this.sendDataPDf} disabled={disabledPdf}>
                {this.state.ready ?
                    <PDFDownloadLink
                        document={
                            type === "Facture" ?
                                <PagePdfHistorique order={this.state.data}/>
                                :
                                <PagePDF Categories={this.props.categoryReducer} order={this.state.data}/>
                        }
                        fileName={fileName}
                    >
                        {({blob, url, loading, error}) => {
                            return loading ?
                                <>
                                    <LoadingOutlined/>
                                    <span className={``}>Chargement...</span>
                                </>
                                :
                                <>
                                    <DownloadOutlined/><br/>
                                    <span>Télécharger</span>
                                </>
                        }}
                    </PDFDownloadLink>
                    :
                    <>
                        <IconPDF/>
                        <span>{type === "Facture" ? "Facture PDF" : "Devis PDF"}</span>
                    </>
                }
            </button>
        );
    }
}

export default connector(PDFButton);
