import openSocket from 'socket.io-client';
import User from "../User/User";
export const socket = openSocket(process.env.REACT_APP_BACK_URL + '/');
class Socket {

    static read(collection, filter) {
        return new Promise((resolve, reject) => {
            const token = User.token();
            socket.emit("read", {token, collection, filter} , (err, res) => {
                if (err) {
                    console.error(err.message);
                    reject(err);
                } else {
                    resolve(res);
                }
            });
        })
    }

    static readLean(collection, filter, keys) {
        return new Promise((resolve, reject) => {
            const token = User.token();
            socket.emit("readLean", {token , collection, filter, keys} , (err, res) => {
                if (err) {
                    console.error(err.message);
                    reject(err);
                } else {
                    resolve(res);
                }
            })
        })
    }

    static readLast(collection, filter, keys) {
        return new Promise((resolve, reject) => {
            const token = User.token();
            socket.emit("readLast", {token, collection, filter, keys} , (err, res) => {
                if (err) {
                    console.error(err.message);
                    reject(err);
                } else {
                    resolve(res);
                }
            })
        })
    }

    static readInterface(collection, filter, keys) {
        return new Promise((resolve, reject) => {
            const token = User.token();
            socket.emit("readInterface", {token, collection, filter, keys}, (err, res) => {
                if (err) {
                    console.error(err.message);
                    reject(err);
                } else {
                    resolve(res);
                }
            })
        })
    }

    static insert(collection, data) {
        let token = User.token();
        return new Promise((resolve, reject) => {
            socket.emit("insert", {collection, token, data}, (err, res) => {
                if (err) {
                    // console.error(err.message);
                    reject(err);
                } else {
                    resolve(res)
                }
            });
        });
    }

    static update(collection, id, entityId, newData) {
        let token = User.token();
        return new Promise((resolve, reject) => {
            socket.emit("update", {collection, id, entityId, newData, token}, (err, res) => {
                if (err) {
                    console.error(err.message);
                    reject(err);
                } else {
                    resolve(res)
                }
            });
        })
    }

    static updateMany(collection, entitiesIds, id) {
        let token = User.token();
        return new Promise((resolve, reject) => {
            socket.emit("updateMany", {token, entitiesIds, collection, id}, (err, res) => {
                if (err) {
                    console.error(err.message);
                    reject(err)
                } else {
                    resolve(res)
                }
            })
        })
    }

    static updateOtherUsers(collection, id, newData) {
        let token = User.token();
        socket.emit("updateOtherUsers", {collection, id, newData, token}, (err, res) => {

        });
    }

    static remove(collection, id) {
        return new Promise((resolve, reject) => {
            let token = User.token();
            socket.emit("remove", {token, collection, id}, (err, res) => {
                if (err) {
                    console.error(err.message);
                    reject(err)
                } else {
                    resolve(res)
                }
            });
        })
    }

    static deleteMany(collection, entitiesIds, id) {
        let token = User.token();
        return new Promise((resolve, reject) => {
            socket.emit("removeMany", {token, entitiesIds, collection, id}, (err, res) => {
                if (err) {
                    console.error(err.message);
                    reject(err)
                } else {
                    resolve(res)
                }
            })
        });
    }


    static createUser(user) {
        return new Promise((resolve, reject) => {
            socket.emit("createUser", user, (err, res) => {
                if (err) {
                    console.error(err.message);
                    reject(err)
                } else {
                    resolve(res)
                }
            });
        });
    }

    static loginUser(user) {
        return new Promise((resolve, reject) => {
            socket.emit("login", user, (res) => {
                // if (err) {
                //     console.error(err.message);
                //     reject(err)
                // } else {
                    resolve(res)
                // }
            });
        });
    }

    static isAdmin(token) {
        return new Promise((resolve, reject) => {
            socket.emit("isAdmin", token, (err, res) => {
                if (err) {
                    console.error(err.message);
                    reject(err)
                } else {
                    resolve(res)
                }
            });
        });
    }

    static getUserInfo(token) {
        return new Promise((resolve, reject) => {
            socket.emit("getUserInfo", token, (err, res) => {
                if (err) {
                    console.error(err.message);
                    reject(err)
                } else {
                    resolve(res.data);
                }
            });
        });
    }

    static checkValidateToken(token) {
        return new Promise((resolve, reject) => {
            socket.emit("validateToken", token, (err, res) => {
                if (err) {
                    console.error(err.message);
                    reject(err)

                    User.removeToken();
                    window.location.replace(process.env.REACT_APP_WANUP + "");
                } else {
                    resolve(res.data);
                }
            });
        });
    }

    static forgotPassword(email) {
        return new Promise((resolve, reject) => {
            socket.emit("forgotPassword", email, (err, res) => {
                if (err) {
                    console.error(err.message);
                    reject(err)
                } else {
                    resolve(res.data);
                }
            });
        });
    }

    static resetPassword(token, password) {
        return new Promise((resolve, reject) => {
            socket.emit("resetPassword", {token, password}, (err, res) => {
                if (err) {
                    console.error(err.message);
                    reject(err)
                } else {
                    resolve(res.data);
                }
            });
        });
    }

    static resetPasswordVanko(token, lastPassword, verifypsw, newPwd) {
        return new Promise((resolve, reject) => {
            socket.emit("resetPasswordVanko", {token, lastPassword, verifypsw, newPwd}, (err, res) => {
                if (err) {
                    console.error(err.message);
                    reject(err)
                } else {
                    resolve(res.data);
                }
            });
        });
    }

    static readTest(entity) {
        return new Promise((resolve, reject) => {
            socket.emit("readTest", entity, User.token(), (res) => {
                resolve(res);
            })

        })
    }

    static readTestToken(collection, filter) {
        return new Promise((resolve, reject) => {
            socket.emit("readTestToken", collection, filter, User.token(), (res) => {
                resolve(res);
            });
        })
    }

    static live(callback) {
        socket.on(`live`, (res) => {
            callback(res)
        })
    }

    static leaveAllRooms() {
        socket.emit("leaveAllRooms");
    }
    //--------------------------------------------------------------------------




    static charge() {
        return new Promise((resolve, reject) => {
            socket.on("readStatus", (res) => {
                resolve(res);
            });
        });
    }

    static readChange(collection, callback) {
        // return new Promise((resolve, reject) => {
        socket.on(`read${collection}`, (res) => {
            callback(res);
        });
        // })
    }

    static closeWatch() {
        socket.emit('closeWatch')
    }




    //======================================================


    static sendNotif(obj) {
        socket.emit('notif', obj)
    }

    static receiveNotif() {
        return new Promise(resolve => {
            socket.on('notif', obj => {
                resolve(obj);
            })
        })
    }
}


export default Socket;
