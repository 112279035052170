import React, {Component} from 'react';

class IconDuplicate extends Component {
    render() {
        return (
            <svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                 viewBox="0 0 278.7 278.7" space="preserve">
<g>
	<path d="M224,42.5V28.9C224,13,211,0,195.1,0H28.9C13,0,0,13,0,28.9v166.1C0,211,13,224,28.9,224h13.5V83.6
		c0-22.7,18.4-41.1,41.1-41.1H224z"/>
    <path d="M249.7,54.6H83.6c-16,0-28.9,13-28.9,28.9v166.1c0,16,13,28.9,28.9,28.9h166.1c16,0,28.9-13,28.9-28.9V83.6
		C278.7,67.6,265.7,54.6,249.7,54.6z M229.7,166.7c0,4.7-3.8,8.6-8.6,8.6h-45.9v45.9c0,4.7-3.8,8.6-8.6,8.6c-4.7,0-8.6-3.8-8.6-8.6
		v-45.9h-45.9c-4.7,0-8.6-3.8-8.6-8.6v0c0-4.7,3.8-8.6,8.6-8.6h45.9v-45.9c0-4.7,3.8-8.6,8.6-8.6c4.7,0,8.6,3.8,8.6,8.6v45.9h45.9
		C225.8,158.1,229.7,161.9,229.7,166.7L229.7,166.7z"/>
</g>
</svg>
        );
    }
}

export default IconDuplicate;