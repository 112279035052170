import axios from "axios";
import {notification} from "antd";
import Socket from "./Socket/Socket";

const tokenTrello = process.env.REACT_APP_TRELLO_TOKEN;
const keyTrello = process.env.REACT_APP_TRELLO_KEY;
const urlTrello = "https://api.trello.com/1";

const apiPipe = process.env["REACT_APP_API_PIPEDRIVE"];
const tokenPipe = process.env["REACT_APP_PIPEDRIVE_TOKEN"];

export default class Trello {
    static refactorTitle = (cardName, title) => {
        let refactorTitle = "";
        for (let i = 0; i < cardName.length; i++) {
            if (cardName[i] === title[i]) {
                refactorTitle += title[i];
            }
        }

        return refactorTitle;
    };

    static async stringifyTasks(devis) {
        const tasks = [];
        const categories = await Socket.readLean('Category');
        devis.sites.forEach(site => {
            let tasksPerSite = [];
            if (site.services && site.services.length > 0) {
                site.services.forEach(service => {
                    if(service && service.designation && service.quantity) {
                        const cat = categories.data.filter(cat => cat._id === service.id_categorie);
                        tasksPerSite.push(`${cat[0].name} -> ${service.designation} x${service.quantity}`)
                    }
                });
                tasks.push(tasksPerSite)
            }
        });
        return tasks;
    }


    static getListsTrello = (board) => {
        return axios.get(`${urlTrello}/boards/${board.id}/lists?key=${keyTrello}&token=${tokenTrello}`)
            .then(res => {
                return res.data
            })
    };

    static getCardsTrello = (list) => {
        return axios.get(`${urlTrello}/lists/${list.id}/cards?key=${keyTrello}&token=${tokenTrello}`)
            .then(res => {
                return res.data;
            })
    };

    static createCheckListsTrello = async (quote, card) => {
        const tabSitesTasks = await this.stringifyTasks(quote);

        if (tabSitesTasks.length > 0) {
            //Cree les checklist dans la carte
            for (let i = 0; i < tabSitesTasks.length; i++) {
                let site_name = `${quote.sites[i].site_name} ${quote.sites[i].delivery_address}`;
                axios.post(`https://api.trello.com/1/checklists?idCard=${card.id}&key=${keyTrello}&token=${tokenTrello}`)
                    .then(res => {
                        if (res.status === 200) {
                            // Change le nom de la checklist
                            axios.put(`https://api.trello.com/1/checklists/${res.data.id}/name?value=${site_name}&key=${keyTrello}&token=${tokenTrello}`)
                                .then(res => {
                                    console.info(res)
                                });
                            //Cree une checkbox dans la checklist
                            for (let x = 0; x < tabSitesTasks[i].length; x++) {
                                axios.post(`https://trello.com/1/cards/${card.id}/checklist/${res.data.id}/checkItem?name=${tabSitesTasks[i][x]}&key=${keyTrello}&token=${tokenTrello}`)
                                    .then(res => {});
                                if (x === tabSitesTasks[i].length - 1) {
                                    console.info('Les checklists ont été créer avec succès!')
                                    notification.success({message: 'Les checklists ont été créer avec succès!'})
                                }
                            }
                        } else {
                            console.error("une erreur s'est produit")
                        }
                    })
            }
        } else {
            console.error("Aucun services dans ce devis")
            notification.error({message: "Aucun services dans ce devis"});
        }
    };

    static  getTrello = async (quote) => {

        await axios.get(`${apiPipe}/${quote.society.id_pipedrive}?api_token=${tokenPipe}&limit=500`)
            .then(resPipe => {
                if(resPipe.status === 200) {
                    if (resPipe) {
                        axios.get(`${urlTrello}/members/me/boards?key=${keyTrello}&token=${tokenTrello}`)
                            .then(res => {
                                if (res.data.length > 0) {
                                    const boards = res.data;
                                    const boardsSayse = boards.filter(board => board.name === "DEPLOIEMENT SAYSE");
                                    if (boardsSayse.length > 0) {
                                        const board = boardsSayse[0];
                                        this.getListsTrello(board)
                                            .then(lists => {
                                                const listsSayse = lists.filter(list => list.name === "DOSSIER VALIDE");
                                                if (listsSayse.length > 0) {
                                                    const list = listsSayse[0];
                                                    this.getCardsTrello(list)
                                                        .then(cards => {

                                                            // console.log(resPipe.data.data.title)
                                                            const getCards = cards.filter(card => card.name === this.refactorTitle(card.name, resPipe.data.data.title));
                                                            if (getCards.length > 0) {
                                                                const card = getCards[0];

                                                                this.createCheckListsTrello(quote, card);
                                                            } else {
                                                                notification.error({message: `La carte "${quote.society.title}" n'a pas été trouvé.`})
                                                            }
                                                        })
                                                } else {
                                                    notification.error({message: "La liste DOSSIER VALIDE n'a pas été trouvé."})
                                                }
                                            })
                                    } else {
                                        console.error("Le tableau DEPLOIEMENT SAYSE n'a pas été trouvé.")
                                        notification.error({error: "Le tableau DEPLOIEMENT SAYSE n'a pas été trouvé."})
                                    }
                                } else {
                                    console.error("Aucun tableau trouvé")
                                    notification.error({message:  "Aucun tableau trouvé"})
                                }
                            })
                    }
                }
            })
            .catch(error => {
                if(error) {
                    console.log(error)
                    notification.error({message: "id introuvable"})
                }
            })

    };
}