import React, {Component} from 'react';

class IconDelete extends Component {
    render() {
        return (
            <svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                 viewBox="0 0 278.7 278.7" space="preserve">
<path d="M237.8,35.1h-54.3V19.9c0-11-9-19.9-19.9-19.9h-52.8c-11,0-19.9,9-19.9,19.9v15.1h-54c-2.7,0-4.9,2.2-4.9,4.9
	s2.2,4.9,4.9,4.9h12.7L60.6,274c0.1,2.6,2.3,4.6,4.9,4.6H209c2.6,0,4.8-2,4.9-4.6L225,44.9h12.8c2.7,0,4.9-2.2,4.9-4.9
	S240.5,35.1,237.8,35.1z M100.6,19.9c0-5.6,4.6-10.1,10.1-10.1h52.8c5.6,0,10.1,4.6,10.1,10.1v15.1h-73.1V19.9z"/>
</svg>

        );
    }
}

export default IconDelete;