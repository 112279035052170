import {ServicesState, SitesState} from "../../../../../Store/Reducers/Dac/types";

export interface ServiceInt {
    abo_ht: number,
    pa_abo: number,
    fas_ht: number,
    pa_fas: number,
    mge_abo: number,
    tx_abo: number,
    mge_fas: number,
    quantity: number,
    tx_fas: number,
    discount_abo?: number,
    discount_fas?: number,
}

class TotalFunctions {

    static calcPrice(props: any) {
        this.dispatchTotalPerSite(props)
            .then(() => {
                this.dispatchTotal(props)
            })
    }



    // au changement du service dispatch les prix totaux de tout les sites
    static dispatchTotal(props: {sitesReducer: Array<SitesState>, discount_fas: number, discount_abo: number, receiveTotal: Function, editTotal: Function}) {
        const {sitesReducer, discount_fas, discount_abo, receiveTotal} = props;
        const tab = this.calcSumPerSites(sitesReducer, true);

        receiveTotal({
            ...tab,
            discount_abo: discount_abo,
            discount_fas: discount_fas,
        })
    }

    // au changement du service dispatch les prix totaux par site
    static async dispatchTotalPerSite(props: {sitesReducer: Array<SitesState>, editSites: Function}) {
        const {sitesReducer, editSites} = props;
        if(sitesReducer && sitesReducer.length > 0) {
            const copySites = [...sitesReducer];
            copySites.forEach((site: any, index: number) => {
                const tab = this.calcSumPerSites(site.services, false);
                for (let [key, value] of Object.entries(tab)) {
                    //@ts-ignore
                    site[key] = value;
                    editSites(key, value, index);
                }
            })
        }
    }

    static calcSumPerSites(tab: Array<SitesState | ServicesState>, isSite: boolean) {
        const values: ServiceInt = {
            abo_ht: 0,
            pa_abo: 0,
            fas_ht: 0,
            pa_fas: 0,
            mge_abo: 0,
            tx_abo: 0,
            mge_fas: 0,
            quantity: 0,
            tx_fas: 0,
        };
        if (tab && tab.length >= 0) {
            for (let key of Object.keys(values)) {
                // @ts-ignore
                let sum = Array.from(tab, service => isSite ? service[key] : service[key] * service.quantity);
                // @ts-ignore
                values[key] = sum.length > 0 ? sum.reduce((accumulator, currentValue) => accumulator + currentValue) : 0;
            }
        }
        return values;
    }

}

export default TotalFunctions;
