import * as React from 'react';
import {DacState} from "../../Store/Reducers/Dac/types";
import {addInfoDeal} from "../../Store/Reducers/Dac/actions";
import {connect, ConnectedProps} from "react-redux";
import {CollectionsState} from "../../Store/Reducers/Collections/types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import HeaderTableCatalogue from "../../Components/Catalogue/HeaderTableCatalogue/HeaderTableCatalogue";
import {faUsers} from "@fortawesome/free-solid-svg-icons";
import {Tabs} from "antd";
import {Deal} from "../../utils/Types/Pipedrive";
import './Catalogues.scss';

const {TabPane} = Tabs;

interface RootState {
    dacReducer: DacState,
    collectionsReducer: CollectionsState
}

const mapState = (state: RootState) => ({
    societyReducer: state.dacReducer.society,
    collectionsReducer: state.collectionsReducer
});

const mapDispatch = {
    addInfoDeal: (client: Deal) => addInfoDeal(client),
};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
}

interface State {
    category: string,
    search: string
}

class Catalogue extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            category: "",
            search: ""
        }
    }

    changeCategory = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const {name, value} = e.target;
        // @ts-ignore
        this.setState({[name]: value});
    };

    componentDidMount() {
        if (this.props.collectionsReducer.Category.length > 0) {
            this.setState(prevState => ({...prevState, category: this.props.collectionsReducer.Category[1]._id}));
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if (this.props.collectionsReducer.Category !== prevProps.collectionsReducer.Category) {
            this.setState(prevState => ({...prevState, category: this.props.collectionsReducer.Category[1]._id}));
        }
    }

    changeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {value} = e.target;
        this.setState(prevState => ({...prevState, search: value}));
    }

    render() {

        const {Category, Service} = this.props.collectionsReducer;
        return (
            <div id={"Catalogue"}>
                <div className={"title_page"}>
                    <FontAwesomeIcon icon={faUsers} />
                    <h2>Catalogue</h2>
                </div>
                {(Service.length > 0 && Category.length > 0) &&
                <Tabs className={"tabs"} defaultActiveKey={Category[0].name}>
                    {Category.filter(c => c.name !== "SPECIAL").map(tab => (
                        <TabPane tab={<span>{tab.name}</span>} key={tab.name}>
                            <HeaderTableCatalogue data={Service.filter(s => s.id_categorie === tab._id)}/>
                        </TabPane>
                    ))}
                </Tabs>
                }
            </div>
        )
    }
}

export default connector(Catalogue);
