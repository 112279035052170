import React from "react";
import {
    Page,
    Text,
    View,
    Document
} from "@react-pdf/renderer";
import {DacState} from "../../../Store/Reducers/Dac/types";
import axios from "axios";


interface Props {
    order: DacState | null
}

interface State {
    durations: Array<{label: string, id: string}>
}

class PagePDF extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            durations: []
        }
    }

    componentDidMount() {
        this.getDurations();
    }

    getDurations = async () => {
        const res = await axios.get('https://api.pipedrive.com/v1/dealFields/12465?api_token=d168467b213a71acdc4ba5cb5b2cfe96529ac061')
        const durations = res.data.data.options;
        this.setState(() => ({durations: durations.map((d: {id: string, label: string}) => ({id: `${d.id}`, label: d.label}))}))
    }
    render() {
        const {order} = this.props;
        if (order) {
        const {society, sites, billing} = order;
            let highlightCss = {backgroundColor: "yellow", padding: 1, marginTop: 1, marginBottom: 1};
            return (
                <Document>
                    <Page style={{fontSize: 8, padding: 10}}>
                        <View style={{backgroundColor: '#f0f9fd', padding: 8, marginBottom: 15}}>
                            <Text style={{fontSize: 13, marginBottom: 5}}>Référence société</Text>
                            <View>
                                <Text style={highlightCss}>Durée de souscription: {this.state.durations.find(d => d.id === society.subscribe_duration)?.label}</Text>
                                <Text>ID Pipedrive: {society.id_pipedrive}</Text>
                                <Text>Numéro de bon de commande: {society.id_order}</Text>
                                <Text>Titre de l'offre: {society.title}</Text>
                                <Text>Société: {society.society}</Text>
                                <Text>Adresse: {society.address}</Text>
                                <Text>Nom du signataire: {society.signatory}</Text>
                                <Text>Nom du contact: {society.contact_name}</Text>
                                <Text>Email du contact: {society.contact_email}</Text>
                                <Text>Propriétaire: {society.signatory}</Text>
                            </View>
                        </View>z
                        <View style={{marginBottom: 15, padding: 8}}>
                            <Text style={{fontSize: 13, marginBottom: 5}}>Référence facturation</Text>
                            <View>
                                <Text>Nom du contact: {billing.contact_name}</Text>
                                <Text>Téléphone du contact: {billing.contact_tel}</Text>
                                <Text>Email du contact: {billing.contact_mail}</Text>
                                <Text>Adresse: {billing.address}</Text>
                            </View>
                        </View>
                        <View>
                            {sites.map((site, index) => {
                                return (
                                    <View key={index} style={{marginBottom: 20}}>
                                        <View style={{backgroundColor: '#144395', color: 'white', borderTopLeftRadius: 10, borderTopRightRadius: 10, padding: 10}} >
                                            <Text wrap={false}>Site n°{index + 1}</Text>
                                        </View>
                                        <View>
                                            {site.services.map((service, index) => {
                                                let bgService = (index % 2 === 0) ? '#ececec' : 'white';
                                                return (
                                                    <View style={{
                                                        backgroundColor: bgService,
                                                        padding: 5
                                                    }} key={index}>
                                                        {/*<Text wrap={false}>Famille: {service.family}</Text>*/}
                                                        <Text wrap={false}
                                                              style={highlightCss}>Désignation: {service.designation}</Text>
                                                        <Text wrap={false}
                                                              style={highlightCss}>Solution: {service.solution}</Text>
                                                        <Text wrap={false}>Description: {service.describe_solution}</Text>
                                                        <Text wrap={false}>Référence: {service.reference}</Text>
                                                        <Text wrap={false}>FRN: {service.frn}</Text>
                                                        <Text wrap={false}>Numéro: {service.number}</Text>
                                                        <Text wrap={false}>PA ABO: {service.pa_abo} €</Text>
                                                        <Text wrap={false}>PA FAS: {service.pa_fas} €</Text>
                                                        <Text wrap={false}>Marge ABO: {service.mge_abo} €</Text>
                                                        <Text wrap={false}>Taux ABO: {service.tx_abo} %</Text>
                                                        <Text wrap={false}>Marge FAS: {service.mge_fas} €</Text>
                                                        <Text wrap={false}>Taux FAS: {service.tx_fas} %</Text>
                                                        <Text wrap={false}>Prix Router: {service.p_router} €</Text>
                                                        <Text wrap={false} style={highlightCss}>Durée: {this.state.durations.find(d => d.id === service.duration)?.label}</Text>
                                                        <Text wrap={false} style={highlightCss}>Abo/mois
                                                            ht: {service.abo_ht} €</Text>
                                                        <Text wrap={false} style={highlightCss}>Total Abo/mois
                                                            ht: {service.abo_ht * service.quantity} €</Text>
                                                        <Text wrap={false} style={highlightCss}>Fas ht: {service.fas_ht} €</Text>
                                                        <Text wrap={false} style={highlightCss}>Total Fas
                                                            ht: {service.fas_ht * service.quantity} €</Text>
                                                        <Text wrap={false}
                                                              style={highlightCss}>Quantité: {service.quantity}</Text>
                                                    </View>
                                                )
                                            })}
                                            <View style={{padding: 5, borderBottomLeftRadius: 10, borderBottomRightRadius: 10, backgroundColor: '#f0f9fd'}} >
                                                <Text wrap={false}>Nom du site: {site.site_name}</Text>
                                                <Text wrap={false}>Nom du contact: {site.contact_name}</Text>
                                                <Text wrap={false}>Téléphone du contact: {site.contact_tel}</Text>
                                                <Text wrap={false}>Siret: {site.siret}</Text>
                                                <Text wrap={false}>Mail du contact: {site.contact_mail}</Text>
                                                <Text wrap={false}>Adresse de livraison: {site.delivery_address}</Text>
                                                <Text wrap={false}>Total ABO: {site.abo_ht} €</Text>
                                                <Text wrap={false}>Total FAS: {site.fas_ht} €</Text>
                                            </View>
                                        </View>
                                    </View>
                                )
                            })}
                        </View>
                        <View style={{marginTop: 'auto', backgroundColor: '#144395', padding: 10, color: 'white'}}
                              wrap={false}>
                            <Text>Abo/mois ht: {society.abo_ht} €</Text>
                            <Text>Total fas ht: {society.fas_ht} €</Text>
                            <Text>Remise ABO: {society.discount_abo} €</Text>
                            <Text>Remise FAS: {society.discount_fas} €</Text>
                        </View>
                    </Page>
                </Document>

            );
        } else {
            return <Document/>
        }
    }
}


export default PagePDF;
