import * as React from 'react';
import Logo from "../../../assets/logo_sayse_blanc.png";
import {NavLink, withRouter} from "react-router-dom";

import {RouteComponentProps} from "react-router";
import './NavbarLeft.scss';
import Cursor from "./Cursor/Cursor";
import IconDevis from "../../IconSVG/IconDevis";
import IconHistorique from "../../IconSVG/IconHistorique";
import IconHome from "../../IconSVG/IconHome";
import IconCatalogue from "../../IconSVG/IconCatalogue";
import IconStats from "../../IconSVG/IconStats";

type PathParams = {
    pathname: string
}

interface LayoutProps extends RouteComponentProps<PathParams> {
    // children: JSX.Element[] | JSX.Element,
    collapse: boolean,
    changeSizeNavbar: Function
}


interface State {
}

const tabs = [
    {name: "Dashboard", icon: <IconHome />},
    {name: "Devis", icon: <IconDevis />},
    {name: "Historique", icon: <IconHistorique />},
    // {name: "Eligiblité", icon: faBriefcase},
    // {name: "Bibliothèque", icon: faMapMarkedAlt},
    {name: "Catalogue", icon: <IconCatalogue />},
    {name: "Statistiques", icon: <IconStats />},
    // {name: "Logs", icon: faChartLine},
    // {name: "Clients", icon: faChartLine},
]

class NavbarLeft extends React.Component<LayoutProps, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            collapse: true
        }
    }

    componentDidMount() {
        this.mouseMove();
    }

    mouseMove = () => {
        document.body.onmousemove = event => {
            const cursor = document.getElementById("cursor");

            if(cursor) {
                const sizeCursor = cursor.getBoundingClientRect();
                const calcPercentage = Math.floor((sizeCursor.y * 100) / window.innerHeight);
                const border = document.getElementById('cls-2');
                const circle = document.getElementById('color-left');
                const halfCursor = (sizeCursor.height / 2);
                if (event.clientY > halfCursor && event.clientY < (window.innerHeight - halfCursor)) {
                    cursor.style.top = event.clientY - (sizeCursor.height / 2) + "px";
                }
                if (circle && border) {
                    if (calcPercentage <= 20) {
                        circle.style.fill = "#dfeffb";
                        border.style.fill = "#dfeffb";
                    } else if (calcPercentage > 20 && calcPercentage <= 40) {
                        circle.style.fill = "#dceaf7";
                        border.style.fill = "#dceaf7";
                    } else if (calcPercentage > 40 && calcPercentage <= 60) {
                        circle.style.fill = "#dbe6f6";
                        border.style.fill = "#dbe6f6";
                    } else if (calcPercentage > 60 && calcPercentage <= 80) {
                        circle.style.fill = "#dadff6";
                        border.style.fill = "#dadff6";
                    } else if (calcPercentage > 80) {
                         circle.style.fill = "#daddfa";
                         border.style.fill = "#daddfa";
                    }
                }
            }
        };
    }



    render() {
        const {collapse} = this.props;
        return (
            <div id={"NavbarLeft"}>
                <img src={Logo} alt={'logo'} width="70%"
                     className="image_left mt-5"/>
                <nav>
                    {tabs.map(tab => (
                        <NavLink key={tab.name} to={`${tab.name}`} activeClassName={'nav_active'}>
                            {tab.icon}
                            <span>{tab.name}</span>
                        </NavLink>
                    ))}
                </nav>
                <Cursor left={collapse} changeSizeNavbar={this.props.changeSizeNavbar}/>
            </div>
        )
    }
}

export default withRouter(NavbarLeft);
